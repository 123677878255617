import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import globals from "../config/globals/globals";
import ".././index.css";
import images from "../images/images";
import Loader from "../components/Loader";
import { _ } from "../config/languages/i18n";
import React from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import moment from "moment";
import { getMonth } from "../../src/config/month/month"
import { getYear } from "../../src/config/year/year"
import constant from '../config/constant';
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const AddFee = () => {
    var totalPrice = 0;
    var salePrice = 0;
    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState("");
    const [cartTime, setCartTime] = useState("");
    const [counter, setCounter] = useState(999);
    const [fullscreen, setFullscreen] = useState(true);
    const [deleteCartModal, setDeleteCartModal] = useState(false);
    const [deleteTicketModal, setDeleteTicketModal] = useState(false);
    const [deleteTicketId, setDeleteTicketId] = useState(false)
    const [orderId, setOrderId] = useState("")
    const month = getMonth();
    const year = getYear();
    const [invoiceType, setInvoiceType] = useState(1)
    const [TCNO, setTCNO] = useState("")
    const [buyButton, setBuyButton] = useState(false)
    const [saleStatus, setSaleStatus] = useState(false)
    const [saleRatio, setSaleRatio] = useState(0)
    const [sloganNumber, setSloganNumber] = useState("")
    const [moneyValue, setMoneyValue] = useState("")
    const cookies = new Cookies()
    const myInput = useRef(null)
    const [paymentStatus, setPaymentStatus] = useState(0)
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')

    useEffect(()=>{
        setDevice(navigator.userAgent)
    },[])

    useEffect(()=>{
        setUtc(new Date().getTimezoneOffset()/-60)
    },[])

    useEffect(()=>{
        HTTP.get('https://api.ipify.org?format=json')
        .then(
            res=>{
                setIp(res.data.ip)
            }
        )
    },[])

    const [state, setState] = useState({
        number: "",
        month: "",
        year: "",
        cvc: "",
        name: "",
        focus: "",
        month: "",
        amount: "",

        nameSurname: "",
        tckn: "",
        individualEmail: "",

        officialTitleCompany: "",
        taxAdministration: "",
        taxNumber: "",
        billingAddress: "",
        institutionalEmail: ""
    });
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [iFrameSrc, setIFrameSrc] = useState("");
    const formData = new FormData();
    const HTTP = axios.create({
        baseURL: globals.apiUrl,
    });
    const [ticketId, setTicketId] = useState("")
    const [cardId, setCardId] = useState("")
    const [term, setTerm] = useState(true)
    const onChangeTerm = (value) => {
        setTerm(!term);
    }
    const [tcknTerm, setTcknTerm] = useState(false)
    const onChangeTcknTerm = (value) => {
        setTcknTerm(!tcknTerm);
    }

    useEffect(() => {
        setLoading(true);
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
            setLoading(false)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
            setLoading(false)
        }
    }, [sloganNumber]);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true);
        HTTP.get("users/getUserLastInvoice", {
            headers: { Authorization: `bearer ${getuser.token}` },
        })
            .then((res) => {
                if (res.data.result) {
                    setLoading(false)
                    if (res.data.invoice.type == 2) {
                        state.officialTitleCompany = res.data.invoice.firmname
                        state.taxAdministration = res.data.invoice.tax_office
                        state.taxNumber = res.data.invoice.tax_no
                        state.billingAddress = res.data.invoice.address
                        state.institutionalEmail = res.data.invoice.email

                    } else {
                        state.nameSurname = res.data.invoice.fullname
                        state.tckn = res.data.invoice.tckn

                        state.individualEmail = res.data.invoice.email
                    }
                } else {
                    setLoading(true);
                }
            })
            .catch(setLoading(true));

    }, []);

    if (orderId) {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setTimeout(() => HTTP.get(`getWalletProcessStatus?process_id=${orderId}`,
            {
                headers: { Authorization: `bearer ${getuser.token}` },
            }
        )
            .then((res) => {
                if (res.data.process.status == 2) {
                    setPaymentStatus(2)
                    setTimeout(() => window.location.href = globals.url + "i/addFee", 2000);
                    setTimeout(() => setShowPaymentModal(false), 2000)
                }
                if (res.data.process.status == 1) {
                    setPaymentStatus(1)
                    localStorage.setItem("feeRef", JSON.stringify(1))
                    setTimeout(() => window.location.href = globals.url + "i/tickets", 2000);
                    setTimeout(() => setShowPaymentModal(false), 2000)
                }
            })
            .catch(), 1000);
    }

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const handleInputChangeName = (evt) => {
        const { name, value } = evt.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeNumber = (evt) => {
        const { name, value } = evt.target;
        if (value.length >= 6) {
            if (constant.denizbankBin.find(arr => arr == value.slice(0, 6))) {
                setSaleStatus(true);
                setSaleRatio(constant.denizbankBin[0]);
            } else {
                setSaleStatus(false);
                setSaleRatio(0);
            }
        } else {
            setSaleStatus(false);
            setSaleRatio(0);
        }

        if ((value.replace(/\s/g, '')).length < 17 && (/^[0-9]*[]?[0-9]*$/).test(value.replace(/\s/g, ''))) {
            return setState((prev) => ({ ...prev, [name]: value.replace(/\s/g, '').trim() }));
        }
    };

    const handleInputChangeMonth = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 3) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeYear = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 3) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeCVV = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 4) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeNameSurname = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeTckn = (evt) => {
        const { name, value } = evt.target;
        if ((value.length < 12)) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeIndividualEmail = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value.trim() }));
    };

    const handleInputChangeOfficialTitleCompany = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeTaxAdministration = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeTaxNumber = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 11) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeBillingAddress = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeInstitutionalEmail = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value.trim() }));
    };

    const handleInputChangeAmount = (evt) => {
        setMoneyValue("")
        const { name, value } = evt.target;
        // ParseFloat (value, 9)
        // function ParseFloat(str, val) {
        //     str = str.toString();
        //     str = str.slice(0,(str.indexOf(".")) + val )
        //     setState((prev) => ({ ...prev, [name]: str }));
        //     return Number(str) 
        // }
        var sayiArray = value.split('.');
        if (sayiArray.length == 2) {
            if (sayiArray[1].length > 2) {
                sayiArray[1] = sayiArray[1].substr(0, 2)
                value = parseFloat(parseInt(sayiArray[0]) + parseInt(sayiArray[1]) / 100)
            }
            setState((prev) => ({ ...prev, [name]: value }));
        } else {
            setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    function amountStateNull() {
        state.amount = '';
    }

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    };

    function TCNOKontrol(TCNO) {
        var tek = 0,
            cift = 0,
            sonuc = 0,
            TCToplam = 0,
            i = 0;

        if (TCNO.length != 11) return false;
        if (isNaN(TCNO)) return false;
        if (TCNO[0] == 0) return false;

        tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
        cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);

        tek = tek * 7;
        sonuc = Math.abs(tek - cift);
        if (sonuc % 10 != TCNO[9]) return false;

        for (var i = 0; i < 10; i++) {
            TCToplam += parseInt(TCNO[i]);
        }

        if (TCToplam % 10 != TCNO[10]) return false;

        return true;
    }

    function emailControl(data) {
        var regex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/;
        if (regex.test(data) == true) {
            return true;
        } else {
            return false;
        }
    }

    var myArray = ""
    var cartId = ""
    const pay = () => {

        const getuser = JSON.parse(localStorage.getItem("auth"));
        setBuyButton(true)

        if (invoiceType == 1) {
            if (TCNOKontrol(state.tckn) == false) {
                toast(_('invalid_tckn'), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false,
                    type: "error"
                })
                setBuyButton(false)
                return;
            }

            if (emailControl(state.individualEmail) == false) {
                toast(_('invalid_email'), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false,
                    type: "error"
                })
                setBuyButton(false)
                return;
            }
        } else {
            if (emailControl(state.institutionalEmail) == false) { //Kurumsal
                toast(_('invalid_email'), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false,
                    type: "error"
                })
                setBuyButton(false)
                return;
            }
        }

        formData.append("amount", state.amount ? state.amount : moneyValue);
        formData.append("ccNumber", state.number);
        formData.append("ccMonth", state.month);
        formData.append("ccYear", state.year);
        formData.append("ccCvv", state.cvc);

        formData.append("invoice_type", invoiceType)
        formData.append("invoice_fullname", state.nameSurname)
        formData.append("invoice_tr_status", tcknTerm ? 0 : 1)
        formData.append("invoice_tckn", state.tckn)
        formData.append("invoice_email", state.individualEmail ? state.individualEmail : state.institutionalEmail)
        formData.append("invoice_firm", state.officialTitleCompany)
        formData.append("invoice_tax_office", state.taxAdministration)
        formData.append("invoice_tax_no", state.taxNumber)
        formData.append("invoice_address", state.billingAddress)
        formData.append("invoice_e_invoice", term ? 1 : 0)
        formData.append("ip_address", ip)
        formData.append("device_name", device)
        formData.append("utc", utc)
        if (state.amount < 200 && moneyValue < 200) {
            toast(_("min_add_fee"), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton: false
            })
            setTimeout(() => setBuyButton(false), 4000);
        } else {
            HTTP.post(
                "/addMoneyToWallet",
                (formData),
                {
                    headers: { Authorization: `bearer ${getuser.token}` },
                    'Content-Type': 'application/json'
                }
            ).then(response => {
                const data = response.data
                if (data.result == true) {
                    toast(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false
                    })
                    setTimeout(() => setShowPaymentModal(true), 3000);
                    setIFrameSrc(data.url)
                    setOrderId(data.orderUuid)
                }
                if (data.result == false) {
                    setBuyButton(false)
                    if (data.errors.ccNumber) {
                        toast(data.errors.ccNumber[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton: false,
                            type: "error"
                        })
                    }
                    if (data.errors.ccMonth) {
                        toast(data.errors.ccMonth[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton: false,
                            type: "error"
                        })
                    }
                    if (data.errors.ccYear) {
                        toast(data.errors.ccYear[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton: false,
                            type: "error"
                        })
                    }
                    if (data.errors.ccCvv) {
                        toast(data.errors.ccCvv[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton: false,
                            type: "error"
                        })
                    }
                }
            }).catch(() => {
                setBuyButton(false)
                toast(_('connection_error'), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false,
                    type: "error"
                })
            })
        }
    }

    function NumToTime(num) {
        var hours = Math.floor(num / 60);
        var minutes = num % 60;
        if (minutes + ''.length < 2) {
            minutes = '0' + minutes;
        }
        return hours + ":" + minutes;
    }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Add Fee Page'
            },
            event: 'add_fee_page'
        })
    }, [])


    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("add_price")}</title>
                <meta name="description" content={_("meta_content_01")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5 mt-5">

                        <div className="card p-5 col-md-6 offset-md-3">
                            <div className="form-group">

                                <div  onClick={()=>gtmTag('price_amount_button')} className="row g-1 mb-2">
                                    <h1 style={{fontSize:16}} className="homeText text-center mb-1">{_("add_fee")}</h1>

                                    <div onClick={() => setMoneyValue(200) + amountStateNull()} className="text-decoration-none d-grid gap-2 col-md-3 mx-auto text-white">
                                        <button className="btns text-white" type="button">200 TRY</button>
                                    </div>
                                    <div onClick={() => setMoneyValue(500) + amountStateNull()} className="text-decoration-none d-grid gap-2 col-md-3 mx-auto">
                                        <button className="btns text-white" type="button">500 TRY</button>
                                    </div>
                                    <div onClick={() => setMoneyValue(1000) + amountStateNull()} className="text-decoration-none d-grid gap-2 col-md-3 mx-auto">
                                        <button className="btns text-white" type="button">1000 TRY</button>
                                    </div>
                                </div>
                                {
                                    !moneyValue && state.amount < 200 &&
                                    <span className="text-muted text-center mb-1">{_("min_add_fee")}</span>
                                }

                                <input
                                    type="number"
                                    name="amount"
                                    className="form-control"
                                    placeholder={_('other')}
                                    value={state.amount.replace(/\s/g, '')}
                                    required
                                    onChange={handleInputChangeAmount}
                                    onFocus={handleInputFocus}
                                />
                                {
                                    ((state.amount && state.amount >= 200) || moneyValue) &&
                                    <span className="homeText text-center mb-1">{_("amount_to_be_loaded")} : {state.amount ? state.amount : moneyValue} TRY</span>
                                }
                                  <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                    {state.amount == "" && moneyValue == "" && <div className="text-danger small mt-2 mx-1">{_('required_amount')}</div>}
                                </span>
                            </div>
                            <span className="homeText text-center mb-3 mt-3">{_("invoice_info")}</span>
                            <div>
                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="faq_tab_1-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_1" type="button" role="tab" aria-controls="faq_tab_1" aria-selected="true" >
                                            <div className="d-flex flex-column lh-lg">
                                                <i className="bx bxs-plane-alt"></i>
                                                <span className="homeText">{_("individual")}</span>
                                            </div>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="faq_tab_2-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_2" type="button" role="tab" aria-controls="faq_tab_2" aria-selected="false">
                                            <div className="d-flex flex-column lh-lg">
                                                <i className="bx bxs-shopping-bag"></i>
                                                <span className="homeText">{_("corporate")}</span>
                                            </div>
                                        </button>
                                    </li>
                                </ul>

                                <div className="tab-content my-3" id="myTabContent">
                                    <div onClick={e => setInvoiceType(1)} className="tab-pane fade active show" id="faq_tab_1" role="tabpanel" aria-labelledby="faq_tab_1-tab">
                                        <div className="form-group mt-2">
                                            <input
                                                type="text"
                                                name="nameSurname"
                                                className="form-control"
                                                placeholder={_('name_surname')}
                                                value={state.nameSurname}
                                                required
                                                onChange={handleInputChangeNameSurname}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.nameSurname == "" && <div className="text-danger small mt-2 mx-1">{_('required_name_surname')}</div>}
                                        </span>
                                        <div className="form-group mt-2">
                                            <input
                                                type="number"
                                                name="tckn"
                                                className="form-control"
                                                placeholder={_('tckn')}
                                                value={state.tckn}
                                                required
                                                onChange={handleInputChangeTckn}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {(state.tckn == "" && tcknTerm == 0) && <div className="text-danger small mt-2 mx-1">{_('required_tckn')}</div>} 
                                        </span>
                                        <div className="form-check mt-2">
                                            <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={tcknTerm} onChange={onChangeTcknTerm} />
                                                {_("not_citizen_of_republic_of_turkey")}
                                            </label>
                                        </div>
                                        <div className="form-group mt-2">
                                            <input
                                                type="text"
                                                name="individualEmail"
                                                className="form-control"
                                                placeholder={_('email_address_to_send_invoice')}
                                                value={state.individualEmail}
                                                required
                                                onChange={handleInputChangeIndividualEmail}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.individualEmail == "" && <div className="text-danger small mt-2 mx-1">{_('required_email')}</div>}
                                        </span>
                                    </div>

                                    <div onClick={e => setInvoiceType(2)} className="tab-pane fade" id="faq_tab_2" role="tabpanel" aria-labelledby="faq_tab_2-tab">
                                        <div className="form-group mt-2">
                                            <input
                                                type="text"
                                                name="officialTitleCompany"
                                                className="form-control"
                                                placeholder={_('official_title_of_company')}
                                                value={state.officialTitleCompany}
                                                required
                                                onChange={handleInputChangeOfficialTitleCompany}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.officialTitleCompany == "" && <div className="text-danger small mt-2 mx-1">{_('required_official_title')}</div>}
                                        </span>
                                        <div className="form-group mt-2">
                                            <input
                                                type="text"
                                                name="taxAdministration"
                                                className="form-control"
                                                placeholder={_('tax_administration')}
                                                value={state.taxAdministration}
                                                required
                                                onChange={handleInputChangeTaxAdministration}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.taxAdministration == "" && <div className="text-danger small mt-2 mx-1">{_('required_tax_administration')}</div>}
                                        </span>
                                        <div className="form-group mt-2">
                                            <input
                                                type="number"
                                                name="taxNumber"
                                                className="form-control"
                                                placeholder={_('tax_number')}
                                                value={state.taxNumber}
                                                required
                                                onChange={handleInputChangeTaxNumber}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.taxNumber == "" && <div className="text-danger small mt-2 mx-1">{_('required_tax_number')}</div>}
                                        </span>
                                        <div className="form-group mt-2">
                                            <textarea
                                                type="text"
                                                name="billingAddress"
                                                className="form-control"
                                                placeholder={_('billing_address')}
                                                value={state.billingAddress}
                                                required
                                                onChange={handleInputChangeBillingAddress}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.billingAddress == "" && <div className="text-danger small mt-2 mx-1">{_('required_billing_address')}</div>}
                                        </span>
                                        <div className="form-group mt-2">
                                            <input
                                                type="text"
                                                name="institutionalEmail"
                                                className="form-control"
                                                placeholder={_('email_address_to_send_invoice')}
                                                value={state.institutionalEmail}
                                                required
                                                onChange={handleInputChangeInstitutionalEmail}
                                                onFocus={handleInputFocus}
                                            />
                                        </div>
                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                        {state.institutionalEmail == "" && <div className="text-danger small mt-2 mx-1">{_('required_email')}</div>}
                                        </span>
                                        <div className="form-check mt-2">
                                            <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} />
                                                {_("e_invoice_payer")}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ color: "#000" }}>
                                    <hr style={{ height: 1 }} />
                                </div>

                                <Cards
                                    number={state.number}
                                    expiry={state.month + state.year}
                                    cvc={state.cvc}
                                    name={state.name}
                                    focused={state.focus}
                                    placeholders={{ name: _('name_surname') }}
                                />
                                <form className="mt-2">
                                    <div className="form-group mt-2">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder={_('name_on_the_card')}
                                            value={state.name}
                                            required
                                            onChange={handleInputChangeName}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                    {state.name == "" && <div className="text-danger small mt-2 mx-1">{_('required_card_name')}</div>}
                                    </span>
                                    <div className="form-group mt-2">
                                        <input
                                            name="number"
                                            placeholder={_('card_number')}
                                            value={state.number.replace(/\s/g, '')}
                                            className="form-control"
                                            required
                                            onChange={handleInputChangeNumber}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                    {state.number == "" && <div className="text-danger small mt-2 mx-1">{_('required_card_number')}</div>}
                                    </span>
                                    <div className="row mt-2">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <select
                                                    type="tel"
                                                    name="month"
                                                    className="form-control bg-white headerInput"
                                                    placeholder={_('month')}
                                                    value={state.month}
                                                    pattern="\d\d/\d\d"
                                                    required
                                                    onChange={handleInputChangeMonth}
                                                    onFocus={handleInputFocus}>
                                                    <option value="" key={""}>{_('month')}</option>
                                                    {
                                                        month.map((result) => (<option title={result.key}>{result.value}</option>))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <select
                                                    type="tel"
                                                    name="year"
                                                    className="form-control bg-white headerInput"
                                                    placeholder={_('year')}
                                                    value={state.year}
                                                    pattern="\d\d/\d\d"
                                                    required
                                                    onChange={handleInputChangeYear}
                                                    onFocus={handleInputFocus}>
                                                    <option value="" key={""}>{_('year')}</option>
                                                    {
                                                        year.map((result) => (<option title={result.key}>{result.value}</option>))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <input
                                                    type="tel"
                                                    name="cvc"
                                                    className="form-control"
                                                    placeholder="CVC"
                                                    value={state.cvc}
                                                    pattern="\d{3,4}"
                                                    required
                                                    onChange={handleInputChangeCVV}
                                                    onFocus={handleInputFocus}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                    {state.month == "" && <div className="text-danger small mt-2 mx-1">{_('required_month')}</div>}
                                    {state.year == "" && <div className="text-danger small mt-2 mx-1">{_('required_year')}</div>}
                                    {state.cvc == "" && <div className="text-danger small mt-2 mx-1">{_('required_cvc')}</div>}
                                    </span>
                                </form>
                            </div>
                            <div  onClick={()=>gtmTag('pay_button')} className="row justify-content-center mt-3 my-3 px-3">
                                <button disabled={
                                    !(state.name && state.number && state.month && state.year && state.cvc && (state.amount >= 200 || moneyValue >= 200) && (
                                        tcknTerm ?
                                            (state.nameSurname && state.individualEmail)
                                            :
                                            (state.nameSurname && state.tckn && state.individualEmail)
                                            ||
                                            (state.officialTitleCompany && state.taxAdministration && state.taxNumber && state.billingAddress && state.institutionalEmail))
                                    ) || buyButton} onClick={pay} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >{_('pay')}</button>
                                {/* <button disabled={!(state.name && state.number && state.month && state.year && state.cvc)}  onClick={pay} className="text-decoration-none btn btn-primary cardButton rounded-2" >{_('pay')}</button> */}
                            </div>
                        </div>
                        {/* <div className="card card2 order-1 me-1 mb-1">
                                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                                        }
                                    </div> */}
                    </div>
                    <Modal show={showPaymentModal}>
                        <Modal.Header closeButton closeVariant="white" style={{ color: "pink", background: "#f8f8f8" }}>
                            <Modal.Title>
                                <div className="row">
                                    <div className="col col-4 d-flex justify-content-start align-items-center text-align-center">
                                        <div className="d-flex justify-content-start align-items-center text-align-center ">
                                            <img src={images.vidipass_logo} className="w-75" alt="vidipass logo" />
                                        </div>
                                    </div>
                                    <div className="col col-8">
                                        <p style={{ fontSize: 10, color: "black" }}> {_("payment_warning_1")} </p>
                                        <p style={{ fontSize: 10, color: "black" }}> {_("payment_warning_2")} </p>
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body >
                            {
                                paymentStatus != 0 ?
                                    <>
                                        <div style={{ marginBottom: -100 }} className="row" >
                                            <iframe src={iFrameSrc}></iframe>
                                        </div>
                                        {
                                            paymentStatus == 1 ?
                                                <div className="d-flex justify-content-center">
                                                    <a className="border p-2 rounded border-primary" aria-current="page" href={globals.url + "i/tickets"}><img width={75} height={'auto'} src={images.vidipassbank_colourful_navbar} alt="vidipass bank"/></a>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-center">
                                                    <a className="text-decoration-none btn btn-primary cardButton rounded-2" href={globals.url + "i/addFee/"}>{_("add_fee")}</a>
                                                </div>
                                        }
                                    </>
                                    :
                                    <div className="containerIframe">
                                        <iframe className="responsive-iframe" src={iFrameSrc}></iframe>
                                    </div>
                            }
                        </Modal.Body>
                    </Modal>
                </>
            <Footer />
        </div>
    );
};

export default AddFee;