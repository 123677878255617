import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../index.css";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useMemo } from "react";
import { _ } from "../config/languages/i18n";
import Loader from "../components/Loader";
import globals from "../config/globals/globals";
import moment from "moment";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Card, Input } from 'reactstrap';
import Linkify from "linkify-react";
import { Modal } from "react-bootstrap";


const Notifications = () => {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [userNotifications, setUserNotifications] = useState([]);
    const [userNotificationsUnview, setUserNotificationsUnview] = useState('');
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [expandedRows, setExpandedRows] = useState([]); // Genişletilen satırları takip etmek için state
    const [flagModal, setFlagModal] = useState('')

    const handleRowExpand = (row) => {
        // Eğer satır genişletilmişse kapat, değilse genişlet
        if (expandedRows.includes(row)) {
            setExpandedRows(expandedRows.filter(item => item !== row));
        } else {
            setExpandedRows([...expandedRows, row]);
        }
    };

    const columns = [
        {
            name: _('subject'),
            selector: row => row.subject,
            sortable: true,
          maxWidth:'200px',
            cell: row => (
                <span
                onClick={() => handleRowExpand(row)}
                 className={`text-ellipsis ${row.view === 0 ? 'font-weight-bold' : 'font-weight-normal'}`}>
                    {row.subject}
                </span>
            ),
        },
        {
            name: _('description'),
            selector: row => row.info,
            sortable: true,
            maxWidth:'300px',
            cell: row => (
                <span
                onClick={() => handleRowExpand(row)}
                 className={`text-ellipsis ${row.view === 0 ? 'font-weight-bold' : 'font-weight-normal'}`}>
                    {row.info}
                </span>
            )
        },
        {
            name: _('time'),
            selector: row => moment(row.created_at).format("YYYY-MM-DD HH:mm:ss"),
            sortable: true,
           
            cell: row => (
                <span 
                onClick={() => handleRowExpand(row)}
                className={`text-ellipsis ${row.view === 0 ? 'font-weight-bold' : 'font-weight-normal'}`}>
                    {moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </span>
            )
        },

        {
            name: _('sign'),
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <i
                    className={`bi bi-flag${row.status === 1 ? '-fill' : ''} cursor-pointer homeText`}
                    onClick={row.status===0? ()=>toggleNotificationStatus(row.id):() => setFlagModal(row.id)}
                ></i>
            ),
        }
        
    ];

    const customText = {
        rowsPerPageText: _("number_of_rows_per_page"),
        rangeSeparatorText: _("between"),
    };

    // useEffect(() => {
    //     setLoading(true);
    //     HTTP.get(`getUserNotifications`, { headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
    //         .then((res) => {
    //             setUserNotifications(res.data.userNotifications);
    //             setUserNotificationsUnview(res.data.userNotificationsUnview);
    //             setLoading(false);
    //         }).catch((error) => {
    //             console.error("Error fetching notifications:", error);
    //             setLoading(false);
    //         });
    // }, []);

    useEffect(() => {
        setLoading(true);
        HTTP.get(`getUserNotifications`, { 
            headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } 
        })
        .then((res) => {
            setUserNotifications(res.data.userNotifications);
            setUserNotificationsUnview(res.data.userNotificationsUnview);
            setLoading(false);
    
            // Eğer okunmamış bildirim varsa, otomatik olarak "görüldü" yap
            if (res.data.userNotificationsUnview > 0) {
                HTTP.get(`viewedUserNotifications`, { 
                    headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } 
                }).catch((error) => console.error("Error marking notifications as viewed:", error));
            }
        })
        .catch((error) => {
            console.error("Error fetching notifications:", error);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (userNotificationsUnview > 0) {
            const timer = setTimeout(() => {
                HTTP.get(`viewedUserNotifications`, { headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } });
            }, 5000);

            return () => clearTimeout(timer); // Cleanup
        }
    }, [userNotificationsUnview]);

    const ExpandableComponent = ({ data }) => (
       
        <div className="expandable-container">
                <div className="row">
                <div className="col-5 col-md-12">
            <small className={data.view === 0 ? 'font-weight-bold' : 'font-weight-normal'}>
                <small>{data.subject}</small>
            </small>
            <small className={data.view === 0 ? 'font-weight-bold' : 'font-weight-normal'}>
                <div>
                    <small>{moment(data.created_at).format("YYYY-MM-DD HH:mm:ss")}</small> 
                </div>
               
            </small>
            
            <small className={data.view === 0 ? 'font-weight-bold' : 'font-weight-normal'}>
         
                <Linkify
                     options={{
                        className: "homeText text-decoration-none",
                        
                    }}
                >
                    <small>{data.info}</small>
                </Linkify>
        </small>
            </div>

                </div>

       
     
            
        </div>
    );

   

    // Memoize filtered notifications to avoid unnecessary re-renders
    const filteredNotifications = useMemo(() => {
        return userNotifications.filter((item) => {
            if (searchTerm === "") {
                return item;
            } else if (
                item.subject?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.info?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                moment(item.created_at).format("YYYY-MM-DD HH:mm:ss").toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                return item;
            }
            return null;
        });
    }, [searchTerm, userNotifications]);

    const toggleNotificationStatus = (id) => {
        HTTP.post('/toggleNotificationStatus', { id },{ headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
            .then((response) => {
                if (response.data.status === 'success') {
                    // Bildirim durumu başarıyla güncellendiğinde, kullanıcıya bildirimi yenile
                    setUserNotifications((prevNotifications) =>
                        prevNotifications.map((notif) =>
                            notif.id === id ? { ...notif, status: notif.status === 1 ? 0 : 1 } : notif
                        )
                    );
                }
            })
            .catch((error) => {
                console.error("Error toggling notification status:", error);
            });
    };

  

    return (
        <div>
           {loading && <Loader/>}
                <>
                    <Navbar />
                    <Container className="mt-5">
                        <div className="text-center mb-4 title fs-5">
                            {_("notifications")}
                        </div>
                        <Card className="p-4 shadow-sm">
                            <Row className="mb-3">
                                <Col md={6} className="mx-auto">
                                    <Input
                                        type="search"
                                        className="search-input"
                                        placeholder={_("search")}
                                        aria-label={_("search")}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </Col>

                            </Row>
                            <DataTable
                                columns={columns}
                                responsive
                                pagination
                                expandableRows
                                expandableRowsComponent={ExpandableComponent}
                                paginationPerPage={100}
                                paginationComponentOptions={customText}
                                noDataComponent={_('no_records')}
                                paginationRowsPerPageOptions={[100, 500, 1000, 5000, 10000]}
                                data={filteredNotifications}
                                expandOnRowClicked // Satıra tıklandığında genişlet
                                expandableRowExpanded={row => expandedRows.includes(row)}
                            />
                        </Card>
                    </Container>
                    <Footer />
                    <Modal show={flagModal} onHide={() => setFlagModal('')}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                            <Modal.Title
                                style={{ fontSize: 16 }}
                            ><span className="homeText">{_("remove_sign")}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <h6 className="homeText">{_("sure")}</h6>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <div className="row">
                                    <div className="col text-end" onClick={(e) => toggleNotificationStatus(flagModal) + setFlagModal('')}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-check-circle-fill"></i>
                                        </a>
                                    </div>
                                    <div className="col text-end" onClick={() => setFlagModal('')}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-x-circle-fill"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
        </div>
    );
};

export default Notifications;