import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import globals from "../config/globals/globals";
import axios from "axios";
import moment from 'moment';
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet";
import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Loader from "../components/Loader";

const GetCollaboration = () => {
    const [collaboration, setCollaboration] = useState([]);
    const cookies = new Cookies();
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [collaborationModal, setCollaborationModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState("");
    const [detailModal, setDetailModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        HTTP.get('getCollaboration').then(res => {
            if (res.data.result) {
                setLoading(false); 
                setCollaboration(res.data.data);
            }
        });
    }, []);   

    useEffect(() => {
        const lang = cookies.get("language");
    }, []);

    console.log(collaboration)

    
    const columns = [
        {
            name: _('company_trade_name'),
            selector: row => row.company_trade_name,
            sortable: true,
            width: '200px',
            wrap: true
        },
         {
              name: _("details"),
              cell: (row) => (
                <button
                  className="bi bi-arrows-angle-expand"
                  onClick={() => {
                    setSelectedRow(row); 
                    setDetailModal(true); 
                  }}
                ></button>
              ),
              minWidth: "15px",
            },
       
    ];
console.log(columns)
    const customText = {
        rowsPerPageText: _("number_of_rows_per_page"),
        rangeSeparatorText: _("between"),
    };

  
    
    return (
       
        <div>
            {
                loading ? <Loader />:
                <div>
                <Helmet>
                    <title>Vidipass | {_("contact")}</title>
                    <meta name="description" content={_("contact")} />
                    <meta name="robots" content="noindex" />
                    <link rel="alternate" href={window.location.href} hreflang="tr" />
                    <link rel="canonical" href={window.location.href} hreflang="tr" />
                </Helmet>
                <Navbar />
                <div className="container mb-5 mt-5">
                <div className="card-body row">
                        <h1 className="text-center mb-4 title fs-5">{_('collaboration_forms')}</h1>
                    </div>
                    <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                        <div className="card card1 col">
                            <div className="row justify-content-center">
                                <div className="col-12 ">
                                    <div className="col-sm-12 col-md-4 mb-2 mx-2 mt-2">
                                        <input
                                            type="search"
                                            className="form-control bg-white headerInput text-muted"
                                            placeholder={_("search")}
                                            aria-label={_("search")}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}>
                                        </input>
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            responsive
                                            pagination
                                            paginationPerPage={100}
                                            paginationComponentOptions={customText}
                                            noDataComponent={_('no_records')}
                                            paginationRowsPerPageOptions={[100, 500, 1000, 5000, 10000]}
                                            expandOnRowClicked
                                            data={collaboration.filter((item) => {
                                                if (searchTerm === "") {
                                                    return item;
                                                } else if (
                                                    item.company_trade_name?.toLowerCase().includes(searchTerm.toLowerCase())
                                                ) {
                                                    return item;
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span ref={divRef} /> */}
                </div>
                <Footer />
                 <Modal show={detailModal} onHide={() => setDetailModal(false)}>
                                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                                <Modal.Title
                                                    style={{ fontSize: 16 }} 
                                                ><span className="homeText">{_("details")}</span></Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body >
                                                 {selectedRow ? (
                                                          <div className="row gap-2" style={{ fontSize: 12 }}>
                                                            <div className="form-group">
                                                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("owner_company_info")}</h1>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_trade_name")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_trade_name}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("mersis")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.mersis}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_commercial_registry")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_commercial_registry}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_tax_office")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_tax_office}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_tax_number")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_tax_number}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_headquarters_address")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_headquarters_address}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_phone_number")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_phone_number}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_email_address")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_email_address}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_actividi_address")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_actividi_address}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_vidipass_address")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_vidipass_address}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("info_employer_company")}</h1>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("name_surname_company_official")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.name_surname_company_official}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_official_identification_number")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_official_identification_number}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_official_corporate_title")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_official_corporate_title}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("company_official_actividi_address")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.company_official_actividi_address}</p>
                                                            </div>
                                                             <div className="form-group">
                                                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("info_authority_company")}</h1>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("name_surname_approval_authority")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.name_surname_approval_authority}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("identification_approval_authority")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.identification_approval_authority}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("corporate_approval_authority")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.corporate_approval_authority}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("email_approval_authority")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.email_approval_authority}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("phone_approval_authority")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.phone_approval_authority}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("actividi_approval_authority")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.actividi_approval_authority}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("bank_info_owner_company")}</h1>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("title_first_bank")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.title_first_bank}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("branch")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.first_branch}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("account_number")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.first_account_number}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("iban")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.first_iban}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("title_second_bank")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.title_second_bank}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("branch")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.second_branch}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("account_number")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.second_account_number}</p>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                              <p className="fw-bold text-muted mb-0 col-md-6">
                                                                {_("iban")}
                                                              </p>
                                                              <p className="mb-0 col-md-6">{selectedRow.second_iban}</p>
                                                            </div>


                                                          </div>
                                                        ) : (
                                                          <p>{_("no_records")}</p>
                                                        )}
                                                
                                            </Modal.Body>
                                        </Modal>

             
            </div>

            }
           
        </div>
    );
};

export default GetCollaboration;