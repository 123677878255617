import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import React, { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import Loader from "../components/Loader";
import TagManager from 'react-gtm-module'
import { useDispatch } from "react-redux";
import { saveContact } from "../redux/actions/auth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import globals from "../config/globals/globals";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import AddActivity from "./AddActivity";
import DataTable from 'react-data-table-component';
import moment from "moment";
import { tr, de, enUS, af, ar, az, bg, bs, cy, el, es, fi, fr, he, hi, hr, hu, it, ja, kk, ko, lb, mk, ms, nl, nn, pt, ro, ru, sq, sr, sv, uk, zhTW } from "date-fns/locale";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';




const Activities = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [activityEditModal, setActivityEditModal] = useState(false);
    const [activityId, setActivityId] = useState("");
    const [activityTitle, setActivityTitle]= useState("")
    const [activityDescription, setActivityDescription]= useState("")
    const [activityComment, setActivityComment]= useState("")
    const [metaDescription, setMetaDescripton]= useState("")
    const [metaKeywords, setMetaKeywords] = useState([])
    const [activityPlace, setActivityPlace]= useState("")
    const [activityStartTime, setActivityStartTime] = useState("")
    const [activityEndTime, setActivityEndTime] = useState("")
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utcLog, setUtcLog] = useState('')
    const [userId, setUserId] = useState("");
    const formData = new FormData();

    const [editorModal, setEditorModal] = useState(false);
    const [metaTag, setMetaTag] = useState('')
    const [activityStatusModal, setActivityStatusModal] = useState(false);
    const [activityStatus, setActivityStatus] = useState("");
    const [activityStatusStopModal, setActivityStatusStopModal] = useState(false);
    const [activityStatusCancelModal, setActivityStatusCancelModal] = useState(false);
    const [number, setNumber] = useState('');
    const [userType, setUserType] = useState('');
    const [openDate, setOpenDate] = useState(false);
    const cookies = new Cookies();
    const [lang] = useState(cookies.get("language"));
    const [activity, setActivity] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]); // Genişletilen satırları takip etmek için state
    const [selectedData, setSelectedData] = useState([]);

    useEffect(() => {
        setSelectedData(activity);
      }, [activity]);

      // Seçimi takip etmek için handleCheckbox fonksiyonu
  const handleCheckboxChange = (row) => {
    const isSelected = selectedData.some(item => item.id === row.id);
    
    if (isSelected) {
      // Eğer zaten seçiliyse, seçimi kaldır
      setSelectedData(prev => prev.filter(item => item.id !== row.id));
    } else {
      // Eğer seçili değilse, seçilen veriyi ekle
      setSelectedData(prev => [...prev, row]);
    }
  };

  // Tüm checkboxları seç veya seçimleri kaldır
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedData(activity); // Hepsini seç
    } else {
      setSelectedData([]); // Tüm seçimleri kaldır
    }
  };

  // Tüm satırlar seçili mi kontrolü
  const isAllSelected = selectedData.length === activity.length;

      
   
    const handleRowExpand = (row) => {
        // Eğer satır genişletilmişse kapat, değilse genişlet
        if (expandedRows.includes(row)) {
            setExpandedRows(expandedRows.filter(item => item !== row));
        } else {
            setExpandedRows([...expandedRows, row]);
        }
    };

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser?.result) {
            setUserId(getuser.user.id);
            setUserType(getuser.user.type);
        }
    }, []);
    
    const [date, setDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
            key: "selection"
        }
    ]);

   
 

    useEffect(() => {
      
        const start = moment(date[0].startDate).format("YYYY-MM-DD");
        const end = moment(date[0].endDate).format("YYYY-MM-DD");
        setLoading(true);
        HTTP.get(`getFilterActivities?term&start=${start}&end=${end}&userId=${JSON.parse(localStorage.getItem("auth")).user.id}&city&cats`)
            .then((res) => {
                setLoading(false);
                if (res.data.result) {
                    setActivity(res.data.activities.data)
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);

    useEffect(()=>{
        setDevice(navigator.userAgent)
    },[])

    useEffect(()=>{
        setUtcLog(new Date().getTimezoneOffset()/-60)
    },[])

    useEffect(()=>{
        HTTP.get('https://api.ipify.org?format=json')
        .then(
            res=>{
                setIp(res.data.ip)
            }
        )
    },[])

    const columns = [
        {
            name: (
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
            ),
            grow: 0.1,
            cell: row => (
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={selectedData.some(item => item.id === row.id)}
                onChange={() => handleCheckboxChange(row)}
              />
            ),
          },
        {
            name: _('photo'),
            grow:0.5,
            cell: row => 
                <a href={globals.url + 'a/' + row.number}>
                    <img height="50px" width="100px" src={globals.imgUrl + row.id + "/thumb/" + row.photo_name + ".png" + '?' + new Date(row.created_at).getTime()} />
                </a>,
            
            
        },
        {
            name: _('activity_name'),
            selector: row => row.title,
            sortable: true,
        },
        {
            name: _('start_time'),
            selector: row => row.start_time,
            sortable: true,
        },
        {
            name: _('place'),
            selector: row => row.hall.name,
            sortable: true,
        },
        {
            name: _('total_ticket'),
            selector: row => row.total_tickets,
            sortable: true,
            center: true,
        },
        {
            name: _('total_ticket_sold'),
            selector: row => row.totalSellCount,
            sortable: true,
            center: true,
        },
        {
            name: _('daily_sale') + " " +"(" + _("piece") + ")",
            selector: row => row.totalSellDayCount,
            sortable: true,
            center: true,
        },
        {
            name: _('daily_sale') + " (TRY)",
            selector: row => row.totalSellDayPrice,
            sortable: true,
            center: true,
        },
        {
            name: _('total_sale') + " (TRY)",
            selector: row => row.total,
            sortable: true,
            center: true,
            cell: row => {
                const totalAmount = row.total;
                // Check if there's a decimal part
                if (totalAmount % 1 !== 0) {
                    // Show 2 decimal places if there's a decimal part
                    return totalAmount.toFixed(2);
                } else {
                    // Otherwise, show it without decimal places
                    return totalAmount.toString();
                }
            },
        },
        {
            name: _('event_detail'),
            button: true,
            selector: row => (
                <a className="homeText text-decoration-none" href={globals.url + 'a/' + row.number} rel="noopener noreferrer">
                    {row.number}
                </a>
            ),
            minWidth: '200px',
           
        },
        {
            name: _('actions'),
            button: true,
            cell: row => (
                <span className="d-flex">
             {(userType == 3 || userType == 2) &&
             <>
                <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>window.location.href=globals.url + 'a/activityreport/' + row.number} target="_blank" rel="noopener noreferrer" title={_('activity_report')}>
                    <span style={{color:"#FFFFFF"}} className="bi bi-eye m-1"></span>
                </button>
                   <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>changeActivityStatusEdit(row)} target="_blank" rel="noopener noreferrer" title={_('activity_control')}>
                   <span style={{color:"#FFFFFF"}} class="bi bi-sliders m-1"></span>
               </button>
               {userType == 3 &&
               <>
                <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>activityEdit(row)} target="_blank" rel="noopener noreferrer" title={_('activity_edit')}>
                    <span style={{color:"#FFFFFF"}} class="bi bi-pencil m-1"></span>
                </button>
                <button style={{border:'none'}} className="mx-1 p-1 saloonButton rounded" onClick={()=>activityEditor(row)} target="_blank" rel="noopener noreferrer" title={_('activity_packages')}>
                    <span style={{color:"#FFFFFF"}} class="bi bi-chat-square-dots m-1"></span> 
                </button>
                </>
                }
             </>
             }
            </span>
                
            )
           
        },       
    ];

    const customText = {
        rowsPerPageText: _("number_of_rows_per_page"),
        rangeSeparatorText: _("between"),
    };
    
const activityEdit = (item) =>{
    setActivityId(item.id)
    setActivityTitle(item.title)
    setActivityDescription(item.description)
    setActivityComment(item.comment)
    setMetaDescripton(item.meta_description)
    if (item.meta_keywords !== null && item.meta_keywords !== '') {
        setMetaKeywords(JSON.parse(item.meta_keywords));
      }
    setActivityPlace(item.place.id)
    setActivityStartTime(new Date(item.start_time))
    setActivityEndTime(new Date(item.end_time))
    setActivityEditModal(true)
}

const activityEditor = (item) =>{
    setActivityId(item.id)
    setActivityTitle(item.title)
    setActivityDescription(item.description)
    setActivityComment(item.comment)
    setMetaDescripton(item.meta_description)
    if (item.meta_keywords !== null && item.meta_keywords !== '') {
        setMetaKeywords(JSON.parse(item.meta_keywords));
      }
    setActivityPlace(item.place.id)
    setActivityStartTime(new Date(item.start_time))
    setActivityEndTime(new Date(item.end_time))
    setEditorModal(true)
}

const saveMetaTag = () =>{
    if(metaKeywords.length < 9){
        if(metaTag != ''){
            if(metaKeywords.indexOf(metaTag)== -1){
                setMetaKeywords(data=>[...data, (metaTag)])
            }
            setMetaTag('')
        }
    }else{
        setMetaTag('')
    }
}
const removeMetaTag = (data) =>{
    setMetaKeywords(metaKeywords.filter(item=>item!==data))
}
const tagList = () =>{
    return metaKeywords.map((data)=>{
        return(
            <div class="col d-flex justify-content-center">
                <div key={data} class=" text-decoration-none btn btn-primary saloonButton rounded-2">{data}</div>
                <button  onClick={() => removeMetaTag(data)} className="btn btn-secondary bi bi-x-circle-fill"/>
            </div>
        )
    })
}
const sendEditorPanel = () =>{
    formData.append("id", activityId);
    formData.append("activity_title", activityTitle);
    formData.append("description", activityDescription);
    formData.append("comment", activityComment);
    formData.append("meta_description", metaDescription);
    formData.append("meta_keywords", JSON.stringify(metaKeywords));
    formData.append("place_id", activityPlace);
    formData.append("start_time", moment(activityStartTime).format("DD.MM.YYYY HH:mm"));
    formData.append("end_time", moment(activityEndTime).format("DD.MM.YYYY HH:mm"));
    formData.append("ip_address", ip);
    formData.append("device_name", device);
    formData.append("utc", utcLog);
    formData.append("userId", userId);

    HTTP.post("/activities/update", formData)
        .then((res) => {
            if (res.data.result) {
                
                    setEditorModal(false);
                    window.location.reload();
                
            } else {
                setLoading(true);
            }
        })
        .catch(setLoading(true));

}

const changeActivityStatus = (val) => {
    formData.append("number", number);
    formData.append("activity_status", val);
    formData.append("ip_address", ip);
    formData.append("device_name", device);
    formData.append("utc", utcLog);
    formData.append("userId", userId);

    setLoading(true);
    HTTP.post("/changeActivityStatus", formData)
        .then((res) => {
            if (res.data.result) {
                if (res.data.activity.deleted_at) {
                    setActivityStatusModal(false);
                    setLoading(false);
                    setActivityStatusCancelModal(false)
                    setActivityStatusStopModal(false)
                    window.location.reload();
                   
                } else {
                    setActivityStatusModal(false);
                    setLoading(false);
                    setActivityStatusCancelModal(false)
                    setActivityStatusStopModal(false)
                    window.location.reload();
                    
                }
            } else {
                setLoading(true);
            }
        })
        .catch(setLoading(true));
};

const activityChangeStatus = (val) => {
    if (val != 2) {
        setActivityStatusStopModal(true);
    } else {
        setActivityStatusCancelModal(true);
    }
};

const changeActivityStatusEdit = (item) =>{
    setNumber(item.number)
    setActivityStatus(item.activity_status);
    setActivityStatusModal(true)
}


 // Prepare data for the pie chart
 const pieDataAll = selectedData?.map(val => ({
    name: val.title,
    solded_tickets: val.totalSellCount,
    total_tickets: val.total_tickets,
  }));

  const top5Data = pieDataAll
  .sort((a, b) => b.solded_tickets - a.solded_tickets) // solded_tickets'a göre azalan sırada sıralar
  .slice(0, 5); // İlk 20 elemanı alır

  const getRandomColorAll = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
    
    // Dinamik renk oluşturma
    const generateColorsAll = (length) => {
      const colors = new Set();
      while (colors.size < length) {
        colors.add(getRandomColorAll());
      }
      return Array.from(colors);
    };

    const colorsAll = generateColorsAll(top5Data.length); // Dinamik renkler
const ExpandableComponent = ({ data }) => {
    // Prepare data for the pie chart
    const pieData = data.hall.groups.map(group => ({
      name: group.name,
      solded_tickets: group.solded_tickets,
      total_tickets: group.total_tickets,
    }));

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      };
      
      // Dinamik renk oluşturma
      const generateColors = (length) => {
        const colors = new Set();
        while (colors.size < length) {
          colors.add(getRandomColor());
        }
        return Array.from(colors);
      };

      const colors = generateColors(pieData.length); // Dinamik renkler
    return (
        <div className="expandable-container">
             {
            data.total>0 &&
        <div className="row">
          {/* Total Sales and Daily Sales Card */}
         
            <div className="col-lg-6 col-md-12">
            <div className="card shadow-lg border-0 rounded-lg mb-4">
              <div className="card-body">
                <h5 className="reportText">
                {_("sold_ticket")} : <span className="fw-bold reportText">{data.totalSellCount} | {data.total_tickets}</span>
                </h5>
                <h5 className="reportText">
                {_("total_sale")} : <span className="fw-bold reportText">{data.total} TRY</span>
                </h5>
                <h6 className="reportText">
                {_("daily_sale")} :  <span className="fw-bold reportText">{data.totalSellDayCount} {_("piece")} | {data.totalSellDayPrice} TRY</span>
                </h6>
              </div>
            </div>
          </div>
         
          
  
          {/* Ticket Distribution Pie Chart */}
          <div className="col-lg-6 col-md-12">
                <div className="card shadow-lg border-0 rounded-lg mb-4">
                    <div className="card-body text-start">
                    <h5 className="reportText">{_("ticket_sale_distribution")}</h5>
                    <PieChart width={400} height={300} className="ms-0">
                        <Pie
                        data={pieData}
                        dataKey="solded_tickets"
                        nameKey="name"
                        cx="50%" 
                        cy="50%"
                        outerRadius={60}
                        label
                        >
                        {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.solded_tickets === 0 ? '#D9D9D9' : colors[index]} />
                        ))}
                        </Pie>
                        <Tooltip />
                        <Legend formatter={(value) => (value.length > 15 ? `${value.slice(0, 15)}...` : value)} />
                    </PieChart>
                    </div>
                </div>
            </div>

        </div>
    }
  
        {/* Group Sales */}
        <div className="group-sales mt-3">
          {data.hall.groups.map((group, index) => (
            <div key={index} className="card shadow-sm border-0 rounded-lg mb-4">
              <div className="group-header bg-dark text-white p-2 rounded-top">
                <h6 className="mb-1">{group.name}</h6>
              </div>
              <div className="group-body bg-light p-3 rounded-bottom">
                <p> {_("sold_ticket")} : <span className="fw-bold">{group.solded_tickets} | {group.total_tickets}</span></p>
                <p> {_("total_sale")} : <span className="fw-bold">{group.total} TRY</span></p>
  
                {/* Prices per Group */}
                <ul className="list-group small">
                  {group.prices.map((price, idx) => (
                    <li key={idx} className="list-group-item border-0 p-1">
                      <strong>{price.name}</strong>: {price.solded_tickets} | {price.total} TRY
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const totals = selectedData.reduce(
    (acc, item) => {
      acc.total += item.total || 0;
      acc.totalSellCount += item.totalSellCount || 0;
      acc.totalSellDayCount += item.totalSellDayCount || 0;
      acc.totalSellDayPrice += item.totalSellDayPrice || 0;
      acc.total_tickets += item.total_tickets || 0;
      return acc;
    },
    { total: 0, totalSellCount: 0, totalSellDayCount: 0, totalSellDayPrice: 0, total_tickets: 0 }
  );
    return (
        <div >
            {loading ? <Loader/> :
            <>
                    <Navbar />
                    <div className="container mt-5 mb-5">
                        <div className="justify-content-center card">
                        <div className="justify-content-center mb-3 mt-3 row">
                                <form
                                    className="row"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const start = moment(date[0].startDate).format("YYYY-MM-DD");
                                        const end = moment(date[0].endDate).format("YYYY-MM-DD");
                                        setLoading(true);
                                        HTTP.get(`getFilterActivities?term&start=${start}&end=${end}&userId=${JSON.parse(localStorage.getItem("auth")).user.id}&city&cats`)
                                            .then((res) => {
                                                setLoading(false);
                                                if (res.data.result) {
                                                    setActivity(res.data.activities.data)
                                                } else {
                                                    setLoading(false);
                                                }
                                            })
                                            .catch(setLoading(true));
                                       
                                    }}
                                >
                               <div className="col-8 mb-2">
                                    <span
                                            onClick={() => setOpenDate(!openDate)}
                                            type="search"
                                            className="form-control bg-white headerInput">
                                            {`${format(date[0].startDate, "dd.MM.yyyy")} - ${format(date[0].endDate, "dd.MM.yyyy")}`}
                                        </span>

                                      
                                    </div>
                                        <div className="col-2">
                                            <button
                                                onClick={() => ('search')}
                                                type="submit"
                                                className="btn btn-primary text-center btn1-color"
                                            >
                                                <img
                                                    className="bi bi-search"
                                                    width="25"
                                                    height="auto"
                                                    style={{ fill: "currentColor" }}
                                                    src={images.explore_disable_icon}
                                                    alt="search"
                                                />
                                            </button>
                                        </div>
                                </form>
                                {openDate && (
                                            <div className="mb-2" style={{ backgroundColor: '#EFF2F7', borderRadius: 4 }}>
                                                <div className="d-flex justify-content-center">
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={(item) => setDate([item.selection])}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={date}
                                                        className="date"
                                                        dateDisplayFormat="dd.MM.yyyy"
                                                        minDate={new Date(2022, 12, 1)}
                                                        locale={
                                                            lang == "af" ? af :
                                                            lang == "ar" ? ar :
                                                            lang == "az" ? az :
                                                            lang == "bg" ? bg :
                                                            lang == "bs" ? bs :
                                                            lang == "cy" ? cy :
                                                            lang == "de" ? de :
                                                            lang == "el" ? el :
                                                            lang == "es" ? es :
                                                            lang == "fi" ? fi :
                                                            lang == "fr" ? fr :
                                                            lang == "he" ? he :
                                                            lang == "hi" ? hi :
                                                            lang == "hr" ? hr :
                                                            lang == "hu" ? hu :
                                                            lang == "it" ? it :
                                                            lang == "ja" ? ja :
                                                            lang == "kk" ? kk :
                                                            lang == "ko" ? ko :
                                                            lang == "lb" ? lb :
                                                            lang == "mk" ? mk :
                                                            lang == "ms" ? ms :
                                                            lang == "nl" ? nl :
                                                            lang == "nn" ? nn :
                                                            lang == "pt" ? pt :
                                                            lang == "ro" ? ro :
                                                            lang == "ru" ? ru :
                                                            lang == "sq" ? sq :
                                                            lang == "sr" ? sr :
                                                            lang == "sv" ? sv :
                                                            lang == "tk" ? tr :
                                                            lang == "tr" ? tr :
                                                            lang == "tw" ? zhTW :
                                                            lang == "uk" ? uk : enUS
                                                        }
                                                        rangeColors={["#D500F9"]}
                                                        color="#D500F9"
                                                    />
                                                </div>
                                                <div className="col text-center" onClick={() => setOpenDate(false)}>
                                                    <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            {_('ok')}
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                <div className="row">
                                    <div className="col-8 mb-2">
                                        <input
                                            type="search"
                                            className="form-control bg-white headerInput text-muted"
                                            placeholder={_("search")}
                                            aria-label={_("search")}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}>
                                        </input>
                                    </div>

                                </div>
                                <div className="container">
                                {
                                            totals.total>0 &&
                                    <div className="row px-2">
                                        
                                            <div className="col-lg-6 col-md-12">
                                            <div className="card shadow-lg border-0 rounded-lg mb-4">
                                                <div className="card-body row d-flex justify-content-center">
                                                <span className="reportText">
                                                {_('total_sale')} : 
                                                <span className="fw-bold reportText">
                                                    {totals.total % 1 !== 0 ? `${totals.total.toFixed(2)} TRY` : `${totals.total} TRY`}
                                                </span>
                                                </span>
                                                <span className="reportText">
                                                 {_("total_ticket")} : <span className="fw-bold reportText">{totals.total_tickets}</span>
                                                </span>
                                                <span className="reportText">
                                                 {_("total_ticket_sold")} : <span className="fw-bold reportText">{totals.totalSellCount}</span>
                                                </span>
                                                <span className="reportText">
                                                  {_("daily_sale")} : <span className="fw-bold reportText">{totals.totalSellDayCount} Adet | {totals.totalSellDayPrice} TRY</span>
                                                </span>
                                                </div>
                                            </div>
                                            </div>
                                       
                                           
                                            <div className="col-lg-6 col-md-12">
                                            <div className="card shadow-lg border-0 rounded-lg p-3">
                                                <h5 className="reportText">{_("ticket_sale_distribution")} ({_("top_5")})</h5>
                                                <PieChart width={400} height={300} className="m-auto">
                                                <Pie
                                                    data={top5Data}
                                                    dataKey="solded_tickets"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={60}
                                                    label
                                                    // labelLine={false}
                                                >
                                                    {top5Data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={entry.solded_tickets === 0 ? '#D9D9D9' : colorsAll[index]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                                <Legend formatter={(value) => (value.length > 15 ? `${value.slice(0, 15)}...` : value)} />
                                                </PieChart>
                                            </div>
                                            </div>
                                      
                                       
                                    </div>
                                      }
                                    </div>

                             

                            </div>
                      
                                    <DataTable
                                    columns={columns}
                                    responsive
                                    pagination
                                    paginationPerPage={100}
                                    paginationComponentOptions={customText}
                                    expandableRows
                                    expandableRowsComponent={ExpandableComponent}
                                    expandOnRowClicked // Satıra tıklandığında genişlet
                                    expandableRowExpanded={row => expandedRows.includes(row)}
                                    paginationRowsPerPageOptions={[100, 500, 1000, 5000, 10000]}
                                    data={(activity).filter((item) => {
                                        if (searchTerm === "") {
                                          return item;
                                        } else if (
                                            item.title?.toLowerCase().includes(searchTerm.toLowerCase())
                                            ||
                                            item.hall.name?.toLowerCase().includes(searchTerm.toLowerCase())
                                            ||
                                            item.total_tickets?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                                            ||
                                            item.totalSellCount?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                                            ||
                                            item.total?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                                        ) {
                                          return item;
                                        }
                                      })}
                                 
                                />
                        </div> 
                    </div>
                    <Modal
                            show={activityEditModal}
                            onHide={() => setActivityEditModal(false)}
                            
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_edit")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card px-2">
                                <form onSubmit={(e) => {
                                            e.preventDefault();

                                            formData.append("id", activityId);
                                            formData.append("activity_title", activityTitle);
                                            formData.append("description", activityDescription);
                                            formData.append("comment", activityComment);
                                            formData.append("meta_description", metaDescription);
                                            formData.append("meta_keywords", JSON.stringify(metaKeywords));
                                            formData.append("place_id", activityPlace);
                                            formData.append("start_time", moment(activityStartTime).format("DD.MM.YYYY HH:mm"));
                                            formData.append("end_time", moment(activityEndTime).format("DD.MM.YYYY HH:mm"));
                                            formData.append("ip_address", ip);
                                            formData.append("device_name", device);
                                            formData.append("utc", utcLog);
                                            formData.append("userId", userId);
                                    
                                            HTTP.post("/activities/update", formData)
                                                .then((res) => {
                                                   
                                                    if (res.data.result) {
                                                        
                                                            setActivityEditModal(false);
                                                            window.location.reload();
                                                        
                                                    } else {
                                                        setLoading(true);
                                                    }
                                                })
                                                .catch(setLoading(true));
                                            
                                            
                                        }} >
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("activity_title")}</label>
                                                <input type="text" value={activityTitle} onChange={(e) => setActivityTitle(e.target.value)} className="form-control" />
                                            </div>

                                            <div className="form-group mt-1">
                                                <label className="form-control-label text-muted">{_("place")} ( id )</label>
                                                <input type="number" value={activityPlace} onChange={(e) => setActivityPlace(e.target.value)} className="form-control bg-white" />
                                            </div>

                                            <div className="row mt-2 text-center">

                                            <div className="form-group col-6">
                                                <div className="text-start">

                                                <label className="form-control-label text-muted">{_("start_time")}</label>
                                                </div>
                                                <div>
                                               
                                                <DatePicker
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={5}
                                                dateFormat="dd.MM.yyyy / HH:mm"
                                                className="form-control text-start"
                                                selected={activityStartTime} 
                                                onChange={(e) => setActivityStartTime(e)}                                                 
                                                />
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div className="text-start">
                                                    <label className="form-control-label text-muted">{_("end_time")}</label>
                                                </div>
                                                <div>
                                                    <DatePicker
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    dateFormat="dd.MM.yyyy / HH:mm"
                                                    className="form-control  text-start"
                                                    selected={activityEndTime} 
                                                    onChange={(e) => setActivityEndTime(e)} 
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("description")}</label>
                                                <textarea rows="4" cols="50" value={activityDescription} onChange={(e) => setActivityDescription(e.target.value)}></textarea>
                                            </div>
                                            {/* <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("editor_comment")}</label>
                                                <textarea rows="4" cols="50" value={activityComment} onChange={(e) => setActivityComment(e.target.value)}></textarea>
                                            </div> */}
                                            <div className="row justify-content-center my-3 px-3">
                                                <button className="text-decoration-none btn btn-primary cardButton col-6" type="submit">{_("send")}</button>
                                            </div>
                                        </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={editorModal}
                            onHide={() => setEditorModal(false)}
                            
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_packages")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card px-2">
                                    <div className="form-group mb-2 mt-2">
                                        <label className="form-control-label text-muted">{_("editor_comment")}</label>
                                        <textarea rows="4" cols="50" value={activityComment} onChange={(e) => setActivityComment(e.target.value.trimStart())}></textarea>
                                    </div>
                                    <div className="form-group mb-2 mt-2">
                                        <label className="form-control-label text-muted">{_("meta_description")}</label>
                                        <textarea rows="4" cols="50" value={metaDescription} onChange={(e) => setMetaDescripton(e.target.value.trimStart())}></textarea>
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="form-control-label text-muted">{_("meta_keyword")}</label>
                                        <div className="input-group">
                                            <input type="text" value={metaTag.trim()} onChange={(e) =>setMetaTag(e.target.value)} className="form-control text-muted bg-white" />
                                            <button  onClick={() => saveMetaTag()} className="btn btn-secondary text-center bg-secondary">{_('add')}</button>
                                        </div>
                                        <div className="row grid gap-2 py-2 d-flex justify-content-center">
                                            {tagList()}
                                        </div>
                                    </div>
                                    <div onClick={()=>sendEditorPanel()} className="row justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton col-6">{_("send")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusCancelModal}
                            onHide={() => setActivityStatusCancelModal(false)}
                        >   
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("cancel_activity")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div
                                            className="col text-end"
                                            onClick={() => changeActivityStatus(2)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div
                                            className="col text-end"
                                            onClick={() => setActivityStatusCancelModal(false)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusStopModal}
                            onHide={() => setActivityStatusStopModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    {activityStatus == 0 ? (
                                        <span className="homeText">
                                            {_("stop_selling_tickets_for_event")}
                                        </span>
                                    ) : (
                                        <span className="homeText">
                                            {_("continue_selling_tickets_for_evet")}
                                        </span>
                                    )}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div
                                            className="col text-end"
                                            onClick={
                                                activityStatus == 0
                                                    ? () => changeActivityStatus(1)
                                                    : () => changeActivityStatus(0)
                                            }
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div
                                            className="col text-end"
                                            onClick={() => setActivityStatusStopModal(false)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusModal}
                            onHide={() => setActivityStatusModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_control")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card">
                                    <div className="m-2 row">
                                        {activityStatus == 0 ? (
                                            <a
                                                onClick={() => activityChangeStatus(1)}
                                                className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col me-1"
                                            >
                                                {_("stop_selling_tickets_for_event")}
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() => activityChangeStatus(0)}
                                                className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col ms-1"
                                            >
                                                {_("continue_selling_tickets_for_evet")}
                                            </a>
                                        )}

                                        <a
                                            onClick={() => activityChangeStatus(2)}
                                            className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col ms-1"
                                        >
                                            {_("cancel_activity")}
                                        </a>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
}
            <Footer />
        </div>
    )
}

export default Activities