import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import Loader from "../components/Loader";
import TagManager from 'react-gtm-module'
import { useDispatch } from "react-redux";
import { saveContact } from "../redux/actions/auth";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import globals from "../config/globals/globals";
import { Helmet } from "react-helmet";
import axios from "axios";


const ContactForm = () => {
    const dispatch = useDispatch();   
    const cookies = new Cookies()
    const divRef = useRef(null)
    const [loading, setLoading] = useState(false);
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    const [companyTradeName, setCompanyTradeName] = useState ('');
    const [mersis,setMersis] = useState('');
    const [commercialRegistry, setCommercialRegistry] = useState('');
    const [companyTaxOffice, setCompanyTaxOffice] = useState ('');
    const [companyTaxNumber, setCompanyTaxNumber] = useState ('');
    const [headquartersAdress, setHeadquartersAdress] = useState ('');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState ('');
    const [companyEmailAddress, setCompanyEmailAddress] = useState ('');
    const [companyActividiAddress, setCompanyActividiAddress] = useState ('');
    const [companyVidipassAddress, setCompanyVidipassAddress] = useState ('');
    const [officialNameSurname, setOfficialNameSurname] = useState ('');
    const [officialIdentificationNumber, setOfficialIdentificationNumber] = useState ('');
    const [officialCorporateTitle, setOfficialCorporateTitle] = useState ('');
    const [officialActividi, setOfficialActividi] = useState ('');
    const [approvalNameSurname, setApprovalNameSurname] = useState ('');
    const [approvalIdentification, setApprovalIdentification] = useState ('');
    const [corporateApprovalAuthority, setCorporateApprovalAuthority] = useState ('');
    const [approvalEmail, setApprovalEmail] = useState ('');
    const [approvalPhone, setApprovalPhone] = useState ('');
    const [approvalActividi, setApprovalActividi] = useState ('');
    const [firstBank, setFirstBank] = useState ('');
    const [firstBranch, setFirstBranch] = useState ('');
    const [firstAccountNumber, setFirstAccountNumber] = useState ('');
    const [firstIban, setFirstIban] = useState ('');
    const [secondBank, setSecondBank] = useState ('');
    const [secondBranch, setSecondBranch] = useState ('');
    const [secondAccount, setSecondAccount] = useState ('');
    const [secondIban, setSecondIban] = useState ('');
    const [verify, setVerify] = useState(false);
    const [isIndividualEnterprise, setIsIndividualEnterprise] = useState(false);


    

    const onChange = (value) => {
        setVerify(true);
    }



    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])


    function emailControl(data) {
        // E-posta uzunluğunu kontrol et (en az 3, en fazla 100 karakter)
        if (data.length < 3 || data.length > 100) {
            return false; // Eğer uzunluk 3'ten küçük veya 100'den büyükse, geçersiz
        }
        // E-posta formatını kontrol et (regex ile)
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(data); // Regex'e uygunsa true, değilse false döner
    }

    console.log(isIndividualEnterprise)


    return (
        <div >
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5 mt-5">
                    <div className="card-body d-flex row text-center mb-3">
                            <h1 className="title fs-5 mt-1">{_('collaboration_info')}</h1>
                        </div>
                        <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                            <div className="card card1 col">
                                <div className="row justify-content-center my-auto">
                                    <div className="col-md-8 col-10">
                                        <div className="row justify-content-center mb-3 mt-3">
                                            <img width={25} height={'auto'} src={images.vidipass_logo} style={{width:200}} alt="vidipass logo" />
                                        </div>
                                        <div className="d-flex align-items-center gap-2 mb-3">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input mt-0" 
                                                id="exampleCheck1" 
                                                checked={isIndividualEnterprise} 
                                                onChange={(e) => setIsIndividualEnterprise(e.target.checked)} 
                                            />
                                            <label className="text-muted mt-1">{_("sole_proprietorship")}</label>
                                        </div>
                                        <div className="form-group mb-2">
                                            <h1 style={{fontSize:16}} className="panel-title homeText">{_("collaboration_info")}</h1>
                                        </div>
                                        <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!emailControl(companyEmailAddress)) {
                                            toast(_("invalid_email"), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error",
                                            });
                                            return;
                                        }
                                    
                                        if (!emailControl(approvalEmail)) {
                                            toast(_("invalid_email"), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error",
                                            });
                                            return;
                                        }    
                                        const formData = new FormData();
                    

                                        formData.append("company_trade_name", companyTradeName);
                                        formData.append("mersis", !isIndividualEnterprise ? mersis : null);
                                        formData.append("company_commercial_registry", !isIndividualEnterprise ? commercialRegistry : null);
                                        formData.append("company_tax_office", companyTaxOffice);
                                        formData.append("company_tax_number", companyTaxNumber);
                                        formData.append("company_headquarters_address", headquartersAdress);
                                        formData.append("company_phone_number", companyPhoneNumber);
                                        formData.append("company_email_address", companyEmailAddress);
                                        formData.append("company_actividi_address", companyActividiAddress);
                                        formData.append("company_vidipass_address", companyVidipassAddress);
                                        formData.append("name_surname_company_official", officialNameSurname);
                                        formData.append("company_official_identification_number", officialIdentificationNumber);
                                        formData.append("company_official_corporate_title", officialCorporateTitle);
                                        formData.append("company_official_actividi_address", officialActividi);
                                        formData.append("name_surname_approval_authority", approvalNameSurname);
                                        formData.append("identification_approval_authority", approvalIdentification);
                                        formData.append("corporate_approval_authority", corporateApprovalAuthority);
                                        formData.append("email_approval_authority", approvalEmail);
                                        formData.append("phone_approval_authority", approvalPhone);
                                        formData.append("actividi_approval_authority", approvalActividi);
                                        formData.append("title_first_bank", firstBank);
                                        formData.append("first_branch", firstBranch);
                                        formData.append("first_account_number", firstAccountNumber);
                                        formData.append("first_iban", firstIban);
                                        formData.append("title_second_bank", secondBank);
                                        formData.append("second_branch", secondBranch);
                                        formData.append("second_account_number", secondAccount);
                                        formData.append("second_iban", secondIban);
                                                                        
                                        HTTP.post('/addCollaboration', formData)
                                        .then((res) => {
                                            if (res.data.result) {
                                                toast(_("successful"), {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                });
                                                setTimeout(() => window.location.reload(), 3000);
                                            } else {
                                                toast(res.data.message, {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "error",
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            toast(_("connection_error"), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error",
                                            });
                                        });
                                    }}>
                            <div className="form-group">
                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("owner_company_info")}</h1>
                            </div>

                            <div className="gap-3">
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_trade_name")}</label>
                                                <input type="text"value={companyTradeName} onChange={(e) => setCompanyTradeName(e.target.value.trimStart() )} placeholder={_("company_trade_name")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("mersis")}</label>
                                                <input type="number"value={mersis} onChange={(e) => setMersis(e.target.value)} placeholder={_("mersis")} className="form-control"  disabled={isIndividualEnterprise}/>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_commercial_registry")}</label>
                                                <input type="number"value={commercialRegistry} onChange={(e) => setCommercialRegistry(e.target.value)} placeholder={_("company_commercial_registry")} className="form-control"  disabled={isIndividualEnterprise}/>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_tax_office")}</label>
                                                <input type="text"value={companyTaxOffice} onChange={(e) => setCompanyTaxOffice(e.target.value.trimStart() )} placeholder={_("company_tax_office")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_tax_number")}</label>
                                                <input type="number"value={companyTaxNumber} onChange={(e) => setCompanyTaxNumber(e.target.value)} placeholder={_("company_tax_number")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_headquarters_address")}</label>
                                                <input type="text"value={headquartersAdress} onChange={(e) => setHeadquartersAdress(e.target.value.trimStart() )} placeholder={_("company_headquarters_address")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_phone_number")}</label>
                                                <input type="number"value={companyPhoneNumber} onChange={(e) => setCompanyPhoneNumber(e.target.value)} placeholder={_("company_phone_number")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_email_address")}</label>
                                                <input type="text"value={companyEmailAddress} onChange={(e) => setCompanyEmailAddress(e.target.value.trim() )} placeholder={_("company_email_address")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">
                                                {_("company_actividi_address")}
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text text-muted">actividi.com/</span>
                                                    <input 
                                                    type="text" 
                                                    value={companyActividiAddress} 
                                                    onChange={(e) => setCompanyActividiAddress(e.target.value.trimStart())} 
                                                    placeholder={_("company_actividi_address")} 
                                                    className="form-control" 
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="form-control-label text-muted">
                                                {_("company_vidipass_address")}
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text text-muted">vidipass.com/</span>
                                                    <input 
                                                    type="text" 
                                                    value={companyVidipassAddress} 
                                                    onChange={(e) => setCompanyVidipassAddress(e.target.value.trimStart())} 
                                                    placeholder={_("company_vidipass_address")} 
                                                    className="form-control" 
                                                    />
                                                </div>
                                            </div>
                                          
                                </div>
                                <hr />

                            <div className="form-group">
                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("info_employer_company")}</h1>
                            </div>

                            <div className="gap-3">
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("name_surname_company_official")}</label>
                                                <input type="text"value={officialNameSurname} onChange={(e) => setOfficialNameSurname(e.target.value.trimStart() )} placeholder={_("name_surname_company_official")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_official_identification_number")}</label>
                                                <input type="number"value={officialIdentificationNumber} onChange={(e) => setOfficialIdentificationNumber(e.target.value)} placeholder={_("company_official_identification_number")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("company_official_corporate_title")}</label>
                                                <input type="text"value={officialCorporateTitle} onChange={(e) => setOfficialCorporateTitle(e.target.value.trimStart() )} placeholder={_("company_official_corporate_title")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">
                                                {_("company_official_actividi_address")}
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text text-muted">actividi.com/</span>
                                                    <input 
                                                    type="text" 
                                                    value={officialActividi} 
                                                    onChange={(e) => setOfficialActividi(e.target.value.trimStart())} 
                                                    placeholder={_("company_official_actividi_address")} 
                                                    className="form-control" 
                                                    />
                                                </div>
                                            </div>      
                                </div>
                                <hr />

                            <div className="form-group">
                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("info_authority_company")}</h1>
                            </div>

                            <div className="gap-3">
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("name_surname_approval_authority")}</label>
                                                <input type="text"value={approvalNameSurname} onChange={(e) => setApprovalNameSurname(e.target.value.trimStart() )} placeholder={_("name_surname_approval_authority")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("identification_approval_authority")}</label>
                                                <input type="number"value={approvalIdentification} onChange={(e) => setApprovalIdentification(e.target.value)} placeholder={_("identification_approval_authority")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("corporate_approval_authority")}</label>
                                                <input type="text"value={corporateApprovalAuthority} onChange={(e) => setCorporateApprovalAuthority(e.target.value.trimStart() )} placeholder={_("corporate_approval_authority")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("email_approval_authority")}</label>
                                                <input type="text"value={approvalEmail} onChange={(e) => setApprovalEmail(e.target.value.trim() )} placeholder={_("email_approval_authority")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("phone_approval_authority")}</label>
                                                <input type="number"value={approvalPhone} onChange={(e) => setApprovalPhone(e.target.value)} placeholder={_("phone_approval_authority")} className="form-control" />
                                            </div>


                                            <div className="form-group">
                                                <label className="form-control-label text-muted">
                                                {_("actividi_approval_authority")}
                                                </label>
                                                <div className="input-group">
                                                    <span className="input-group-text text-muted">actividi.com/</span>
                                                    <input 
                                                    type="text" 
                                                    value={approvalActividi} 
                                                    onChange={(e) => setApprovalActividi(e.target.value.trimStart())} 
                                                    placeholder={_("actividi_approval_authority")} 
                                                    className="form-control" 
                                                    />
                                                </div>
                                            </div>      
                                </div>

                                <hr />
                                
                                <div className="form-group">
                                <h1 style={{fontSize:15}} className="panel-title homeText">{_("bank_info_owner_company")}</h1>
                            </div>

                            <div className="gap-3">
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("title_first_bank")}</label>
                                                <input type="text"value={firstBank} onChange={(e) => setFirstBank(e.target.value.trimStart() )} placeholder={_("title_first_bank")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("branch")}</label>
                                                <input type="text"value={firstBranch} onChange={(e) => setFirstBranch(e.target.value.trimStart() )} placeholder={_("branch")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("account_number")}</label>
                                                <input type="number"value={firstAccountNumber} onChange={(e) => setFirstAccountNumber(e.target.value)} placeholder={_("account_number")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("iban")}</label>
                                                <input type="number"value={firstIban} onChange={(e) => setFirstIban(e.target.value)} placeholder={_("iban")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("title_second_bank")}</label>
                                                <input type="text"value={secondBank} onChange={(e) => setSecondBank(e.target.value.trimStart() )} placeholder={_("title_second_bank")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("branch")}</label>
                                                <input type="text"value={secondBranch} onChange={(e) => setSecondBranch(e.target.value.trimStart() )} placeholder={_("branch")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("account_number")}</label>
                                                <input type="number"value={secondAccount} onChange={(e) => setSecondAccount(e.target.value)} placeholder={_("account_number")} className="form-control" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("iban")}</label>
                                                <input type="number"value={secondIban} onChange={(e) => setSecondIban(e.target.value)} placeholder={_("iban")} className="form-control" />
                                            </div>    
                                </div>

    
                                            <div className="form-check mb-2">
                                                <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                    {/* <input  type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} /> */}
                                                    {_("terms_of_use_01")} <a href={globals.url + "i/legal"} target="_blank" style={{ color: "#933CAC", textDecoration: "none" }}>{_("terms_of_use_02")}</a>{_("terms_of_use_03")}
                                                </label>
                                            </div>

                                            <ReCAPTCHA
                                                sitekey="6LfwQKwmAAAAABGb8zXTCPFFzB3MxWqDCPVd_-Lr"
                                                onChange={onChange}
                                                className="d-flex justify-content-center"
                                            />

                                            <div onClick={()=>('')} className="row justify-content-center my-3 px-3">
                                                <button disabled={!(verify && companyTradeName && companyTaxOffice && companyTaxNumber && headquartersAdress && companyPhoneNumber && companyEmailAddress && companyActividiAddress && companyVidipassAddress && officialNameSurname && officialIdentificationNumber && officialCorporateTitle && officialActividi && approvalNameSurname && approvalIdentification && corporateApprovalAuthority &&  approvalEmail && approvalPhone && approvalActividi && firstBank && firstBranch && firstAccountNumber && firstIban && secondBank && secondBranch && secondAccount && secondIban) || (!isIndividualEnterprise && (!mersis && !commercialRegistry))} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("send")}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
                    <span ref={divRef} />
                </>
            <Footer />
        </div>
    )
}

export default ContactForm