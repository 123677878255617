import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { useState, useEffect, useRef } from "react";
import { _ } from '../config/languages/i18n';
import { useDispatch } from "react-redux";
import { ChangePasswordAction } from "../redux/actions/auth";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import globals from '../config/globals/globals';
import axios from "axios";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState([]);
    const [oldpassword, setoldpassword] = useState("");
    const [newpassword, setnewpassword] = useState("");
    const [newpasswordagain, setnewpasswordagain] = useState("");
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(()=>{
        setDevice(navigator.userAgent)
    },[])

    useEffect(()=>{
        setUtc(new Date().getTimezoneOffset()/-60)
    },[])

    useEffect(()=>{
        HTTP.get('https://api.ipify.org?format=json')
        .then(
            res=>{
                setIp(res.data.ip)
            }
        )
    },[])

    useEffect(() => {
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem('auth'));
        if (getuser) {
            setUser(getuser.user);
        } else {
            window.location = '/i/start';
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])
    const [passwordShown, setPasswordShown] = useState("password");
    const toggle = () => {
        if (passwordShown === "password") {
            setPasswordShown("text");
            return;
        }
        setPasswordShown("password");
    };

    const [newPasswordShown, setNewPasswordShown] = useState("password");
    const toggleNew = () => {
        if (newPasswordShown === "password") {
            setNewPasswordShown("text");
            return;
        }
        setNewPasswordShown("password");
    };
    const [againPasswordShown, setAgainPasswordShown] = useState("password");
    const toggleAgain = () => {
        if (againPasswordShown === "password") {
            setAgainPasswordShown("text");
            return;
        }
        setAgainPasswordShown("password");
    };

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Change Password Page'
            },
            event: 'change_password_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div >
             <Helmet>
                <title>Vidipass | {_("change_password")}</title>
                <meta name="description" content={_("meta_content_02")} />
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
            <div className="card-body d-flex row text-center mb-3">
                            <h1 className="title fs-5 mt-1">{_('password')}</h1>
                        </div>
                <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                <div className="card card1 col">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-10">
                            <div className="row justify-content-center mb-3">
                                <img width={25} height={'auto'} className="w-25 mb-2" src={images.set_password_icon} alt="sifre degisikligi" />
                                <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("change_password")}</h1>
                            </div>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                if (oldpassword.length < 8) {
                                    toast(_('password_must_be_at_least_eight_characters'), {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                        closeButton: false,
                                        type: "error"
                                    })
                                }
                                else if (newpassword.length < 8) {
                                    toast(_('password_must_be_at_least_eight_characters'), {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                        closeButton: false,
                                        type: "error"
                                    })
                                }
                                else if (newpasswordagain.length < 8) {
                                    toast(_('password_must_be_at_least_eight_characters'), {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                        closeButton: false,
                                        type: "error"
                                    })
                                }
                                else if (!(newpassword == newpasswordagain)) {
                                    toast(_('password_and_password_confirmation_must_be_same'), {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                        closeButton: false,
                                        type: "error"
                                    })
                                } else {
                                    setoldpassword("");
                                    setnewpassword("");
                                    setnewpasswordagain("");
                                    dispatch(ChangePasswordAction({ currentPassword: oldpassword, newPassword: newpassword, newPasswordAgain: newpasswordagain, ip_address: ip, device_name: device, utc: utc }));
                                }

                            }}>
                                <div className="form-group">
                                    <label className="form-control-label text-muted">{_("current_password")}</label>
                                    <div className="input-group">
                                        <input type={passwordShown} value={oldpassword} onChange={(e) => setoldpassword(e.target.value)} placeholder={_("current_password")} className="form-control" />
                                        <i className=" btn btn-secondary text-center btn2-color" onClick={toggle}>
                                            {passwordShown === "password" ? (
                                                <svg
                                                    width="20"
                                                    height="17"
                                                    fill="currentColor"
                                                    className="bi bi-eye-slash-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="20"
                                                    height="17"
                                                    fill="currentColor"
                                                    className="bi bi-eye-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                            )}
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label text-muted">{_("new_password")}</label>
                                    <div className="input-group">
                                        <input type={newPasswordShown} value={newpassword} onChange={(e) => setnewpassword(e.target.value)} placeholder={_("new_password")} className="form-control" />
                                        <i className=" btn btn-secondary text-center btn2-color" onClick={toggleNew}>
                                            {newPasswordShown === "password" ? (
                                                <svg
                                                    width="20"
                                                    height="17"
                                                    fill="currentColor"
                                                    className="bi bi-eye-slash-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="20"
                                                    height="17"
                                                    fill="currentColor"
                                                    className="bi bi-eye-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                            )}
                                        </i>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-control-label text-muted">{_("new_password_again")}</label>
                                    <div className="input-group">
                                        <input type={againPasswordShown} value={newpasswordagain} onChange={(e) => setnewpasswordagain(e.target.value)} placeholder={_("new_password_again")} className="form-control" />
                                        <i className=" btn btn-secondary text-center btn2-color" onClick={toggleAgain}>
                                            {againPasswordShown === "password" ? (
                                                <svg
                                                    width="20"
                                                    height="17"
                                                    fill="currentColor"
                                                    className="bi bi-eye-slash-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            ) : (
                                                <svg
                                                    width="20"
                                                    height="17"
                                                    fill="currentColor"
                                                    className="bi bi-eye-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg>
                                            )}
                                        </i>
                                    </div>
                                </div>
                                <div onClick={()=>gtmTag('ok_button_change_password')} className="row justify-content-center my-3 px-3">
                                    <button disabled={!(oldpassword && newpassword && newpasswordagain)} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("ok")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}

            </div>
            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default ChangePassword