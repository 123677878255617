import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import globals from '../config/globals/globals';
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import moment from "moment";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const Profile = () => {
    const [user, setUser] = useState([]);
    const divRef = useRef(null)
    const [birthDate, setBirthDate] = useState("")

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem('auth'));
        if (getuser) {
            setUser(getuser.user);
        }
    }, []);

    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()

    useEffect(() => {
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Profile Page'
            },
            event: 'profile_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("profile")}</title>
                <meta name="description" content={_("meta_content_09")}/>
                <meta name="robots" content="index, follow"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
            <div className="card-body d-flex row text-center mb-3">
                            <h1 className="title fs-5 mt-1">{_('profile')}</h1>
                        </div>
                <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                    <div className="card card1 col">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="row justify-content-center mt-3">
                                    <img style={{width:150}} className="mb-2" src={images.profile_icon} alt="profile" />
                                    <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("edit_profile")}</h1>
                                </div>
                                <div className="mb-3" >
                                    <ul className="list-group list-group-flush">
                                        <label className="form-control-label title">{_("username")}</label>
                                        <li className="list-group-item">{user.username}</li>
                                        <br />
                                        {user.birth_date &&
                                            <>
                                                <label className="form-control-label title">{_("birth_date")}</label>
                                                <li className="list-group-item">{moment(user.birth_date).format("DD.MM.YYYY")}</li>
                                                <br />
                                            </>
                                        }
                                        {user.gender != 0 &&
                                            <>
                                                <label className="form-control-label title">{_("gender")}</label>
                                                <li className="list-group-item">{user.gender == 1 ? _("man") : user.gender == 2 ? _("woman") : _("not_define_gender")}</li>
                                                <br />
                                            </>
                                        }
                                        {user.phone &&
                                            <>
                                                <label className="form-control-label title">{_("phone")}</label>
                                                <li className="list-group-item">{user.phone}</li>
                                                <br />
                                            </>
                                        }
                                        {user.email &&
                                            <>
                                                <label className="form-control-label title">{_("e_mail")}</label>
                                                <li className="list-group-item">{user.email}</li>
                                                <li className="list-group-item"></li>
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div className="row g-1 mb-2">
                                    <a onClick={()=>gtmTag('edit_profile_button')} className="text-decoration-none d-grid gap-2 col-md-11 mx-auto col-sm-11 d-flex justify-content-center" href={globals.url + "i/profileEdit"}>
                                        <button className="btns2" type="button">
                                            <div className="text-start ms-2">
                                                <i className="bi bi-person-check icons"> </i><span style={{color:"white"}} className="p-1 d-inline"> {_("edit_profile")}</span>
                                            </div>
                                        </button>
                                    </a>
                                    <a onClick={()=>gtmTag('change_password_button')} className="text-decoration-none d-grid gap-2 col-md-11 mx-auto col-sm-11 d-flex justify-content-center" href={globals.url + "i/changePassword"}>
                                        <button className="btns2" type="button">
                                            <div className="text-start ms-2" >
                                                <i className="bi bi-key icons"></i><span style={{color:"white"}} className="p-1 align-text-bottom"> {_("change_password")}</span>
                                            </div>
                                        </button>
                                    </a>
                                    <a onClick={()=>gtmTag('my_tickets_button')} className="text-decoration-none d-grid gap-2 col-md-11 mx-auto col-sm-11 d-flex justify-content-center" href={globals.url + "i/tickets"}>
                                        <button className="btns2" type="button">
                                            <div className="text-start ms-2">
                                                <i className="bi bi-ticket-perforated icons "></i><span style={{color:"white"}} className="p-1 align-text-bottom" > {_("my_tickets")}</span>
                                            </div>
                                        </button>
                                    </a>
                                    <a onClick={()=>gtmTag('records_button')} className="text-decoration-none d-grid gap-2 col-md-11 mx-auto col-sm-11 d-flex justify-content-center" href={globals.url + "i/records"}>
                                        <button className="btns2" type="button">
                                            <div className="text-start ms-2">
                                                <i className="bi bi-clipboard-check icons"></i><span style={{color:"white"}} className="p-1 align-text-bottom "> {_("records")}</span>
                                            </div>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default Profile