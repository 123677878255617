import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import { QRCode } from 'react-qrcode-logo';
import axios from "axios";
import globals from "../config/globals/globals";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import constant from '../config/constant';
import Modal from 'react-bootstrap/Modal';
import Loader from "../components/Loader";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const Tickets = ({ setUser }) => {
    var salePrice = 0;
    const [email, setEmail] = useState("")
    const [username, setusername] = useState("")
    const [password, setPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [token, setToken] = useState("");
    const [activity, setActivity] = useState("");
    const [ticketNameSurname, setTicketNameSurname] = useState("null");
    const [deleteTicketModal, setDeleteTicketModal] = useState(false);
    const [deleteTicketId, setDeleteTicketId] = useState("");
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState("")
    const [serverTime, setServerTime] = useState("")
    const [feeRef, setFeeRef] = useState(0)
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    let renderCount = false
    let renderCountArchive = false


    useEffect(()=>{
        setDevice(navigator.userAgent)
    },[])

    useEffect(()=>{
        setUtc(new Date().getTimezoneOffset()/-60)
    },[])

    useEffect(()=>{
        HTTP.get('https://api.ipify.org?format=json')
        .then(
            res=>{
                setIp(res.data.ip)
            }
        )
    },[])

    useEffect(() => {
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true);
        HTTP.get("/getUserWallet", { headers: { Authorization: `bearer ${getuser.token}` } })
            .then((res) => {
                if (res.data.result) {
                    setBalance(res.data.balance)
                    setServerTime(res.data.time)
                    setLoading(false)
                }
            }).catch(setLoading(true))
    }, [])

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true)
        if (getuser.result) {
            setTicketNameSurname(getuser.user.username)
            setToken(getuser.token);
        }
        HTTP.get("/getUserTickets", { headers: { Authorization: `bearer ${getuser.token}` }, })
            .then((res) => {

                setLoading(false)
                if (res.data.tickets) {
                    setActivity(res.data.tickets)
                }
            })
            .catch(true);
    }, []);

    const ticketRefundId = (e) => {
        setDeleteTicketId(e)
        setDeleteTicketModal(true)
    }

    // const ticketRefund = (val) => {
    //  const getuser = JSON.parse(localStorage.getItem("auth"));
    //  if (getuser.result) {
    //      setTicketNameSurname(getuser.user.username)
    //      setToken(getuser.token);
    //  }
    //  if (deleteTicketId) {
    //      setLoading(true)
    //      HTTP.post("/ticketRefund", { ticket_id: val, ip_address: ip, device_name: device, utc: utc }, { headers: { Authorization: `bearer ${getuser.token}` }, })
    //          .then((res) => {
    //              if (res.data.result) {
    //                  setLoading(false)
    //                  window.location.href = globals.url + "i/tickets"
    //              }
    //          })
    //          .catch(setLoading(true));
    //  }
    // }


    const ticketRefund = async (e) => {
        setLoading(true)
        
        const ticket_id = e;
        const getuser = JSON.parse(localStorage.getItem("auth"));
    
        if (deleteTicketId) {
          // İlk bileti bul
          const primaryTicket = activity.find((val) => val.id === ticket_id);
    
          // İlk biletin seat'inden related_id'yi al
          const relatedSeatId = primaryTicket?.seat?.related_id;
    
          // Related Seat ID ile ikinci bileti bul
          const secondaryTicket = activity.find(
            (val) => val?.seat?.id === relatedSeatId
          );
    
          const secondaryTicketId = secondaryTicket?.id;
    
          // İlk bileti sil
          try {
            await HTTP.post("/ticketRefund", { ticket_id, device, utc, ip }, { headers: { Authorization: `bearer ${getuser.token}` }, }).then((res)=>{
                if(res.data.result){
                    setLoading(false)
                    window.location.href = globals.url + "i/tickets"
                }
            }
                
            
            )
          } catch (error) {
            console.error(error);
          }
    
          // İkinci bileti sil
          if (secondaryTicketId) {
            try {
              await HTTP.post("/ticketRefund", { ticket_id:secondaryTicketId, device, utc, ip }, { headers: { Authorization: `bearer ${getuser.token}` }, }).
              then((res)=>{
                if(res.data.result){
                    setLoading(false)
                    window.location.href = globals.url + "i/tickets"
                }
            }
                
            
            )
            } catch (error) {
              console.error(
                error
              );
            }
          }
    
          
         
       
         
        }
      };

    useEffect(() => {
        const feeRefControl = localStorage.getItem("feeRef")
        if (feeRefControl) {
            setFeeRef(feeRefControl)
        }
    }, [])

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Tickets Page'
            },
            event: 'tickets_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("wallet")}</title>
                <meta name="description" content={_("meta_content_14")}/>
                <meta name="robots" content="index, follow"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5 mt-5">
                        <div className="card-body d-flex row text-center mb-3">
                            <h1 className="title fs-5 mt-1">{_('VidipassBank')}</h1>
                        </div>
                        <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                            <div className="card card1 col">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className={feeRef == 0 ? "nav-link active" : "nav-link"} id="faq_tab_1-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_1" type="button" role="tab" aria-controls="faq_tab_1" aria-selected="true">
                                                    <div className="d-flex flex-column lh-lg">
                                                        <i className="bx bxs-plane-alt"></i>
                                                        <span className="homeText">{_("actual_tickets")}</span>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="faq_tab_2-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_2" type="button" role="tab" aria-controls="faq_tab_2" aria-selected="false">
                                                    <div className="d-flex flex-column lh-lg">
                                                        <i className="bx bxs-shopping-bag"></i>
                                                        <span className="homeText">{_("archive_ticket")}</span>
                                                    </div>
                                                </button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className={feeRef == 1 ? "nav-link active" : "nav-link"} id="faq_tab_3-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_3" type="button" role="tab" aria-controls="faq_tab_3" aria-selected="false">
                                                    <div className="d-flex flex-column lh-lg">
                                                        <i className="bx bxs-shopping-bag"></i>
                                                        <span className="homeText">{_("balance")}</span>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>

                                        <div className="tab-content mt-2" id="myTabContent">
                                            <div className={feeRef == 0 ? "tab-pane fade active show" : "tab-pane fade"} id="faq_tab_1" role="tabpanel" aria-labelledby="faq_tab_1-tab">
                                                {
                                                    activity && activity.map((val) => {
                                                        if (val.activity?.is_online) { // isonline koy bak
                                                            salePrice = val.price * ((100 - constant.denizbankBin[0]) / 100)
                                                        } else {
                                                            salePrice = 0
                                                        }
                                                        return (
                                                            val.time > 0 && !val.is_cancelled && 
                                                        <div className={`${val.price === 0 ? 'card-back' : ''} row rounded-2 mb-1 m-1 p-1`}>
                                                            <div className="card">
                                                            
                                                                <span className="row mb-2">
                                                                    {val.inviting_id && 
                                                                    <>
                                                                    {val.inviting_name &&
                                                                    <small className="text-muted text-truncate" style={{color: '#933CAC'}}>{_('inviting_name')}: {val.inviting_name} </small> 
                                                                }
                                                                    {val.inviting_note &&
                                                                    <small className="text-muted text-truncate" style={{color: '#933CAC'}}>{_('invite_note')}: {val.inviting_note} </small>
                                                                    }
                                                                    </> 
                                                                    }
                                                                </span>
                                                                {renderCount = true}
                                                                <div className="row">
                                                                <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
                                                                    {
                                                                        val.ticket_number ?
                                                                            <div className="row">
                                                                                <div className="col-12 text-center" >
                                                                                    <img src={globals.apiUrl + '/tickets/qr/' + val?.ticket_number} className="rounded-1" width='50%' height='auto' alt="..." />
                                                                                </div>
                                                                                <div className="col-12 text-center" >
                                                                                    <small className="">{val?.ticket_number}</small>
                                                                                </div>
                                                                                {
                                                                                    val?.name_on_ticket ?
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{val?.name_on_ticket}</small>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{ticketNameSurname}</small>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="row">
                                                                                <div className="col-12 text-center" >
                                                                                    <img src={images.qr} className="rounded-1" width='50%' height='auto' alt="..." />
                                                                                </div>
                                                                                {
                                                                                    val?.name_on_ticket ?
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{val?.name_on_ticket}</small>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{ticketNameSurname}</small>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-8 d-flex text-center align-items-center">
                                                                    <div className="row">
                                                                        <div className="col-6 d-flex text-center align-items-center">
                                                                            <div className="col text-truncate">
                                                                                <span className="my-0 text-muted text-center text-truncate">{val?.activity?.title}</span>
                                                                                <div className="row">
                                                                                    <small className="text-muted text-truncate">{val?.activity?.hall.name}</small>
                                                                                    <small className="text-muted text-truncate">{moment(val?.activity?.start_time).format("DD.MM.YYYY")}</small>
                                                                                    <small className="text-muted text-truncate">{_(moment(val?.activity?.start_time).format("dddd").toLocaleLowerCase())}</small>
                                                                                    <small className="text-muted text-truncate">{moment(val?.activity?.start_time).format("HH:mm")}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 d-flex text-start align-items-center text-truncate">
                                                                            <div className="row text-truncate text-center">

                                                                                <small className="text-muted text-truncate">{_('category')}: {val.seat.group.name}</small>
                                                                                <small className="text-muted text-truncate">{_('type')}: {val.current_price.name}</small>
                                                                                {
                                                                                    val.price>0? 
                                                                                    <small className="text-muted text-truncate">{_('fee')}: {val.price} TRY </small>
                                                                                : 
                                                                                    

                                                                                    val.view_fee ?

                                                                                    <small className="text-muted text-truncate">{_('fee')}: {val.current_price.price} TRY </small>
                                                                                    : 
                                                                                    <small className="text-muted text-truncate">{_('invitation')}</small>
                                                                                    
                                                                                
                                                                                }
                                                                                
                                                                                {val.activity?.is_online ?
                                                                                    <small className="text-muted text-truncate">{_('sale_price')}: {(Math.round((salePrice + Number.EPSILON) * 100) / 100).toFixed(2)} TRY </small>
                                                                                    : ""
                                                                                }
                                                                                <small className="text-muted text-truncate">{val.current_price.status == 1 ? _('ticket') : _('armchair')}: {val.current_price.status == 1 ? _('numberless') : val.seat.name}</small>
                                                                                {val?.seat?.row?.row_label &&
                                                                                <small className="text-muted text-truncate">
                                                                                    {_("order")}: {val.seat.row.row_label}
                                                                                </small>
                                                                                

                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            val.is_insurance == 1 && moment(val.activity?.start_time).diff(moment(), 'seconds') > 86400 &&
                                                                            <div className="row">
                                                                                <div className="col text-end mt-3" onClick={(e) => ticketRefundId(val.id)}>
                                                                                    <a className="nav-link navLink" aria-current="page"><span style={{ backgroundColor: '#933CAC', color: '#FFFFFF', fontSize: 16 }} className="bi bi-x-octagon-fill rounded p-1 "><span style={{ marginLeft: 5, fontSize: 15 }}>{_("return")}</span></span></a>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                    
                                                        
                                                        )
                                                    })
                                                }
                                                {!renderCount &&
                                                    <div className="row d-flex justify-content-center py-5">
                                                        <p className="homeText text-center">{_("actual_ticket_none")}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="tab-pane fade" id="faq_tab_2" role="tabpanel" aria-labelledby="faq_tab_2-tab">
                                                {
                                                    activity && activity.map((val) => {
                                                        if (val.activity?.is_online) { // isonline koy bak
                                                            salePrice = val.price * ((100 - constant.denizbankBin[0]) / 100)
                                                        } else {
                                                            salePrice = 0
                                                        }

                                                        return (
                                                            val.time < 0 &&

                                                            <div className={`${val.price === 0 ? 'card-back' : ''} row rounded-2 mb-1 m-1 p-1`}>
                                                            <div className="card">
                                                                <span className="row mb-2">
                                                                    {val.inviting_id && 
                                                                    <>
                                                                    {val.inviting_name &&
                                                                    <small className="text-muted text-truncate" style={{color: '#933CAC'}}>{_('inviting_name')}: {val.inviting_name} </small> 
                                                                }
                                                                    {val.inviting_note &&
                                                                    <small className="text-muted text-truncate" style={{color: '#933CAC'}}>{_('invite_note')}: {val.inviting_note} </small>
                                                                    }
                                                                    </> 
                                                                    }
                                                                </span>
                                                                {renderCountArchive = true}
                                                                <div className="row">
                                                                <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
                                                                    {
                                                                        val.ticket_number ?
                                                                            <div className="row">
                                                                                <div className="col-12 text-center" >
                                                                                    <img src={globals.apiUrl + '/tickets/qr/' + val.ticket_number} className="rounded-1" width='50%' height='auto' alt="..." />
                                                                                </div>

                                                                                <div className="col-12 text-center" >
                                                                                    <small className="">{val.ticket_number}</small>
                                                                                </div>
                                                                                {
                                                                                    val.name_on_ticket ?
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{val.name_on_ticket}</small>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{ticketNameSurname}</small>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <div className="row">
                                                                                <div className="col-12 text-center" >
                                                                                    <img src={images.qr} className="rounded-1" width='50%' height='auto' alt="..." />
                                                                                </div>
                                                                                {
                                                                                    val.name_on_ticket ?
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{val.name_on_ticket}</small>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="col-12 text-center" >
                                                                                            <small className="">{ticketNameSurname}</small>
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </div>

                                                                <div className="col-sm-12 col-md-8 d-flex text-center align-items-center">
                                                                    <div className="row">
                                                                        <div className="col-6 d-flex text-center align-items-center">
                                                                            <div className="col text-truncate">
                                                                                <span className="my-0 text-muted text-center text-truncate">{val.activity?.title}</span>
                                                                                <div className="row">
                                                                                    <small className="text-muted text-truncate">{val.activity?.hall.name}</small>
                                                                                    <small className="text-muted text-truncate">{moment(val.activity?.start_time).format("DD.MM.YYYY")}</small>
                                                                                    <small className="text-muted text-truncate">{_(moment(val.activity?.start_time).format("dddd").toLocaleLowerCase())}</small>
                                                                                    <small className="text-muted text-truncate">{moment(val.activity?.start_time).format("HH:mm")}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 d-flex text-start align-items-center text-truncate">
                                                                            <div className="row text-truncate text-center">
                                                                                <small className="text-muted text-truncate">{_('category')}: {val.seat.group.name}</small>
                                                                                <small className="text-muted text-truncate">{_('type')}: {val.current_price.name}</small>
                                                                                        {
                                                                                    val.price>0? 
                                                                                    <small className="text-muted text-truncate">{_('fee')}: {val.price} TRY </small>
                                                                                : 
                                                                                    

                                                                                    val.view_fee ?
                                                                                    <small className="text-muted text-truncate">{_('fee')}: {val.current_price.price} TRY </small>
                                                                                
                                                                                    : 
                                                                                    
                                                                                    <small className="text-muted text-truncate">{_('invitation')}</small>
                                                                                }
                                                                                {val.activity?.is_online ?
                                                                                    <small className="text-muted text-truncate">{_('sale_price')}: {(Math.round((salePrice + Number.EPSILON) * 100) / 100).toFixed(2)} TRY </small>
                                                                                    : ""
                                                                                }
                                                                                <small className="text-muted text-truncate">{val.current_price.status == 1 ? _('ticket') : _('armchair')}: {val.current_price.status == 1 ? _('numberless') : val.seat.name}</small>
                                                                                {val?.seat?.row?.row_label &&
                                                                                <small className="text-muted text-truncate">
                                                                                    {_("order")}: {val.seat.row.row_label}
                                                                                </small>
                                                                                

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                        )
                                                    })
                                                }
                                                {!renderCountArchive &&
                                                    <div className="row d-flex justify-content-center mt-5">
                                                        <p className="homeText text-center">{_("archive_ticket_none")}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className={feeRef == 1 ? "tab-pane fade active show" : "tab-pane fade"} id="faq_tab_3" role="tabpanel" aria-labelledby="faq_tab_3-tab">
                                                {
                                                    balance > 0 ?
                                                        <div className="row d-flex justify-content-center mt-5">
                                                            <p className="homeText text-center">{_('wallet')}: {balance} TRY</p>
                                                        </div>
                                                        :
                                                        <div className="row d-flex justify-content-center mt-5">
                                                            <p className="homeText text-center">{_("vidipassbank_fee_none")}</p>
                                                        </div>
                                                }

                                                <div  onClick={()=>gtmTag('add_fee_button')} className="crow d-flex justify-content-center mb-5">
                                                    <a href={globals.url + "i/addFee"} className="nav-link navLink" aria-current="page"><span style={{ backgroundColor: '#933CAC', color: '#FFFFFF', fontSize: 20 }} className=" bi bi-wallet-fill rounded p-2 "><span style={{ marginLeft: 5, fontSize: 15 }}>{_("add_fee")}</span></span></a>
                                                </div>
                                            </div>
                                        </div>                  

                                        {/* <div className="row">                                               
                                                    <div className="col text-end mt-3" onClick = {() => toPDF()}>
                                                        <a className="nav-link navLink" aria-current="page"><span style={{backgroundColor:'#933CAC', color:'#FFFFFF',fontSize:16}} className="bi bi-cloud-arrow-down-fill rounded p-1 "></span></a>
                                                    </div>
                                                </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}
                        </div>
                    </div>
                    <Modal show={deleteTicketModal} onHide={() => setDeleteTicketModal(false)}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                            <Modal.Title
                                style={{ fontSize: 16 }}
                            ><span className="homeText">{_("cancel_ticket")}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <h6 className="homeText">{_("sure")}</h6>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <div className="row">
                                    <div className="col text-end" onClick={() => ticketRefund(deleteTicketId) + setDeleteTicketModal(false)}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-check-circle-fill"></i>
                                        </a>
                                    </div>
                                    <div className="col text-end" onClick={() => setDeleteTicketModal(false)}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-x-circle-fill"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            <Footer />
        </div>
    )
}

export default Tickets