import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import "react-phone-number-input/style.css";
import { useState, useEffect, useRef, useCallback } from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Modal from "react-bootstrap/Modal";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ShowMoreText from "react-show-more-text";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import Linkify from "linkify-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import Multiselect from "multiselect-react-dropdown";
import * as Popover from "@radix-ui/react-popover";

const SeatOperations = () => {
  const timestamp = new Date();
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  const [activityName, setActivityName] = useState("");
  const [activityCategoryName, setActivityCategoryName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [description, setDescription] = useState("");
  const [number, setNumber] = useState();
  const [category, setCategory] = useState("null");
  const [seatCategory, setSeatCategory] = useState("null");
  const [activityId, setActivityId] = useState("");
  const [activityPhoto, setActivityPhoto] = useState("");
  const [token, setToken] = useState("");
  const [hall, setHall] = useState("");
  const [seat, setSeat] = useState([]);
  const [seatName, setSeatName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [seatId, setSeatId] = useState("");
  const [price, setPrice] = useState("");
  const [ticket, setTicket] = useState("");
  const [priceCode, setPriceCode] = useState("");
  const [utc, setUTC] = useState("");
  const [counter, setCounter] = useState("");
  const HTTP = axios.create({ baseURL: globals.apiUrl });
  const [showSaloonModal, setShowSaloonModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [deleteCartModal, setDeleteCartModal] = useState(false);
  const [deleteTicketModal, setDeleteTicketModal] = useState(false);
  const [deleteTicketId, setDeleteTicketId] = useState(false);
  const [imgCreated, setImgCreated] = useState("");
  const [planURL, setPlanURL] = useState("");
  const [extra, setExtra] = useState("");
  const [password, setPassword] = useState("");
  const [place, setPlace] = useState("");
  const [ticketNameSurname, setTicketNameSurname] = useState("null");
  const divRef = useRef(null);
  const [term, setTerm] = useState(false);
  const [emailTerm, setEmailTerm] = useState(false);
  const [insurance, setInsurance] = useState(2);
  const [cancelInsuranceModal, setCancelInsuranceModal] = useState(false);
  const [priceStatus, setPriceStatus] = useState("");
  const [ticketNumber, setTicketNumber] = useState(0);
  const [ticketNumberDec, setTicketNumberDec] = useState(0);
  const [serverTime, setServerTime] = useState("");
  const [urlModal, setUrlModal] = useState(false);
  const [copy, setCopy] = useState(false);
  const [startDateDay, setStartDateDay] = useState("");
  const [endDateDay, setEndDateDay] = useState("");
  const [mySeats, setMySeats] = useState([]);
  const [activityStatus, setActivityStatus] = useState("");
  const [addStatus, setAddStatus] = useState(0);
  const [cityName, setCityName] = useState("");
  const [activatedMail, setActivatedMail] = useState("");
  const [extraMail, setExtraMail] = useState("");
  const [activatedModal, setActivatedModal] = useState(false);
  const [codeStart, setCodeStart] = useState("");
  const [codeEnd, setCodeEnd] = useState("");
  const [metaDescription, setMetaDescripton] = useState("");
  const [metaKeywords, setMetaKeywords] = useState([]);
  const [ip, setIp] = useState("");
  const [device, setDevice] = useState("");
  const [utcLog, setUtcLog] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [seatChart, setSeatChart] = useState([]);
  const [groupCenters, setGroupCenters] = useState({}); // Grupların merkezlerini saklar
  const [categoryStatus, setCategoryStatus] = useState("");
  const [prices, setPrices] = useState([]);
  const closeRef = useRef(null);
  const [planImage, setPlanImage] = useState("")
  const [updatedSeats, setUpdatedSeats] = useState([]);

  const [updatedSeatData, setUpdatedSeatData] = useState([]);
  const [originalSeats, setOriginalSeats] = useState([]); // Başlangıç durumunu saklayacak

  
  const [popoverOpened, setPopoverOpened] = useState(false);
const popoverContentRef = useRef(null);


  useEffect(() => {
    setDevice(navigator.userAgent);
  }, []);

  useEffect(() => {
    setUtcLog(new Date().getTimezoneOffset() / -60);
  }, []);

  useEffect(() => {
    HTTP.get("https://api.ipify.org?format=json").then((res) => {
      setIp(res.data.ip);
    });
  }, []);

  useEffect(() => {
    if (
      seat.length > 0 &&
      seatCategory !== "null" &&
      category !== "null" &&
      priceStatus == 1
    ) {
      setTicketNumber(0);
      setTicketNumberDec(0);
    } else {
      setTicketNumber(0);
      setTicketNumberDec(0);
    }
  }, [seat, seatCategory, mySeats, category]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser.result) {
      setToken(getuser.token);
      if (getuser.user.activated_email_extra) {
        setActivatedMail(getuser.user.email_extra);
      } else {
        setActivatedMail("");
      }
    }
    setLoading(true);
    setNumber(window.location.href.split("/")[5]);
    if (number) {
      HTTP.get(`/activities/get/${number}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setCityName(res.data.activity.city.name);
            setActivityName(res.data.activity.title);
            setStartDate(
              moment(res.data.activity.start_time).format("DD.MM.YYYY")
            );
            setStartTime(moment(res.data.activity.start_time).format("HH:mm"));
            setStartDateTime(res.data.activity.start_time);
            setEndDate(moment(res.data.activity.end_time).format("DD.MM.YYYY"));
            setEndTime(moment(res.data.activity.end_time).format("HH:mm"));
            setEndDateTime(res.data.activity.end_time);
            setDescription(res.data.activity.description);
            setMetaDescripton(res?.data?.activity?.meta_description);
            if (
              res.data.activity.meta_keywords !== null &&
              res.data.activity.meta_keywords !== ""
            ) {
              setMetaKeywords(JSON.parse(res.data.activity.meta_keywords));
            }
            setActivityCategoryName(res.data.activity.category.name);
            setActivityId(res.data.activity.id);
            setActivityPhoto(res.data.activity.photo_name);
            setPriceCode(res.data.activity.currency.tcmb_code);
            setToken();
            setHall(res.data.activity.hall);
            setSeatChart(res.data.activity.hall.row);
            setUTC(res.data.activity.utc);
            setImgCreated(res.data.activity.created_at);
            setPlanURL(res.data.activity.seats_plan);
            setPlanImage(res.data.activity.hall.plan_file_url)
            setExtra(res.data.activity.extra);
            setPlace(res.data.activity.place.title);
            setTicketNameSurname(getuser.user.username);
            setServerTime(res.data.time);
            setStartDateDay(
              moment(res.data.activity.start_time)
                .format("dddd")
                .toLocaleLowerCase()
            );
            setEndDateDay(
              moment(res.data.activity.end_time)
                .format("dddd")
                .toLocaleLowerCase()
            );
            setActivityStatus(res.data.activity.activity_status);
            setLoading(false);
           
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          } else {
            window.location.href = globals.url;
          }
        })
        .catch(setLoading(true));
    }
  }, [number]);

  const getGroupsByStatus = () => {
    const groups = {};

    seatChart.forEach((row, rowIndex) => {
      row.seat.forEach((seat, colIndex) => {
        const groupName = seat.group?.name || seat.status; // Eğer grubu varsa, grup ismi; yoksa status kullanacağız

        // Eğer grup daha önce tanımlanmadıysa, oluştur
        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        // Grup içindeki koltukları ekle
        groups[groupName].push({ rowIndex, colIndex });
      });
    });

    return groups;
  };


  const calculateGroupCenter = (group) => {
    if (!group || group.length === 0) return { center: null, positionType: 4 };

    const rows = group.map((seat) => seat.rowIndex);
    const cols = group.map((seat) => seat.colIndex);

    const minRow = Math.min(...rows);
    const maxRow = Math.max(...rows);
    const minCol = Math.min(...cols);
    const maxCol = Math.max(...cols);

    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;

    const closestSeat = group.reduce((closest, seat) => {
      const distance = Math.sqrt(
        Math.pow(seat.rowIndex - centerRow, 2) +
          Math.pow(seat.colIndex - centerCol, 2)
      );
      return closest === null || distance < closest.distance
        ? { seat, distance }
        : closest;
    }, null);

    const positionType =
      Number.isInteger(centerRow) && Number.isInteger(centerCol)
        ? 1
        : Number.isInteger(centerRow)
        ? 2
        : Number.isInteger(centerCol)
        ? 3
        : 4;

    return { center: closestSeat?.seat || null, positionType };
  };

  const calculateAllGroupCenters = () => {
    const groups = getGroupsByStatus(); // Tüm grupları al
    const newGroupCenters = {};

    Object.keys(groups).forEach((groupName) => {
      newGroupCenters[groupName] = calculateGroupCenter(groups[groupName]); // Her grup için merkezi hesapla
    });

    setGroupCenters(newGroupCenters); // Merkezi gruplarla birlikte güncelle
  };

  useEffect(() => {
    calculateAllGroupCenters();
  }, [seatChart]);


  const onChangeTerm = (value) => {
    //setTerm(!term);
    if (!term) {
      setTerm(!term);
      setInsurance(1);
    } else {
      //setInsurance(0)
      // modal ac
      setCancelInsuranceModal(true);
    }
  };

  const getSeat = (categoryId) => {
    
    const myArray = categoryId.split(",");
    const mySeat = myArray[0];
    const myGroup = myArray[1];
    const myPrice = myArray[2];
    const myStatus = myArray[3];
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setSeatCategory(categoryId);
    setSeatId(mySeat);
    setGroupId(myGroup);
    setPrice(myPrice);
    setPriceStatus(myStatus);
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0") {
      HTTP.get(`/activities/getUnsoldSeats/${myGroup}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setSeat(res.data.seats);
            setLoading(false);      
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  useEffect(() => {
    if(categoryStatus==="standing"){
      setTimeout(() => {
        if (prices?.length === 1) {
          const item = prices[0];
          const value = `${item.id},${item.group_id},${item.price},${item.status}`;
          setSeatCategory(value);
          getSeat(value);
        }
      }, 200);

    }
    
  }, [prices,seatCategory]);
  const saveSeat = (seat, related)=>{
    if (prices?.length === 1){
      const item = prices[0];
      const value = `${item.id},${item.group_id},${item.price},${item.status},${seat},${related && related}`;
      setSeatCategory(value);
    }
    
  }
  
  const getStanding = (categoryId,status) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0") {
      HTTP.get(`/activities/getUnsoldSeats/${categoryId}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setSeat(res.data.seats);
            setCategoryStatus(status);
            setCategory(categoryId);
            setLoading(false)
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  const getTicket = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    HTTP.get("/getCart", {
      headers: { Authorization: `bearer ${getuser.token}` },
    })
      .then((res) => {
        if (res.data.cart) {
          selectedCategory(category);
          setTicket(res.data.cart.tickets);
          localStorage.setItem(
            "basketsNumber",
            JSON.stringify(res.data.cart.tickets.length)
          );
          setCounter(res.data.cart.cartTime);
          setLoading(false);
      
          setTimeout(() => {
            window.scrollTo({
              behavior: "smooth",
              top: divRef.current.offsetTop,
            });
          }, 200);
        } else {
          setTicket("");
          localStorage.setItem("basketsNumber", JSON.stringify(0));
          setCounter("");
          setLoading(false);
         
        }
      })
      .catch(setLoading(true));
  };


  const ticketIncrease = () => {
    const currentNumber = parseInt(ticketNumber, 10) || 0; // Sayıya çevir, eğer NaN olursa 0 ata
   
  
      setTicketNumber(currentNumber + 1);
    
  };

  const ticketDecrease = () => {
    if (ticketNumber > 0) {
      const decrease = ticketNumber - 1;
      setTicketNumber(decrease);
    }
  };

  const ticketIncreaseDec = () => {
    const currentNumber = parseInt(ticketNumberDec, 10) || 0; // Sayıya çevir, eğer NaN olursa 0 ata
   
  if(seat.length>ticketNumberDec){
    setTicketNumberDec(currentNumber + 1);

  }
     
    
  };

  const ticketDecreaseDec = () => {
    if (ticketNumberDec > 0) {
      const decrease = ticketNumberDec - 1;
      setTicketNumberDec(decrease);
    }
  };

  const addTicketNumber = () => {
    setLoading(true)
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    for (let i = 0; i < ticketNumber; i++) {
      mySeats.push(seat[i].id);
    }
    setMySeats(mySeats);
    if (mySeats.length > 0) {
      HTTP.post(
        "/addTicketToCart",
        {
          name_on_ticket: ticketNameSurname,
          seat_id: mySeats,
          price: price,
          price_id: seatId,
          activity_id: activityId,
          is_insurance: insurance == 2 ? 0 : insurance,
          ip_address: ip,
          device_name: device,
          utc: utcLog,
        },
        { headers: { Authorization: `bearer ${getuser.token}` } }
      )
        .then((res) => {
          if (res.data.result) {
            setSeatCategory("null");
            selectedCategory(category);
            setMySeats([]);
            setSeatName("");
            setTerm(false);
            setInsurance(0);
            getTicket();
            setCounter(res.data.cart.cartTime);
            setTicketNameSurname(getuser.user.username);
            setLoading(false);
      
            setPriceStatus("");
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
            setAddStatus(1);
          }
        })
        .catch(setLoading(true));
    }
  };

  useEffect(
    () => {
      setTimeout(() => {
        if (!planURL){
          if (hall?.groups?.length < 2) {
            setCategory(0);
          }
          if (hall?.groups[0]?.prices?.length == 1) {
            const id = hall?.groups[0]?.prices[0]?.id;
            const group_id = hall?.groups[0]?.prices[0]?.group_id;
            const price = hall?.groups[0]?.prices[0]?.price;
            const status = hall?.groups[0]?.prices[0]?.status;
            getSeat(id + "," + group_id + "," + price + "," + status);
          }

        }
        
      }, 200);
    },
    [hall],
    divRef.current
  );

  const selectedCategory = (category) => {
    if (hall?.groups[category]?.prices?.length == 1) {
      const id = hall?.groups[category]?.prices[0]?.id;
      const group_id = hall?.groups[category]?.prices[0]?.group_id;
      const price = hall?.groups[category]?.prices[0]?.price;
      const status = hall?.groups[category]?.prices[0]?.status;
      getSeat(id + "," + group_id + "," + price + "," + status);
    }
  };

  const getSeatChart = (categoryId, status) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0" && status !== "standing") {
      HTTP.get(`/activities/getUnsoldSeatsChart/${categoryId}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setCategory(categoryId);
            setSeat(res.data.seats);
            setOriginalSeats(res.data.seats)
            setCategoryStatus(status);
            setLoading(false);
            
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    } else {
      setCategoryStatus(status);
      setCategory(categoryId);
      getStanding(categoryId,status);
    }
  };

  useEffect(() => {
    if (!category || !hall?.groups) return;

    let filteredPrices = [];

    // hall.groups bir nesne mi dizi mi kontrol et
    if (Array.isArray(hall.groups)) {
      // Dizi ise find kullan
      const group = hall.groups.find((group) => group.id === category);
      filteredPrices = group?.prices || [];
    } else {
      // Nesne ise doğrudan erişim kullan
      filteredPrices = hall?.groups?.[category]?.prices || [];
    }

    setPrices(filteredPrices);
  }, [category, hall]);


const handleAssignCategoryToRow = (rowIndex, isClose) => {
  setSeat(prevSeats => {
    const updatedSeats = [...prevSeats]; // Önceki state'in kopyasını al
    const row = updatedSeats[rowIndex].seat.map(seat => {
      if (typeof isClose === "boolean" && seat.basket === null && seat.is_solded !== 1 && seat.is_reserve !== 1) {
        return {
          ...seat,
          is_active: isClose ? 1 : 0,
          is_solded: isClose ? 0 : null
        };
      }
      return seat;
    });

    updatedSeats[rowIndex] = { ...updatedSeats[rowIndex], seat: row };
    return updatedSeats; // Yeni güncellenmiş array'i döndür
  });
};


const handleSingleSeatUpdate = (seat_id, isClose) => {
  setSeat(prevSeats => {
    let relatedId = null;

    // Önce seat_id ile eşleşen koltuğun related_id'sini bul
    prevSeats.forEach(row => {
      row.seat.forEach(seat => {
        if (seat.id === seat_id && seat.related_id) {
          relatedId = seat.related_id;
        }
      });
    });

    // Koltukları güncelle
    const updatedSeats = prevSeats.map(row => ({
      ...row,
      seat: row.seat.map(seat => 
        seat.id === seat_id || seat.related_id === seat_id || (relatedId && seat.id === relatedId)
          ? {
              ...seat,
              is_active: isClose ? 1 : 0,
              is_solded: isClose ? 0 : null
            }
          : seat
      )
    }));

    return updatedSeats;
  });
};
useEffect(() => {
  // Assuming seats and originalSeats are already populated
  const newUpdatedSeats = seat
    .flatMap(seatGroup => 
      seatGroup?.seat?.filter(seat => {
        const originalSeatGroup = originalSeats.find(osg => osg.id === seatGroup.id);
        if (!originalSeatGroup) return false;
        const originalSeat = originalSeatGroup.seat.find(os => os.id === seat.id);
        return originalSeat && seat.is_active !== originalSeat.is_active;
      })
      .map(seat => ({
        seat_id: seat.id,
        is_active: seat.is_active,
        is_solded: seat.is_solded
      }))
    );

  setUpdatedSeats(newUpdatedSeats);
}, [seat, originalSeats]);


const updateSeats = () => {
  const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true)
        formData.append("updateSeats", JSON.stringify(updatedSeats));
        HTTP.post("/updateSeats", formData, {
            headers: { Authorization: `bearer ${getuser.token}` },
          })
            .then((res) => {
              setLoading(false)
              
                     if (res.data.result) {
                       toast(_("successful"), {
                         position: "top-right",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: false,
                         pauseOnHover: false,
                         draggable: false,
                         progress: undefined,
                         theme: "light",
                         closeButton: false,
                       });
                     } else {
                       toast(res.data.message, {
                         position: "top-right",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: false,
                         pauseOnHover: false,
                         draggable: false,
                         progress: undefined,
                         theme: "light",
                         closeButton: false,
                         type: "error",
                       });
                     }
            })
            .catch(setLoading(true));
    };

    const updateSeatsUnseat = () => {
      const getuser = JSON.parse(localStorage.getItem("auth"));
            setLoading(true)
            formData.append("groupId", groupId);
            formData.append("ticketNumber", ticketNumber?ticketNumber:null);
            formData.append("ticketNumberDec", ticketNumberDec?ticketNumberDec:null);
            HTTP.post("/updateSeatsUnseat", formData, {
                headers: { Authorization: `bearer ${getuser.token}` },
              })
                .then((res) => {
                  setLoading(false)
                  
                         if (res.data.result) {
                           toast(_("successful"), {
                             position: "top-right",
                             autoClose: 3000,
                             hideProgressBar: false,
                             closeOnClick: false,
                             pauseOnHover: false,
                             draggable: false,
                             progress: undefined,
                             theme: "light",
                             closeButton: false,
                           });
                         } else {
                           toast(res.data.message, {
                             position: "top-right",
                             autoClose: 3000,
                             hideProgressBar: false,
                             closeOnClick: false,
                             pauseOnHover: false,
                             draggable: false,
                             progress: undefined,
                             theme: "light",
                             closeButton: false,
                             type: "error",
                           });
                         }
                })
                .catch(setLoading(true));
        };

  return (
    <div>
      {loading ? <Loader />
      :
      <>
        <Navbar />
        <div className="container mb-5 px-4 mt-5">
        <h3 className="homeText text-center">{_("seat_operations")}</h3>
          <div
            style={{ marginBottom: isExpanded ? "360px" : "120px" }}
            className="row flex-column flex-lg-row"
          >
            {serverTime > 0 &&
              activityStatus == 0 &&
              ticket.length < 20 &&
              (planURL ? (
              <>
                <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    {seatChart.length > 0 &&
                      (seat.length > 0 ? (
                        categoryStatus === "standing" ? (
                          <>
                           <span
                              onClick={() => window.location.reload()}
                              style={{ color: "#933CAC", cursor: "pointer" }}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <span className="bi bi-arrow-left-square-fill fs-2" />
                             
                            </span>
                            <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    <span className="homeText">{_("category")}</span>
                    <div className="col-sm-12 mb-3">
                      {hall?.groups?.map((item, i) => {
                        return (
                          <div key={i} className="form-check">
                            <input
                              type="radio"
                              className="priceInput form-check-input custom-radio"
                              id={`category-${i}`}
                              name="category"
                              value={i}
                              onChange={(e) => {
                                setCategory(e.target.value);
                                setMySeats([]);
                                setSeatName("");
                                setSeatCategory("null");
                                selectedCategory(e.target.value);
                                setTimeout(() => {
                                  window.scrollTo({
                                    behavior: "smooth",
                                    top: divRef.current.offsetTop,
                                  });
                                }, 200);
                              }}
                              checked={category == i}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`category-${i}`}
                            >
                              {item.name} 
                              { item.total_tickets===item.solded_tickets&&
                              <small style={{color:'#c7c3ca'}}>({_("sold_out")})</small>
                             

                              }
                             
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {category != "null" && (
                      <>
                        <span className="homeText">{_("ticket_type")}</span>
                        <div className="col-sm-12 mb-3">
                          {hall?.groups[category]?.prices?.map((item, i) => {
                            const value = `${item.id},${item.group_id},${item.price},${item.status}`;
                            return (
                              <div key={i} className="form-check">
                                <input
                                  type="radio"
                                  className="priceInput form-check-input custom-radio"
                                  id={`price-${i}`}
                                  name="seatCategory"
                                  value={value}
                                  onChange={(e) => getSeat(e.target.value)}
                                  checked={seatCategory === value}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`price-${i}`}
                                >
                                  {item.name} - {item.price} {priceCode}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                   {seat && seatCategory !== "null" && category !== "null" && (
									
											
										
													<>
                              {
                                seat.length>0 ? 
                                <>
                                {ticketNumberDec===0 &&
                                  <>
                                   <span className="homeText"> {_('increase_ticket')}</span>
                                <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
															{
																ticketNumber > 0 ?
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
																	:
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
															}
															<input onChange={(e) => setTicketNumber(e.target.value.replace(/\D/, ''))} value={ticketNumber} style={{ width: 100, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
															
																
																	<span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
																	
															
														</div>
                                  </>

                                }
                               
                            {ticketNumber === 0 &&
                              <>
                              <span className="homeText"> {_('decrease_ticket')}</span>
                              <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
                                {
                                  ticketNumberDec > 0 ?
                                    <span onClick={() => ticketDecreaseDec()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                                    :
                                    <span onClick={() => ticketDecreaseDec()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                                }
                                <input onChange={(e) => {
                                  const value = e.target.value.replace(/\D/, ''); // Sadece rakamları al
                                  setTicketNumberDec(value > seat.length ? seat.length : value);
                                }
                                  
                                  } value={ticketNumberDec} style={{ width: 100, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
                                
                                  
                                    <span onClick={() => ticketIncreaseDec()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
                                    
                                
                              </div>
                              </>

                            }
                            
                            </>
                            
														:
                            <>
                             <span className="homeText"> {_('increase_ticket')}</span>
                            <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
                            {
                              ticketNumber > 0 ?
                                <span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                                :
                                <span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                            }
                            <input onChange={(e) => setTicketNumber(e.target.value.replace(/\D/, ''))} value={ticketNumber} style={{ width: 100, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
                            
                              
                                <span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
                                
                            
                          </div>
                          </>
                              }
														
														
													
													
													</>
									)}
                    {seat &&
                      (ticketNumberDec>0 || ticketNumber > 0) &&
                      seatCategory !== "null" &&
                      category !== "null" &&
                     
                      <div className="row justify-content-center px-5 mb-3">
                            <a
                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-6 mt-3"
                                onClick={()=>updateSeatsUnseat()}
                            >
                                {_("save")}
                            </a>
                        </div>
                      }
                  </div>
                </div>
                           
                          </>
                        ) : (
                          <>
                           <span
                              onClick={() => window.location.reload()}
                              style={{ color: "#933CAC", cursor: "pointer" }}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <span className="bi bi-arrow-left-square-fill fs-2" />
                             
                            </span>
                            <div className="d-flex flex-row gap-2 align-items-start my-1 flex-wrap">
                              {/* İlk Satır: Boş Küçük Modern Çerçeve */}
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box normal"></div>
                                <span className="modern-text">
                                  {_("standart_seat")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box disable"></div>
                                <span className="modern-text">
                                  {_("disable_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box rounded-left"></div>
                                <div className="modern-box rounded-right"></div>
                                <span className="modern-text">
                                  {_("double_seat")}
                                </span>
                              </div>

                              {/* İkinci Satır: Mor Küçük Modern Çerçeve */}
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box selectable"></div>
                                <span className="modern-text">
                                  {_("selectable_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box close"></div>
                                <span className="modern-text">
                                  {_("closed_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box sold"></div>
                                <span className="modern-text">
                                  {_("full_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box myBasket"></div>
                                <span className="modern-text">
                                  {_("my_basket_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box otherBasket"></div>
                                <span className="modern-text">
                                  {_("other_basket_seat")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box reserved"></div>
                                <span className="modern-text">
                                  {_("reserved")}
                                </span>
                              </div>

                              {/* Üçüncü Satır: Yuvarlatılmış Çerçeveler */}
                            </div>
                            <div
                              id="seat-map"
                              className="seat-map border border-secondary-subtle p-2 rounded-2"
                            >
                              {seat.map((row, rowIndex) => (
                                <div key={rowIndex} className="seat-row">
                                  {row.row_label ? (
                                    
                                    <Popover.Root key={rowIndex}>
                                        <Popover.Trigger asChild>
                                        <div className="seat-row-label">
                                      <span
                                        style={{
                                          border: "3px solid #a5a7a9",
                                          boxShadow:
                                            "0 0 10px 0 rgba(100,100,100,1)",
                                        }}
                                        className="seat-label rounded"
                                      >
                                        {row.row_label}
                                      </span>
                                    </div>
                                        </Popover.Trigger>
                                        <Popover.Portal>
                                          <Popover.Content
                                            className="PopoverContent border border-primary-subtle"
                                            sideOffset={5}
                                          >
                                            
                                            <div className="select-container d-flex flex-column gap-2 mt-3">
                                            <span
                                                   
                                                   className="btn btn-sm rounded"
                                                   style={{
                                                     backgroundColor: `${category.color}`,
                                                     color: "#FFF",
                                                     border:
                                                       "3px solid #CDCDCDCD",
                                                     boxShadow:
                                                       "0 0 10px 0 rgba(100,100,100,1)",
                                                   }}
                                                 >
                                                   <div>
                                                     {category.categoryName}
                                                   </div>

                                                   <button
                                                     className="btn btn-sm rounded me-5"
                                                     style={{
                                                       
                                                      
                                                       border:
                                                         "3px solid #933cac",
                                                       boxShadow:
                                                         "0 0 10px 0 rgba(100,100,100,1)",
                                                     }}
                                                     onClick={() =>
                                                       handleAssignCategoryToRow(
                                                         rowIndex,
                                                         true
                                                       )
                                                     }
                                                   >
                                                     <span
                                                       style={{
                                                         color: "#933cac",
                                                       }}
                                                       className="fas fa-eye d-flex align-self-center justify-content-center"
                                                     />
                                                   </button>

                                                   <button
                                                     className="btn btn-sm rounded"
                                                     style={{
                                                       border:
                                                         "3px solid #CDCDCDCD",
                                                       boxShadow:
                                                         "0 0 10px 0 rgba(100,100,100,1)",
                                                     }}
                                                     onClick={() =>
                                                       handleAssignCategoryToRow(
                                                         rowIndex,
                                                         false
                                                       )
                                                     }
                                                   >
                                                     <span
                                                       style={{
                                                         color: "#CDCDCDCD",
                                                       }}
                                                       className="fas fa-eye-slash d-flex align-self-center justify-content-center"
                                                     />
                                                   </button>
                                                 </span>
                                            </div>
                                            {/* Tek bir cancel_selection butonu */}

                                            <Popover.Close className="bi bi-x-circle-fill PopoverClose" />
                                            <Popover.Arrow className="PopoverArrow" />
                                          </Popover.Content>
                                        </Popover.Portal>
                                      </Popover.Root>
                                  ) : (
                                    <div className="seat-row-label" />
                                  )}

                                  {row?.seat.map((seat, colIndex) => {
                                    const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                    const isEmpty = seat.status === "empty";
                                    const isClose = seat.is_active === 0;
                                    const isBasket = seat.basket === "yourBasket"
                                    const isReserved = seat.is_reserve === 1


                                    const isClickable =
                                      seat.is_solded !== 1 &&
                                      (seat.basket ===null) && !isReserved;

                                    const isSeat = seat.status === "seat";
                                    const isStanding =
                                      seat.status === "standing";
                                    return (
                                      <div
                                        style={{
                                          backgroundColor:
                                            isStage && seat.color,
                                        }}
                                      >
                                        <Popover.Root key={colIndex} onOpenChange={setPopoverOpened}>
                                          <Popover.Trigger asChild>
                                            <div
                                              className={`${isClickable ? "seat":"inactiveSeat"} ${
                                                isStage ? "stage-seat" : ""
                                              } ${
                                                seat.position_type === "left" &&
                                                "leftLoveSeat"
                                              } 
                                            ${
                                              seat.position_type === "right" &&
                                              "rightLoveSeat"
                                            } 
                                            ${
                                              seat.type === "disable" &&
                                              "disableSeat"
                                            }
                                            ${
                                              seat.type === "normal" &&
                                              "normalSeat"
                                            }
                                            ${
                                              isStanding &&
                                              isClose &&
                                              "closeSeat"
                                            }
                                            ${
                                              isStanding &&
                                              !isClose &&
                                              "normalSeat"
                                            }
                                            ${
                                              seat.is_solded === 0 &&
                                              "selectableSeatBack"
                                            }
                                            ${
                                              seat.is_solded === 1 &&
                                              "soldSeatBack"
                                            }

                                             ${
                                              (seat.is_active === 0 && isSeat) &&
                                              "closeSeatBack"
                                            }
                                            
                                            ${
                                              seat.basket === "yourBasket" &&
                                              "myBasketSeatBack"
                                            }
                                            ${
                                              seat.basket === "anotherBasket" &&
                                              "otherBasketSeatBack"
                                            }
                                            ${
                                              isReserved &&
                                              "reserveSeatBack"
                                            }
                                           
                                            `}
                                              style={{
                                                color: "#FFFFFF",
                                                letterSpacing: "1px",
                                                boxShadow: !isEmpty &&
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                          
                                              }}
                                              onClick={() =>
                                                (isClickable && !isBasket)&&
                                                setMySeats([]) + 
                                                setIsExpanded(false) +
                                                setSeatCategory("null")+
                                                setSeatName("")+
                                                setTerm(false)+
                                                setInsurance(0)+
                                                saveSeat(seat.id,seat.related_id && seat.related_id)
                                              } // Dummy onClick, asıl işlevi Popover kullanır
                                            >
                                              {seat.name && (
                                                <span
                                                  className={`seat-label d-flex justify-content-center`}
                                                >
                                                  {seat.name}
                                                </span>
                                              )}
                                            </div>
                                          </Popover.Trigger>

                                          {isClickable && (
                                            <Popover.Portal>
                                              <Popover.Content
                                                className="PopoverContent border border-primary-subtle"
                                                sideOffset={-20}
                                                style={{
                                                  position: "relative", // Popover.Content'in konumunu relative yapıyoruz ki close butonunu yerleştirebilelim
                                                }}
                                              >
                                               <div className="select-container d-flex flex-column gap-2 mt-3">
                                            <span
                                                   
                                                   className="btn btn-sm rounded"
                                                   style={{
                                                     backgroundColor: `${category.color}`,
                                                     color: "#FFF",
                                                     border:
                                                       "3px solid #CDCDCDCD",
                                                     boxShadow:
                                                       "0 0 10px 0 rgba(100,100,100,1)",
                                                   }}
                                                 >
                                                   <div>
                                                     {category.categoryName}
                                                   </div>

                                                   <button
                                                     className="btn btn-sm rounded me-5"
                                                     style={{
                                                       
                                                      
                                                       border:
                                                         "3px solid #933cac",
                                                       boxShadow:
                                                         "0 0 10px 0 rgba(100,100,100,1)",
                                                     }}
                                                     onClick={() =>
                                                       handleSingleSeatUpdate(
                                                         seat.id,
                                                         true
                                                       )
                                                     }
                                                   >
                                                     <span
                                                       style={{
                                                         color: "#933cac",
                                                       }}
                                                       className="fas fa-eye d-flex align-self-center justify-content-center"
                                                     />
                                                   </button>

                                                   <button
                                                     className="btn btn-sm rounded"
                                                     style={{
                                                       border:
                                                         "3px solid #CDCDCDCD",
                                                       boxShadow:
                                                         "0 0 10px 0 rgba(100,100,100,1)",
                                                     }}
                                                     onClick={() =>
                                                      handleSingleSeatUpdate(
                                                        seat.id,
                                                        false
                                                      )
                                                    }
                                                   >
                                                     <span
                                                       style={{
                                                         color: "#CDCDCDCD",
                                                       }}
                                                       className="fas fa-eye-slash d-flex align-self-center justify-content-center"
                                                     />
                                                   </button>
                                                 </span>
                                            </div>

                                                <Popover.Close
                                                  ref={closeRef}
                                                  className="bi bi-x-circle-fill PopoverClose"
                                                />
                                                <Popover.Arrow className="PopoverArrow" />
                                                <Modal
                                                  show={cancelInsuranceModal}
                                                  onHide={() =>
                                                    setCancelInsuranceModal(
                                                      false
                                                    )
                                                  }
                                                >
                                                  <Modal.Header
                                                    closeButton
                                                    style={{
                                                      background: "#fff",
                                                    }}
                                                  >
                                                    <Modal.Title
                                                      style={{ fontSize: 16 }}
                                                    >
                                                      <span className="homeText">
                                                        {_("cancel_insurance")}
                                                      </span>
                                                    </Modal.Title>
                                                  </Modal.Header>
                                                  <Modal.Body>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <p
                                                        style={{ fontSize: 14 }}
                                                        className="text_muted"
                                                      >
                                                        {_(
                                                          "cancel_insurance_text"
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <p className="homeText">
                                                        {_(
                                                          "cancel_insurance_sure"
                                                        )}
                                                      </p>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                      <div className="row">
                                                        <div
                                                          className="col text-end"
                                                          onClick={(e) =>
                                                            setTerm(!term) +
                                                            setInsurance(0) +
                                                            setCancelInsuranceModal(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            <i className="bi bi-check-circle-fill"></i>
                                                          </a>
                                                        </div>
                                                        <div
                                                          className="col text-end"
                                                          onClick={() =>
                                                            setTerm(term) +
                                                            setInsurance(1) +
                                                            setCancelInsuranceModal(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            <i className="bi bi-x-circle-fill"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Modal.Body>
                                                </Modal>
                                              </Popover.Content>
                                            </Popover.Portal>
                                          )}
                                        </Popover.Root>
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                            </div>

                           
                          </>
                        )
                      ) : (
                        <>
                        <span className="d-flex justify-content-center homeText my-2">
                          
                          {_("select_category")}
                        </span>
                        <div id="seat-map" className="seat-map-ticket">
                          {seatChart.map((row, rowIndex) => (
                            <div key={rowIndex} className="seat-row">
                              {row.seat.map((seat, colIndex) => {
                                const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                const isSeat = seat.status === "seat";
                                const isStanding = seat.status === "standing";
                                const isClickable =
                                  seat.status === "seat" ||
                                  seat.status === "standing";

                                // Center stage hesaplama
                                const groupCenter =
                                  groupCenters[seat.group?.name || seat.status];
                                const isGroupCenter =
                                  groupCenter &&
                                  groupCenter.center &&
                                  groupCenter.center.rowIndex === rowIndex &&
                                  groupCenter.center.colIndex === colIndex;
                                const positionType =
                                  groupCenter?.positionType || 4;
                                return (
                                  <div style={{ backgroundColor: seat.color }}>
                                    {isClickable ? (
                                      <div
                                        className={`chartSeat`}
                                        style={{
                                          backgroundColor: seat.color,
                                          border: "none",
                                          cursor: "default",
                                        }}
                                        onClick={() =>
                                          getSeatChart(
                                            seat.group_id,
                                            seat.status
                                          )
                                        }
                                      >
                                        {isGroupCenter &&
                                          (isSeat || isStanding) && (
                                            <div
                                              className="rounded"
                                              style={{
                                                position: "absolute",
                                                top:
                                                  positionType === 1
                                                    ? "50%"
                                                    : positionType === 2
                                                    ? "50%"
                                                    : positionType === 3
                                                    ? "100%"
                                                    : "100%",
                                                left:
                                                  positionType === 1
                                                    ? "50%"
                                                    : positionType === 2
                                                    ? "100%"
                                                    : positionType === 3
                                                    ? "50%"
                                                    : "100%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                                zIndex: 3,

                                                color: "#FFFFFF",
                                              }}
                                            >
                                              <span style={{ fontSize: 12 }}>
                                                {seat.group?.name}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      <div
                                        className={`chartSeat`}
                                        style={{
                                          cursor: "default",
                                          backgroundColor: seat.color,
                                        }}
                                      >
                                        {isStage && isGroupCenter && (
                                          <div
                                            className="rounded"
                                            style={{
                                              position: "absolute",
                                              top:
                                                positionType === 1
                                                  ? "50%"
                                                  : positionType === 2
                                                  ? "50%"
                                                  : positionType === 3
                                                  ? "100%"
                                                  : "100%",
                                              left:
                                                positionType === 1
                                                  ? "50%"
                                                  : positionType === 2
                                                  ? "100%"
                                                  : positionType === 3
                                                  ? "50%"
                                                  : "100%",
                                              transform:
                                                "translate(-50%, -50%)",
                                              zIndex: 3,

                                              color: "#FFFFFF",
                                            }}
                                          >
                                            <span style={{ fontSize: 12 }}>
                                              {_("stage")}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </div>
                        </>
                      ))}
                  </div>
                </div>
                {
                  seat.length>0 &&
                  <div className="row justify-content-center px-5 mb-3">
                            <a
                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-6 mt-3"
                                onClick={()=>updateSeats()}
                            >
                                {_("save")}
                            </a>
                        </div>
                }
                
              </>
              ) : (
                <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    <span className="homeText">{_("category")}</span>
                    <div className="col-sm-12 mb-3">
                      {hall?.groups?.map((item, i) => {
                        return (
                          <div key={i} className="form-check">
                            <input
                              type="radio"
                              className="priceInput form-check-input custom-radio"
                              id={`category-${i}`}
                              name="category"
                              value={i}
                              onChange={(e) => {
                                setCategory(e.target.value);
                                setMySeats([]);
                                setSeatName("");
                                setSeatCategory("null");
                                selectedCategory(e.target.value);
                                setTimeout(() => {
                                  window.scrollTo({
                                    behavior: "smooth",
                                    top: divRef.current.offsetTop,
                                  });
                                }, 200);
                              }}
                              checked={category == i}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`category-${i}`}
                            >
                              {item.name} 
                              { item.total_tickets===item.solded_tickets&&
                              <small style={{color:'#c7c3ca'}}>({_("sold_out")})</small>
                             

                              }
                             
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    {category != "null" && (
                      <>
                        <span className="homeText">{_("ticket_type")}</span>
                        <div className="col-sm-12 mb-3">
                          {hall?.groups[category]?.prices?.map((item, i) => {
                            const value = `${item.id},${item.group_id},${item.price},${item.status}`;
                            return (
                              <div key={i} className="form-check">
                                <input
                                  type="radio"
                                  className="priceInput form-check-input custom-radio"
                                  id={`price-${i}`}
                                  name="seatCategory"
                                  value={value}
                                  onChange={(e) => getSeat(e.target.value)}
                                  checked={seatCategory === value}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`price-${i}`}
                                >
                                  {item.name} - {item.price} {priceCode}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                   {seat && seatCategory !== "null" && category !== "null" && (
									
											
										
													<>
                              {
                                seat.length>0 ? 
                                <>
                                {ticketNumberDec===0 &&
                                  <>
                                   <span className="homeText"> {_('increase_ticket')}</span>
                                <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
															{
																ticketNumber > 0 ?
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
																	:
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
															}
															<input onChange={(e) => setTicketNumber(e.target.value.replace(/\D/, ''))} value={ticketNumber} style={{ width: 100, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
															
																
																	<span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
																	
															
														</div>
                                  </>

                                }
                               
                            {ticketNumber === 0 &&
                              <>
                              <span className="homeText"> {_('decrease_ticket')}</span>
                              <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
                                {
                                  ticketNumberDec > 0 ?
                                    <span onClick={() => ticketDecreaseDec()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                                    :
                                    <span onClick={() => ticketDecreaseDec()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                                }
                                <input onChange={(e) => {
                                  const value = e.target.value.replace(/\D/, ''); // Sadece rakamları al
                                  setTicketNumberDec(value > seat.length ? seat.length : value);
                                }
                                  
                                  } value={ticketNumberDec} style={{ width: 100, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
                                
                                  
                                    <span onClick={() => ticketIncreaseDec()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
                                    
                                
                              </div>
                              </>

                            }
                            
                            </>
                            
														:
                            <>
                             <span className="homeText"> {_('increase_ticket')}</span>
                            <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
                            {
                              ticketNumber > 0 ?
                                <span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                                :
                                <span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
                            }
                            <input onChange={(e) => setTicketNumber(e.target.value.replace(/\D/, ''))} value={ticketNumber} style={{ width: 100, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
                            
                              
                                <span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
                                
                            
                          </div>
                          </>
                              }
														
														
													
													
													</>
									)}
                    {seat &&
                      (ticketNumberDec>0 || ticketNumber > 0) &&
                      seatCategory !== "null" &&
                      category !== "null" &&
                     
                      <div className="row justify-content-center px-5 mb-3">
                            <a
                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-6 mt-3"
                                onClick={()=>updateSeatsUnseat()}
                            >
                                {_("save")}
                            </a>
                        </div>
                      }
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </>
      }
    </div>
  );
};

export default SeatOperations;
