import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { set } from "date-fns";
import globals from "../config/globals/globals";
import axios from "axios";
import moment from 'moment';
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet";
import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Loader from "../components/Loader";

const ContactMessages = ({ setUser }) => {
    const navigate = useNavigate();
    const [contactsInfo, setContactsInfo] = useState([]);
    const [sloganNumber, setSloganNumber] = useState("");
    const cookies = new Cookies();
    const divRef = useRef(null);
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [searchTerm, setSearchTerm] = useState('');
    const [flagModal, setFlagModal] = useState('')
    const [userNotifications, setUserNotifications] = useState([]);
    const [descriptions, setDescriptions] = useState({});
    const [loading, setLoading] = useState(false);


    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    };

   

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Contact Messages Page'
            },
            event: 'contact_messages_page'
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        HTTP.get('getContact').then(res => {
            if (res.data.result) {
                setLoading(false); 
                setContactsInfo(res.data.contact);
            }
        });
    }, []);   

    useEffect(() => {
        const lang = cookies.get("language");
        const sloganNum = (lang === 'tr') ? (Math.floor(Math.random() * 3) + 1) : (Math.floor(Math.random() * 3) + 4);
        setSloganNumber(sloganNum);
    }, []);

    useEffect(() => {
        if (divRef.current) {
            setTimeout(() => {
                window.scrollTo({
                    behavior: "smooth",
                    top: divRef.current.offsetTop
                });
            }, 200);
        }
    }, [divRef.current]);

    const toggleContactStatus = (id) => {
        HTTP.post('/toggleContactStatus', { id },{ headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
            .then((response) => {
                if (response.data.result) {
                    // Bildirim durumu başarıyla güncellendiğinde, kullanıcıya bildirimi yenile
                    setContactsInfo((prevNotifications) =>
                        prevNotifications.map((notif) =>
                            notif.id === id ? { ...notif, status: notif.status === 1 ? 0 : 1 } : notif
                        )
                    );
                }
            })
           
    };

    const handleDescriptionChange = (rowId, value) => {
        setDescriptions(prev => ({ ...prev, [rowId]: value }));
    };

    const saveDescription = (rowId) => {
        setLoading(true);
        const newDescription = descriptions[rowId] || "";
        HTTP.post('/updateContactDescription', { id: rowId, description: newDescription }, {
            headers: {
                Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}`
            }
        })
        .then((response) => {
            if (response.data.result) {
                setLoading(false);
                window.location.reload();
            }
        })
       
    };
    
    
    


    const columns = [
        {
            name: _('created_time'),
            selector: row => moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('type'),
            selector: row => getTypeText(row.type), 
            sortable: true,
            width: '150px',
            wrap: true
        },
        {
            name: _('name_surname'),
            selector: row => row.namesurname,
            sortable: true,
            width: '200px',
            wrap: true
        },
        {
            name: _('phone'),
            selector: row => row.phone,
            sortable: true,
            width: '180px',
            wrap: true
        },
        {
            name: _('e_mail'),
            selector: row => row.email,
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('subject'),
            selector: row => subject(row.subject_type),
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('subject_details'),
            selector: row => row.content,
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('message'),
            selector: row => (
                <ShowMoreText
                lines={3}
                more={_("continue")}
                less={_("close")}
            >
                {row.extra}                
            </ShowMoreText> 
           
            ),
            sortable: true,
            minWidth: '180px',
            wrap: true
        },
        {
            name: _('description'),
            selector: row => row.description,
            sortable: true,
            minWidth: '200px',
            wrap: true,
            cell: row => (
                <textarea
                    type="text"
                    value={descriptions[row.id] ?? row.description ?? ""} // Öncelikle state'teki değeri kullan
                    onChange={(e) => handleDescriptionChange(row.id, e.target.value)}
                    placeholder={_("enter_your_description")}
                    maxLength="300"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            saveDescription(row.id);  // Enter tuşuna basıldığında kaydet
                        }
                    }}
                />
            )
        },
        {
            name: _('sign'),
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <i
                    className={`bi bi-flag${row.status === 1 ? '-fill' : ''} cursor-pointer homeText`}
                    onClick={row.status===0? ()=>toggleContactStatus(row.id):() => setFlagModal(row.id)}
                ></i>
            ),
        },
    ];

    const customText = {
        rowsPerPageText: _("number_of_rows_per_page"),
        rangeSeparatorText: _("between"),
    };

    const getTypeText = (type) => {
        switch (type) {
            case 0: return _('contact');
            case 1: return _('artist');
            case 2: return _('manager');
            case 3: return _('organizer');
            case 4: return _('venue_owner');
            default: return '';
        }
    };

    const subject = (subjectType) => {
        switch (subjectType) {
            case 0: return _('ticket_loss');
            case 1: return _('ticket_refund');
            case 2: return _('activity_postponement');
            case 3: return _('activity_cancellation');
            case 4: return _('complaint_about_organizer');
            case 5: return _('suggestion');
            default: return _('other');
        }
    };
    return (
       
        <div>
            {
                loading ? <Loader />:
                <div>
                <Helmet>
                    <title>Vidipass | {_("contact")}</title>
                    <meta name="description" content={_("contact")} />
                    <meta name="robots" content="noindex" />
                    <link rel="alternate" href={window.location.href} hreflang="tr" />
                    <link rel="canonical" href={window.location.href} hreflang="tr" />
                </Helmet>
                <Navbar />
                <div className="container mb-5 mt-5">
                <div className="card-body row">
                                        <h1 className="text-center mb-4 title fs-5">{_('contact')}</h1>
                                    </div>
                    <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                        <div className="card card1 col">
                            <div className="row justify-content-center">
                                <div className="col-12 ">
                                    <div className="col-sm-12 col-md-4 mb-2 mx-2 mt-2">
                                        <input
                                            type="search"
                                            className="form-control bg-white headerInput text-muted"
                                            placeholder={_("search")}
                                            aria-label={_("search")}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}>
                                        </input>
                                    </div>
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            responsive
                                            pagination
                                            paginationPerPage={100}
                                            paginationComponentOptions={customText}
                                            noDataComponent={_('no_records')}
                                            paginationRowsPerPageOptions={[100, 500, 1000, 5000, 10000]}
                                            expandOnRowClicked
                                            data={contactsInfo.filter((item) => {
                                                const typeText = getTypeText(item.type).toLowerCase();

                                                if (searchTerm === "") {
                                                    return item;
                                                } else if (
                                                    moment(item.created_at).format('YYYY-MM-DD HH:mm:ss').toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    typeText.includes(searchTerm.toLowerCase()) ||
                                                    item.namesurname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    item.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    item.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                    item.extra?.toLowerCase().includes(searchTerm.toLowerCase())
                                                ) {
                                                    return item;
                                                }
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span ref={divRef} /> */}
                </div>
                <Footer />

                <Modal show={flagModal} onHide={() => setFlagModal('')}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title
                                    style={{ fontSize: 16 }} 
                                ><span className="homeText">{_("remove_sign")}</span></Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div className="col text-end" onClick={(e) => toggleContactStatus(flagModal) + setFlagModal('')}>
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div className="col text-end" onClick={() => setFlagModal('')}>
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
            </div>

            }
           
        </div>
    );
};

export default ContactMessages;