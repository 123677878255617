import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import "react-phone-number-input/style.css";
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import globals from "../config/globals/globals";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { getCities } from "../config/cities/cities";
import * as Popover from "@radix-ui/react-popover";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";

const AddActivity = () => {
  const [loading, setLoading] = useState(false);
  const HTTP = axios.create({ baseURL: globals.apiUrl });
  const HTTPNumber = axios.create({ baseURL: `https://actividi.com` });
  const [url, setUrl] = useState("");
  const [error, setError] = useState(false);
  const [activity, setActivity] = useState("");
  const formData = new FormData();
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const cities = getCities();
  const [city, setCity] = useState(0);
  const [urlModal, setUrlModal] = useState(false);

  const [activityFee, setActivityFee] = useState([]);
  const [availableSeats, setAvailableSeats] = useState([]);
  const [unavailableSeats, setUnavailableSeats] = useState([]);

  const [rows, setRows] = useState(5);
  const [columns, setColumns] = useState(5);
  const [categories, setCategories] = useState([]);
  const [categoriesUnseat, setCategoriesUnseat] = useState([]);
  const [seats, setSeats] = useState([]);
  const [rowLabels, setRowLabels] = useState(
    Array.from({ length: rows }, (_, i) => String.fromCharCode(65 + i))
  );
  const [hallName, setHallName] = useState("");
  const [hallPlans, setHallPlans] = useState([]);
  const [selectedHallPlan, setSelectedHallPlan] = useState(null);
  const [positionType, setPositionType] = useState(null);
  const [isPrice, SetIsPrice] = useState(false);
  const [organizers, setOrganizers] = useState([])
  const [responsibles, setResponsibles] = useState([])
  const [selectedOrganizers, setSelectedOrganizers] = useState([])
  const [selectedResponsibles, setSelectedResponsibles] = useState([])


  const getNumber = () => {
    setLoading(true);
    HTTPNumber.get(`/s/api/${url.split("/").pop()}`).then((res) => {
      if (res.data.result) {
        setLoading(false);
        if (typeof JSON.parse(res.data.activity.activity_fee) === "object") {
          checkActivity(res.data.activity);
          SetIsPrice(true);
        } else {
          SetIsPrice(false);
          toast(_("no_ticket_info"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false,
            type: "error",
          });
        }
      }
    });
  };

  const onSelectResponsibles = (selectedList, selectedItem) => {
		setSelectedResponsibles([...selectedResponsibles, selectedItem.id])
	}

	const onRemoveResponsibles = (selectedList, removedItem) => {
		const myRemove = selectedResponsibles.filter(function (item) {
			return item !== removedItem.id
		})
		setSelectedResponsibles(myRemove)
	}

  const onSelectOrganizers = (selectedList, selectedItem) => {
		setSelectedOrganizers([...selectedOrganizers, selectedItem.id])
	}

	const onRemoveOrganizers = (selectedList, removedItem) => {
		const myRemove = selectedOrganizers.filter(function (item) {
			return item !== removedItem.id
		})
		setSelectedOrganizers(myRemove)
	}

  const checkActivity = (val) => {
    setLoading(true);
    formData.append("extra", `https://actividi.com/a/${url.split("/").pop()}`);
    HTTP.post("checkActivity", formData, {
      headers: { Authorization: `bearer ${token}` },
    }).then((res) => {
      if (!res.data.result) {
        setLoading(false);
        setActivity("");
        setUrlModal(res.data.activity.number);
      } else {
        setLoading(false);
        setActivity(val);
        setActivityFee(JSON.parse(val.activity_fee));
      }
    });
  };

  useEffect(() => {
    setAvailableSeats(activityFee?.filter((item) => item.seatStatus));
    setUnavailableSeats(activityFee?.filter((item) => !item.seatStatus));
  }, [activity?.activity_fee]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser?.result) {
      setUserId(getuser.user.id);
      setToken(getuser.token);
    }
  }, []);

  const addActivity = () => {
    setLoading(true);
    formData.append("place_title", activity?.place?.title);
    formData.append("address", activity?.place?.address);
    formData.append("lat", activity?.place?.lat);
    formData.append("lon", activity?.place?.lon);
    formData.append("city_id", city);
    formData.append("created_by", selectedOrganizers.length>0?selectedOrganizers[0]:userId);
    formData.append("responsible_user_id", selectedResponsibles);
    
    formData.append("activity_title", activity.title);
    formData.append(
      "activity_photo",
      "https://actividi.com/api/" +
        "activity/photo/" +
        activity.id +
        "?" +
        new Date(activity.updated_at).getTime()
    );
    formData.append("description", activity.description);
    formData.append("category_id", activity.category_id);
    formData.append("online_url", "");
    formData.append(
      "start_time",
      moment(new Date(activity.start_time)).format("DD.MM.YYYY HH:mm")
    );
    formData.append(
      "end_time",
      moment(new Date(activity.end_time)).format("DD.MM.YYYY HH:mm")
    );
    formData.append("is_paid", 1);
    formData.append("is_online", 0);
    formData.append("currency_id", 24);
    formData.append("hashtags", "");
    formData.append("utc", "+3");
    formData.append("status", 1);
    formData.append("comment", "");
    formData.append("meta_description", "");
    formData.append("meta_keywords", "");
    formData.append("type", activity.type);
    formData.append("extra", url);
    formData.append("activity_status", 1);
    unavailableSeats.length > 0 &&
      formData.append("activity_fee", JSON.stringify(unavailableSeats));
    seats.length > 0 && formData.append("seat_plan", JSON.stringify(seats));

    HTTP.post("/activities/add", formData, {
      headers: { Authorization: `bearer ${token}` },
    }).then((res) => {
      if (res.data.result) {
        setLoading(false);
        window.location.href = globals.url + "a/" + res.data.activity.number;
      }
    });
  };

  useEffect(()=>{

    HTTP.get("/getUsersByType",{
      headers: { Authorization: `bearer ${token}` },
    }).then((res) => {
     if(res.data.result){
      setOrganizers(res.data.organizers)
      setResponsibles(res.data.responsibles)
     }
    });
  },[token])

  const handleInputChangeColor = (index, e) => {
    const newInputs = [...categories];
    newInputs[index].color = e.target.value;
    setCategories(newInputs);
  };
  const handleInputChangeColorUnseat = (index, e) => {
    const newInputs = [...categoriesUnseat];
    newInputs[index].color = e.target.value;
    setCategoriesUnseat(newInputs);
  };

  useEffect(() => {
    // `seats` içinde sadece `hall_seat`'leri güncelle
    const updatedSeats = seats.map((row) => {
      const updatedHallSeats = row.hall_seat.map((seat) => {
        // İlgili kategoriyle eşleşen koltuk bulunuyor
        const category = categories.find(
          (cat) => cat.categoryName === seat.category_name
        );

        // Eğer kategori varsa ve renginde değişiklik olduysa, sadece koltuk rengini güncelle
        if (category && seat.color !== category.color) {
          return { ...seat, color: category.color };
        }

        // Eğer değişiklik yoksa, koltuğu olduğu gibi bırak
        return seat;
      });

      // `hall_seat`'leri güncellediğimiz için bu satırdaki `hall_seat`'i yenisiyle değiştiriyoruz
      return { ...row, hall_seat: updatedHallSeats };
    });

    setSeats(updatedSeats);
  }, [categories]);

  useEffect(() => {
    // `seats` içinde sadece `hall_seat`'leri güncelle
    const updatedSeats = seats.map((row) => {
      const updatedHallSeats = row.hall_seat.map((seat) => {
        // İlgili kategoriyle eşleşen koltuk bulunuyor
        const category = categoriesUnseat.find(
          (cat) => cat.categoryName === seat.category_name
        );

        // Eğer kategori varsa ve renginde değişiklik olduysa, sadece koltuk rengini güncelle
        if (category && seat.color !== category.color) {
          return { ...seat, color: category.color };
        }

        // Eğer değişiklik yoksa, koltuğu olduğu gibi bırak
        return seat;
      });

      // `hall_seat`'leri güncellediğimiz için bu satırdaki `hall_seat`'i yenisiyle değiştiriyoruz
      return { ...row, hall_seat: updatedHallSeats };
    });

    setSeats(updatedSeats);
  }, [categoriesUnseat]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (getuser) {
      if (getuser.result) {
        if (getuser.token) {
          setToken(getuser.token);
        }
      }
    }
  }, []);

  useEffect(() => {
    const newCategories = [];
    availableSeats.forEach((seat) => {
      const { activityCategoryName, activityCategoryType } = seat;

      // Eğer kategori daha önce oluşturulmamışsa oluştur
      let category = newCategories.find(
        (cat) => cat.categoryName === activityCategoryName
      );

      if (!category) {
        category = {
          categoryName: activityCategoryName,
          color: "#000000", // Tüm kategorilere siyah renk ata
          priceTypes: [],
        };
        newCategories.push(category);
      }

      // Kategorinin altındaki her bir fiyat tipini ekle
      activityCategoryType.forEach((type) => {
        category.priceTypes.push({
          classId: type.classId,
          activityClass: type.activityClass,
          activityPrice: type.activityPrice,
        });
      });
    });

    setCategories(newCategories);
  }, [availableSeats]);
  useEffect(() => {
    const newCategories = [];
    unavailableSeats.forEach((seat) => {
      const { activityCategoryName, activityCategoryType } = seat;

      // Eğer kategori daha önce oluşturulmamışsa oluştur
      let category = newCategories.find(
        (cat) => cat.categoryName === activityCategoryName
      );

      if (!category) {
        category = {
          categoryName: activityCategoryName,
          color: "#000000", // Tüm kategorilere siyah renk ata
          priceTypes: [],
        };
        newCategories.push(category);
      }

      // Kategorinin altındaki her bir fiyat tipini ekle
      activityCategoryType.forEach((type) => {
        category.priceTypes.push({
          classId: type.classId,
          activityClass: type.activityClass,
          activityPrice: type.activityPrice,
        });
      });
    });

    setCategoriesUnseat(newCategories);
  }, [unavailableSeats]);

  // const handleAssignCategory = (rowIndex, colIndex, category, e) => {
  //   const updatedSeats = [...seats];
  //   const seat = updatedSeats[rowIndex].hall_seat[colIndex];
    
  //   // İlk atamada varsayılan kategoriyi saklayın
  //   if (!seat.default_category_name) {
  //     seat.default_category_name = seat.category_name || ""; // Başlangıçta mevcut kategori adı varsa kaydet
  //     seat.default_color = seat.color || ""; // Varsayılan rengi kaydet
  //     seat.default_price_types = seat.price_types || []; // Varsayılan fiyat tiplerini kaydet
  //     seat.default_is_active = seat.is_active || 0
  //   }
  
  //   if (category && category.categoryName) {
  //     if (e === 1) {
  //       seat.is_active = 0;
  //     } else {
  //       seat.is_active = 1;
  //     }
  //     seat.color = category.color;
  //     seat.category_name = category.categoryName; // Yeni kategori adı atanıyor
  //     seat.price_types = category.priceTypes;
  //   } else {
  //     seat.color = seat.default_color;
  //     seat.category_name = seat.default_category_name;
  //     seat.price_types = seat.default_price_types;
  //     seat.is_active = seat.default_is_active
  //   }
  
  //   updatedSeats[rowIndex].hall_seat[colIndex] = seat;
  //   setSeats(updatedSeats);
  // };
  
  const handleAssignCategory = (rowIndex, colIndex, category, e) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex].hall_seat[colIndex];
    
    // İlk atamada varsayılan kategoriyi saklayın
    if (!seat.default_category_name) {
      seat.default_category_name = seat.category_name || ""; // Başlangıçta mevcut kategori adı varsa kaydet
      seat.default_color = seat.color || ""; // Varsayılan rengi kaydet
      seat.default_price_types = seat.price_types || []; // Varsayılan fiyat tiplerini kaydet
      seat.default_is_active = seat.is_active || 0;
    }
  
    // Kategori bilgisi atanıyor
    const assignCategory = (targetSeat) => {
      if (category && category.categoryName) {
        if (e === 1) {
          targetSeat.is_active = 0;
        } else {
          targetSeat.is_active = 1;
        }
        targetSeat.color = category.color;
        targetSeat.category_name = category.categoryName; // Yeni kategori adı atanıyor
        targetSeat.price_types = category.priceTypes;
      } else {
        targetSeat.color = targetSeat.default_color;
        targetSeat.category_name = targetSeat.default_category_name;
        targetSeat.price_types = targetSeat.default_price_types;
        targetSeat.is_active = targetSeat.default_is_active;
      }
    };
  
    // İlgili koltuğun `related_number` değerini al
    const relatedNumber = seat.related_number;
  
    if (relatedNumber) {
      // Aynı `related_number` değerine sahip tüm koltukları bul ve işle
      updatedSeats.forEach((row) => {
        row.hall_seat.forEach((targetSeat) => {
          if (targetSeat.related_number === relatedNumber) {
            assignCategory(targetSeat);
          }
        });
      });
    } else {
      // Sadece seçilen koltuğu işle
      assignCategory(seat);
    }
  
    setSeats(updatedSeats);
  };
  

  const handleAssignCategoryStanding = (rowIndex, colIndex, category, index) => {
    const updatedSeats = [...seats];
    const seat = updatedSeats[rowIndex].hall_seat[colIndex];
  
    // İlk atamada varsayılan kategoriyi saklayın
    if (!seat.default_category_name) {
      seat.default_category_name = seat.category_name || ""; // Başlangıçtaki kategori adı
      seat.default_color = seat.color || ""; // Varsayılan renk
      seat.default_price_types = seat.price_types || []; // Varsayılan fiyat tipleri
      seat.default_is_active = seat.is_active || 0
    }
  
    if (category && category.categoryName) {
      seat.is_active = 1;
      seat.color = category.color;
      seat.category_name = category.categoryName; // Yeni kategori adı atanıyor
      seat.price_types = category.priceTypes;
    } else {
      seat.is_active = 0;
      // Kategori temizlendiğinde varsayılan kategori bilgilerini geri yükleyin
      seat.color = seat.default_color;
      seat.category_name = seat.default_category_name;
      seat.price_types = seat.default_price_types;
      seat.is_active = seat.default_is_active
    }
  
    updatedSeats[rowIndex].hall_seat[colIndex] = seat;
    setSeats(updatedSeats);
  };
  

  const handleAssignCategoryToRow = (
    rowIndex,
    category,
    isClose = false,
    isUnseat = false
  ) => {
    const updatedSeats = [...seats];
    const row = updatedSeats[rowIndex].hall_seat;
  
    row.forEach((seat, colIndex) => {
      // İlk atamada varsayılan kategoriyi saklayın
      if (!seat.default_category_name) {
        seat.default_category_name = seat.category_name || ""; // Varsayılan kategori adı
        seat.default_color = seat.color || (seat.status === "standing" ? "#EC008C" : "#933CAC"); // Varsayılan renk
        seat.default_price_types = seat.price_types || []; // Varsayılan fiyat tipleri
        seat.default_is_active = seat.is_active
      }
  
      if (isUnseat && !category) {
        // Unseat ve kategori temizleme durumu
        if (seat.status === "seat") {
          seat.color = seat.default_color; // Varsayılan renge geri dön
          seat.category_name = seat.default_category_name; // Varsayılan kategori adına geri dön
          seat.price_types = seat.default_price_types; // Varsayılan fiyat tiplerine geri dön
          seat.is_active = seat.default_is_active
        } else if (seat.status === "standing") {
          seat.color = seat.default_color; // Varsayılan renge geri dön
          seat.category_name = seat.default_category_name; // Varsayılan kategori adına geri dön
          seat.price_types = seat.default_price_types; // Varsayılan fiyat tiplerine geri dön
          seat.is_active = seat.default_is_active
        }
      } else if (isUnseat) {
        // Unseat Durumu: Sadece standing veya isStanding durumundakilere etki et
        if (seat.status === "standing") {
          if (seat.is_active === 0) {
            seat.is_active=1; // standing -> isStanding
          }
          seat.color = category.color || seat.default_color; // Yeni renk veya varsayılan renk
          seat.category_name = category.categoryName || seat.default_category_name; // Yeni kategori adı veya varsayılan ad
          seat.price_types = category.priceTypes || seat.default_price_types; // Yeni fiyat tipleri veya varsayılanlar
          seat.is_active = 1
        }
      } else if (category && category.categoryName) {
        // Kategori Atama: Sadece selected, isSeat, isClose durumundakilere etki et
        if (["seat"].includes(seat.status)) {
          seat.color = category.color;
          seat.category_name = category.categoryName;
          seat.price_types = category.priceTypes;
          seat.is_active=isClose ? 0:1;
        }
      }
  
      updatedSeats[rowIndex].hall_seat[colIndex] = seat;
    });
  
    setSeats(updatedSeats);
  };
  
  useEffect(() => {
    HTTP.get("/getHallPlan", {
      headers: { Authorization: `bearer ${token}` },
    }).then((res) => {
      setHallPlans(res.data.hallPlan);
    });
  }, [token, categories]);

  const handleSelectChange = (event) => {
    const selectedId = Number(event.target.value);
    const selectedHall = hallPlans.find((hall) => hall.id === selectedId);
    setSelectedHallPlan(selectedHall);
    setSeats(selectedHall.hall_row);
  };

  const getStageSeatsMatrix = () => {
    const stageSeats = [];
    seats.forEach((row, rowIndex) => {
      row.hall_seat.forEach((seat, colIndex) => {
        if (seat.status === "stage") {
          stageSeats.push({ rowIndex, colIndex });
        }
      });
    });
    return stageSeats;
  };
  const findCenterStageSeat = () => {
    const stageSeats = getStageSeatsMatrix();
    if (stageSeats.length === 0) return null;

    // En küçük ve en büyük satır/sütun indekslerini bul
    const minRow = Math.min(...stageSeats.map((seat) => seat.rowIndex));
    const maxRow = Math.max(...stageSeats.map((seat) => seat.rowIndex));
    const minCol = Math.min(...stageSeats.map((seat) => seat.colIndex));
    const maxCol = Math.max(...stageSeats.map((seat) => seat.colIndex));

    // Satır ve sütunlar arasında ortalama merkezi bul
    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;
    // En yakın sahne koltuğunu bul

    const closestSeat = stageSeats.reduce((closest, seat) => {
      const distance = Math.sqrt(
        Math.pow(seat.rowIndex - centerRow, 2) +
          Math.pow(seat.colIndex - centerCol, 2)
      );
      if (closest === null || distance < closest.distance) {
        return { seat, distance };
      }
      return closest;
    }, null);

    return closestSeat ? closestSeat.seat : null;
  };

  const centerStageSeat = findCenterStageSeat();
  useEffect(() => {
    const stageSeats = getStageSeatsMatrix();
    const minRow = Math.min(...stageSeats.map((seat) => seat.rowIndex));
    const maxRow = Math.max(...stageSeats.map((seat) => seat.rowIndex));
    const minCol = Math.min(...stageSeats.map((seat) => seat.colIndex));
    const maxCol = Math.max(...stageSeats.map((seat) => seat.colIndex));

    // Satır ve sütunlar arasında ortalama merkezi bul
    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;
    if (centerRow !== null && centerCol !== null) {
      if (Number.isInteger(centerRow) && Number.isInteger(centerCol)) {
        setPositionType(1);
      } else if (Number.isInteger(centerRow) && !Number.isInteger(centerCol)) {
        setPositionType(2);
      } else if (!Number.isInteger(centerRow) && Number.isInteger(centerCol)) {
        setPositionType(3);
      } else {
        setPositionType(4);
      }
    }
  }, [selectedHallPlan]); // Boş bağımlılık dizisi ile yalnızca bileşen montajında çalışır

  const validateUrl = (inputValue) => {
    // Kabul edilecek regex pattern'ı
    const pattern =
      /^(https?:\/\/actividi\.com\/a\/\d{4}-\d{3,}|https?:\/\/actividi\.com\/a\/\d{4}-\d{3,}|\d{4}-\d{3,})$/;
    return pattern.test(inputValue);
  };
  const handleChange = (e) => {
    const inputValue = e;
    setUrl(inputValue);
    if (validateUrl(inputValue)) {
      setError(false);
    } else {
      setError(true); // Eğer format uymuyorsa hata mesajı göster
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <>
        <Navbar />
        <div className="container mb-5 mt-5">
          <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
            <div className="card card1 order-2 me-1 mb-1 mt-5">
              <div className="row justify-content-center my-auto pb-3">
                <div className="col-md-8 col-10">
                  <div className="text-center mb-3 mt-3">
                    <h1 style={{ fontSize: 16 }} className="homeText">
                      {_("add_activity")}
                    </h1>
                  </div>
                  <div className="form-group">
                    <label className="form-control-label text-muted">
                      Actividi Url
                    </label>
                    <input
                      type="text"
                      value={url}
                      onChange={(e) => handleChange(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="row justify-content-center my-3 px-3">
                    <button
                      disabled={error || !url}
                      onClick={() => getNumber()}
                      className="text-decoration-none btn btn-primary cardButton col-6"
                    >
                      {_("get_activity_info")}
                    </button>
                  </div>
                  {activity && isPrice && (
                    <div className="form-group mb-2 mt-2">
                      <label className="form-control-label text-muted">
                        {_("select_city")}
                      </label>
                      <select
                        type="text"
                        className={"form-control bg-white headerInput"}
                        style={{ float: "left" }}
                        onChange={(e) => setCity(e.target.value)}
                        selected={city}
                        value={city}
                      >
                        <option value="0" key={0}>
                          {_("select_city")}
                        </option>
                        {cities.map((city, i) => {
                          return (
                            <option value={city.key} key={i + 1}>
                              {city.value[0] +
                                city.value.slice(1).toLowerCase()}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  {activity && city != 0 && isPrice && (
                    <>
                      <div className="form-group mt-3">
                        <label className="form-control-label text-muted">
                          {_("activity_title")}
                        </label>
                        <span className="form-control">{activity.title}</span>
                      </div>
                      <div className="justify-content-center mt-3">
                        <img
                          src={
                            "https://actividi.com/api/" +
                            "activity/photo/" +
                            activity.id +
                            "?" +
                            new Date(activity.updated_at).getTime()
                          }
                          className="rounded-2"
                          width="100%"
                          height="auto"
                          alt="activity photo"
                        />
                      </div>
                      <div className="form-group mb-2 mt-2">
                        <label className="form-control-label text-muted">
                          {_("place")}
                        </label>
                        <span className="form-control">
                          {activity.place.title}
                        </span>
                      </div>
                      <div className="row mt-2 text-center">
                        <div className="form-group col-6">
                          <div className="text-center">
                            <label className="form-control-label text-muted">
                              {_("start_time")}
                            </label>
                          </div>
                          <div>
                            <DatePicker
                              disabled
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={5}
                              dateFormat="dd.MM.yyyy / HH:mm"
                              className="form-control text-start"
                              selected={new Date(activity.start_time)}
                            />
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <div className="text-center">
                            <label className="form-control-label text-muted">
                              {_("end_time")}
                            </label>
                          </div>
                          <div>
                            <DatePicker
                              disabled
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={5}
                              dateFormat="dd.MM.yyyy / HH:mm"
                              className="form-control  text-start"
                              selected={new Date(activity.end_time)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-2 mt-2">
                        <label className="form-control-label text-muted">
                          {_("description")}
                        </label>
                        <textarea
                          className="form-control"
                          disabled
                          rows="4"
                          cols="50"
                          value={activity.description}
                        ></textarea>
                      </div>
                      <div className="form-group mb-2 mt-2">
                        <label className="form-control-label text-muted">
                          {_("ticket_info")}
                        </label>
                        <div className="card-bg rounded mx-4 p-1">
                          <div className="row d-none d-md-flex">
                            <div className="col-3">
                              <span
                                className="ms-1"
                                style={{ color: "#FFFFFF" }}
                              >
                                {_("category")}
                              </span>
                            </div>
                            <div className="col-3">
                              <span
                                className="ms-1"
                                style={{ color: "#FFFFFF" }}
                              >
                                {_("armchair")}
                              </span>
                            </div>
                            <div className="col-3">
                              <span style={{ color: "#FFFFFF" }}>
                                {_("type")}
                              </span>
                            </div>
                            <div className="col-3 text-center">
                              <span style={{ color: "#FFFFFF" }}>
                                {_("fee")}
                              </span>
                            </div>
                          </div>

                          {JSON.parse(activity.activity_fee).map((val) => {
                            return (
                              <div className="card p-1 my-1">
                                <div className="row">
                                  <div className="col-md-3 d-flex justify-content-center justify-content-md-start align-items-center">
                                    <span className="text-start multi-line-text-truncate">
                                      {val.activityCategoryName}
                                    </span>
                                  </div>
                                  <div className="col-md-3 d-flex justify-content-center justify-content-md-start align-items-center">
                                    <span className="text-start multi-line-text-truncate">
                                      {val.activitySeat}
                                    </span>
                                  </div>
                                  <div
                                    className="d-block d-md-none"
                                    style={{ color: "#933CAC" }}
                                  >
                                    <hr style={{ height: 2 }} />
                                  </div>

                                  <div className="col-md-6">
                                    {val.activityCategoryType.map((ind, i) => {
                                      return (
                                        <div className="row">
                                          <div className="col-6 d-flex justify-content-center justify-content-md-start align-items-center">
                                            <span className="text-start multi-line-text-truncate">
                                              {ind.activityClass}
                                            </span>
                                          </div>
                                          <div className="col-6 d-flex justify-content-center align-items-center">
                                            <span className="text-center multi-line-text-truncate">
                                              {ind.activityPrice} TRY
                                            </span>
                                          </div>

                                          {i <
                                          val.activityCategoryType.length -
                                            1 ? (
                                            <div style={{ color: "#000" }}>
                                              <hr style={{ height: 1 }} />
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {availableSeats.length > 0 && (
                        <div className="container mt-5">
                          <h1 style={{ fontSize: 16 }} className="homeText">
                            {_("seating_arrangement")}
                          </h1>
                          <label className="form-control-label text-muted">
                            {_("hall_name")}
                          </label>
                          <div className="d-flex flex-wrap mb-4 col-sm-12 border border-secondary-subtle p-2 rounded-2">
                            <select
                              className={
                                "form-control text-center bg-white headerInput"
                              }
                              style={{ float: "left" }}
                              onChange={handleSelectChange}
                            >
                              <option value="">{_("select_hall")}</option>
                              {hallPlans.map((hall) => (
                                <option key={hall.id} value={hall.id}>
                                  {hall.title}
                                </option>
                              ))}
                            </select>

                            <div className="d-flex justify-content-end w-100 mt-2">
                              <button
                                onClick={() =>
                                  (window.location.href =
                                    globals.url + "i/addSeats")
                                }
                                style={{
                                  backgroundColor: "#933CAC",
                                  color: "#FFFFFF",
                                  border: "3px solid #FFD700",
                                  boxShadow: "0 0 10px 0 rgba(100,100,100,1)",
                                }}
                                className="btn"
                              >
                                {_("add_new_hall")}
                              </button>
                            </div>
                          </div>

                          {categories.length > 0 && selectedHallPlan && (
                            <>
                              <label className="form-control-label text-muted">
                                {_("category")}
                              </label>
                              <div>
                                <span
                                  style={{ color: "#933CAC" }}
                                  className="fas fa-chair"
                                />
                                <span className="ms-1 homeText">{_("seated")}</span>
                              </div>

                              <div className="d-flex flex-wrap gap-3 border border-secondary-subtle p-2 rounded-2 justify-content-space-between mb-3">
                                {categories.map((val, index) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          border: `2px solid ${val.color}`,
                                          boxShadow:
                                            "0 0 10px 0 rgba(100,100,100,1)",
                                        }}
                                        key={index}
                                        className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 rounded-2"
                                      >
                                        <span
                                          style={{ fontSize: 12 }}
                                          className="text-muted d-flex"
                                        >
                                          <small className="text-center">
                                            {val.categoryName}
                                          </small>
                                        </span>
                                        <span
                                          style={{
                                            border: `1px solid ${val.color}`,
                                            boxShadow:
                                              "0 0 10px 0 rgba(100,100,100,1)",
                                          }}
                                          className="d-flex mt-1"
                                        >
                                          <input
                                            label="Color"
                                            type="color"
                                            value={val.color}
                                            onChange={(e) =>
                                              handleInputChangeColor(index, e)
                                            }
                                          />
                                        </span>
                                        {val.priceTypes.map((res, i) => {
                                          return (
                                            <div
                                              style={{
                                                border: `2px solid ${val.color}`,
                                                boxShadow:
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                              }}
                                              className="rounded-1 px-1 d-flex justify-content-between align-items-center mt-1"
                                              key={i}
                                            >
                                              <span
                                                style={{ fontSize: 12 }}
                                                className="text-muted d-flex"
                                              >
                                                <small className="text-muted">
                                                  {res.activityClass}
                                                </small>
                                              </span>
                                              <span
                                                style={{ fontSize: 12 }}
                                                className="text-muted d-flex"
                                              >
                                                <small className="text-muted">
                                                  {res.activityPrice}
                                                </small>
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>

                              {categoriesUnseat.length > 0 && (
                                <>
                                  <div>
                                    <span
                                      style={{ color: "#933CAC" }}
                                      className="fas fa-person"
                                    />
                                    <span className="ms-1 homeText">
                                    {_("standing")}
                                    </span>
                                  </div>
                                  <div className="d-flex flex-wrap gap-3 border border-secondary-subtle p-2 rounded-2 justify-content-space-between mb-3">
                                    {categoriesUnseat?.map((val, index) => {
                                      return (
                                        <>
                                          <div
                                            style={{
                                              border: `2px solid ${val.color}`,
                                              boxShadow:
                                                "0 0 10px 0 rgba(100,100,100,1)",
                                            }}
                                            key={index}
                                            className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 rounded-2"
                                          >
                                            <span
                                              style={{ fontSize: 12 }}
                                              className="text-muted d-flex"
                                            >
                                              <small className="text-center">
                                                {val.categoryName}
                                              </small>
                                            </span>
                                            <span
                                              style={{
                                                border: `1px solid ${val.color}`,
                                                boxShadow:
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                              }}
                                              className="d-flex mt-1"
                                            >
                                              <input
                                                label="Color"
                                                type="color"
                                                value={val.color}
                                                onChange={(e) =>
                                                  handleInputChangeColorUnseat(
                                                    index,
                                                    e
                                                  )
                                                }
                                              />
                                            </span>
                                            {val.priceTypes.map((res, i) => {
                                              return (
                                                <div
                                                  style={{
                                                    border: `2px solid ${val.color}`,
                                                    boxShadow:
                                                      "0 0 10px 0 rgba(100,100,100,1)",
                                                  }}
                                                  className="rounded-1 px-1 d-flex justify-content-between align-items-center mt-1"
                                                  key={i}
                                                >
                                                  <span
                                                    style={{ fontSize: 12 }}
                                                    className="text-muted d-flex"
                                                  >
                                                    <small className="text-muted">
                                                      {res.activityClass}
                                                    </small>
                                                  </span>
                                                  <span
                                                    style={{ fontSize: 12 }}
                                                    className="text-muted d-flex"
                                                  >
                                                    <small className="text-muted">
                                                      {res.activityPrice}
                                                    </small>
                                                  </span>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                </>
                              )}

                              <div
                                id="seat-map"
                                className="seat-map border border-secondary-subtle p-2 rounded-2"
                              >
                                {seats.map((row, rowIndex) => (
                                  <div key={rowIndex} className="seat-row">
                                    {row.row_label ? (
                                      <Popover.Root key={rowIndex}>
                                        <Popover.Trigger asChild>
                                          <div className="seat-row-label">
                                            <span
                                              style={{
                                                border: "3px solid #FFD700",
                                                boxShadow:
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                              }}
                                              className="seat-label rounded"
                                            >
                                              {row.row_label}
                                            </span>
                                          </div>
                                        </Popover.Trigger>
                                        <Popover.Portal>
                                          <Popover.Content
                                            className="PopoverContent border border-primary-subtle"
                                            sideOffset={5}
                                          >
                                            <div className="select-container d-flex flex-column gap-2 mt-3">
                                              {categories.map(
                                                (category, index) => (
                                                  <span
                                                    key={index}
                                                    className="btn btn-sm rounded"
                                                    style={{
                                                      backgroundColor: `${category.color}`,
                                                      color: "#FFF",
                                                      border:
                                                        "3px solid #FFD700",
                                                      boxShadow:
                                                        "0 0 10px 0 rgba(100,100,100,1)",
                                                    }}
                                                  >
                                                    <div>
                                                      {category.categoryName}
                                                    </div>

                                                    <button
                                                      className="btn btn-sm rounded me-5"
                                                      style={{
                                                        backgroundColor: `${category.color}`,
                                                        color: "#FFF",
                                                        border:
                                                          "3px solid #FFD700",
                                                        boxShadow:
                                                          "0 0 10px 0 rgba(100,100,100,1)",
                                                      }}
                                                      onClick={() =>
                                                        handleAssignCategoryToRow(
                                                          rowIndex,
                                                          category
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          color: "#FFFFFF",
                                                        }}
                                                        className="fas fa-eye d-flex align-self-center justify-content-center"
                                                      />
                                                    </button>

                                                    <button
                                                      className="btn btn-sm rounded"
                                                      style={{
                                                        backgroundColor: `${category.color}`,
                                                        color: "#FFF",
                                                        border:
                                                          "3px solid #CDCDCDCD",
                                                        boxShadow:
                                                          "0 0 10px 0 rgba(100,100,100,1)",
                                                      }}
                                                      onClick={() =>
                                                        handleAssignCategoryToRow(
                                                          rowIndex,
                                                          category,
                                                          true
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          color: "#FFFFFF",
                                                        }}
                                                        className="fas fa-eye-slash d-flex align-self-center justify-content-center"
                                                      />
                                                    </button>
                                                  </span>
                                                )
                                              )}
                                            </div>

                                            <div className="select-container d-flex flex-column gap-2 mt-3">
                                              {categoriesUnseat.map(
                                                (category, index) => (
                                                  <button
                                                    key={index}
                                                    className="btn btn-sm rounded-5"
                                                    style={{
                                                      backgroundColor: `${category.color}`,
                                                      color: "#FFF",
                                                      border:
                                                        "3px solid #FFD700",
                                                      boxShadow:
                                                        "0 0 10px 0 rgba(100,100,100,1)",
                                                    }}
                                                    onClick={() =>
                                                      handleAssignCategoryToRow(
                                                        rowIndex,
                                                        category,
                                                        false,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    {category.categoryName}
                                                  </button>
                                                )
                                              )}
                                            </div>
                                            <div className="select-container d-flex flex-column gap-2 mt-3">
                                              <button
                                                className="btn btn-sm rounded-5 mt-3"
                                                style={{
                                                  backgroundColor: "red",
                                                  color: "#FFF",
                                                  border: "3px solid #933CAC",
                                                  boxShadow:
                                                    "0 0 10px 0 rgba(100,100,100,1)",
                                                }}
                                                onClick={() =>
                                                  handleAssignCategoryToRow(
                                                    rowIndex,
                                                    null,
                                                    false,
                                                    true
                                                  )
                                                }
                                              >
                                                {_("cancel_selection")}
                                              </button>
                                            </div>
                                            {/* Tek bir cancel_selection butonu */}

                                            <Popover.Close className="bi bi-x-circle-fill PopoverClose" />
                                            <Popover.Arrow className="PopoverArrow" />
                                          </Popover.Content>
                                        </Popover.Portal>
                                      </Popover.Root>
                                    ) : (
                                      <div className="seat-row-label" />
                                    )}

                                    {row.hall_seat.map((seat, colIndex) => {
                                      const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                      const isEmpty = seat.status === "empty";
                                      const isClose = seat.is_active === 0;
                                       
                                      const isClickable = seat.status === "seat" || seat.status === "standing"
                                      const isSeat = seat.status === "seat";
                                      const isStanding = seat.status === "standing"
                                      const isCenterStage = centerStageSeat && centerStageSeat.rowIndex === rowIndex && centerStageSeat.colIndex === colIndex;
                                      return (
                                        <div
                                          style={{
                                            backgroundColor:
                                              isStage && seat.color,
                                          }}
                                        >
                                          <Popover.Root key={colIndex}>
                                            {isClickable ? (
                                              <Popover.Trigger asChild>
                                                <div
                                                  className={`seat ${
                                                    seat.status
                                                  } ${
                                                    isStage ? "stage-seat" : ""
                                                  }  ${seat.position_type==="left" && "leftLoveSeat"} 
                            ${seat.position_type==="right" && "rightLoveSeat"} 
                            ${seat.type==="disable" && "disableSeat"}
                            ${seat.type==="normal" && "normalSeat"}
                            ${(isStanding && isClose) && "closeSeat"}
                            ${(isStanding && !isClose) && "normalSeat"}
                            `}
                                                  style={{
                                                    backgroundColor: seat.color,
                                                    
                                                    cursor: "pointer", // Tıklanabilir koltuklarda pointer işaretçisi
                                                    color: "#FFFFFF",
                                                    letterSpacing: "1px",
                                                    boxShadow:
                                                      "0 0 10px 0 rgba(100,100,100,1)",
                                                  }}
                                                  onClick={() => ""} // Dummy onClick, asıl işlevi Popover kullanır
                                                >
                                                  {
                                                    seat.label &&
                                                    <span className={`seat-label d-flex justify-content-center ${isClose && "border rounded"}`}>
                                                    {seat.label}
                                                  </span>
                                                  }
                                                  
                                                  
                                                </div>
                                              </Popover.Trigger>
                                            ) : (
                                              <div
                                                className={`seat ${seat.status}`}
                                                style={{
                                                  border: "none",
                                                  cursor: "default", // Tıklanamaz koltuklarda default işaretçi
                                                }}
                                              >
                                                {isCenterStage &&
                                                  isStage &&
                                                  (positionType === 1 ? (
                                                    <div
                                                      className="rounded"
                                                      style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        zIndex: 3,
                                                       
                                                        color: "#FFFFFF",
                                                      }}
                                                    >
                                                      <span className="m-5">
                                                        {_("stage")}
                                                      </span>
                                                    </div>
                                                  ) : positionType === 2 ? (
                                                    <div
                                                      className="rounded"
                                                      style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "100%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        zIndex: 3,
                                                       
                                                        color: "#FFFFFF",
                                                      }}
                                                    >
                                                      <span className="m-5">
                                                        {_("stage")}
                                                      </span>
                                                    </div>
                                                  ) : positionType == 3 ? (
                                                    <div
                                                      className="rounded"
                                                      style={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: "50%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        zIndex: 3,
                                                       
                                                        color: "#FFFFFF",
                                                      }}
                                                    >
                                                      <span className="m-5">
                                                        {_("stage")}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="rounded"
                                                      style={{
                                                        position: "absolute",
                                                        top: "100%",
                                                        left: "100%",
                                                        transform:
                                                          "translate(-50%, -50%)",
                                                        zIndex: 3,
                                                       
                                                        color: "#FFFFFF",
                                                      }}
                                                    >
                                                      <span className="m-5">
                                                        {_("stage")}
                                                      </span>
                                                    </div>
                                                  ))}
                                              </div>
                                            )}

                                            {isClickable && (
                                              <Popover.Portal>
                                                <Popover.Content
                                                  className="PopoverContent border border-primary-subtle"
                                                  sideOffset={5}
                                                >
                                                  {isSeat ? (
                                                    <div className="select-container d-flex flex-column gap-2 mt-3">
                                                      {categories.map(
                                                        (category, index) => (
                                                          <span
                                                            key={index}
                                                            className="btn btn-sm rounded"
                                                            style={{
                                                              backgroundColor: `${category.color}`,
                                                              color: "#FFF",
                                                              border:
                                                                "3px solid #FFD700",
                                                              boxShadow:
                                                                "0 0 10px 0 rgba(100,100,100,1)",
                                                            }}
                                                          >
                                                            <div>
                                                              {
                                                                category.categoryName
                                                              }
                                                            </div>

                                                            <button
                                                              key={index}
                                                              className="btn btn-sm rounded me-5"
                                                              style={{
                                                                backgroundColor: `${category.color}`,
                                                                color: "#FFF",
                                                                border:
                                                                  "3px solid #FFD700",
                                                                boxShadow:
                                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                                              }}
                                                              onClick={() =>
                                                                handleAssignCategory(
                                                                  rowIndex,
                                                                  colIndex,
                                                                  category
                                                                )
                                                              }
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "#FFFFFF",
                                                                }}
                                                                className="fas fa-eye d-flex align-self-center justify-content-center"
                                                              />
                                                            </button>
                                                            <button
                                                              key={index}
                                                              className="btn btn-sm rounded"
                                                              style={{
                                                                backgroundColor: `${category.color}`,
                                                                color: "#FFF",
                                                                border:
                                                                  "3px solid #CDCDCDCD",
                                                                boxShadow:
                                                                  "0 0 10px 0 rgba(100,100,100,1)",
                                                              }}
                                                              onClick={(e) =>
                                                                handleAssignCategory(
                                                                  rowIndex,
                                                                  colIndex,
                                                                  category,
                                                                  (e = 1)
                                                                )
                                                              }
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "#FFFFFF",
                                                                }}
                                                                className="fas fa-eye-slash d-flex align-self-center justify-content-center"
                                                              />
                                                            </button>
                                                          </span>
                                                        )
                                                      )}
                                                      <button
                                                        className="btn btn-sm rounded-5"
                                                        style={{
                                                          backgroundColor:
                                                            "red",
                                                          color: "#FFF",
                                                          border:
                                                            "3px solid #933CAC",
                                                          boxShadow:
                                                            "0 0 10px 0 rgba(100,100,100,1)",
                                                        }}
                                                        onClick={() =>
                                                          handleAssignCategory(
                                                            rowIndex,
                                                            colIndex
                                                          )
                                                        }
                                                      >
                                                        {_("cancel_selection")}
                                                      </button>
                                                    </div>
                                                  ) : (
                                                    <div className="select-container d-flex flex-column gap-2 mt-3">
                                                      {categoriesUnseat.map(
                                                        (category, index) => (
                                                          <button
                                                            key={index}
                                                            className="btn btn-sm rounded-5"
                                                            style={{
                                                              backgroundColor: `${category.color}`,
                                                              color: "#FFF",
                                                              border:
                                                                "3px solid #FFD700",
                                                              boxShadow:
                                                                "0 0 10px 0 rgba(100,100,100,1)",
                                                            }}
                                                            onClick={() =>
                                                              handleAssignCategoryStanding(
                                                                rowIndex,
                                                                colIndex,
                                                                category
                                                              )
                                                            }
                                                          >
                                                            {
                                                              category.categoryName
                                                            }
                                                          </button>
                                                        )
                                                      )}
                                                      <button
                                                        className="btn btn-sm rounded-5"
                                                        style={{
                                                          backgroundColor:
                                                            "red",
                                                          color: "#FFF",
                                                          border:
                                                            "3px solid #933CAC",
                                                          boxShadow:
                                                            "0 0 10px 0 rgba(100,100,100,1)",
                                                        }}
                                                        onClick={() =>
                                                          handleAssignCategoryStanding(
                                                            rowIndex,
                                                            colIndex
                                                          )
                                                        }
                                                      >
                                                        {_("cancel_selection")}
                                                      </button>
                                                    </div>
                                                  )}

                                                  <Popover.Close className="bi bi-x-circle-fill PopoverClose" />
                                                  <Popover.Arrow className="PopoverArrow" />
                                                </Popover.Content>
                                              </Popover.Portal>
                                            )}
                                          </Popover.Root>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      )}

<div className="col-sm-12 mb-3">
<label className="form-control-label text-muted">
                                {_("responsible")}
                              </label>
															<Multiselect
																className="bg-white"
																options={responsibles}
																displayValue="username"
																selectionLimit={20}
																avoidHighlightFirstOption
																emptyRecordMsg={_('no_responsible_search_criteria')}
																placeholder={_('please_select_responsible')}
																selectedValues={responsibles.id}
																onSelect={onSelectResponsibles}
																onRemove={onRemoveResponsibles}
																style={{
																	searchBox: { // To change search box element look
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	inputField: { // To change input field position or margin
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	chips: { // To change css chips(Selected options)
																		backgroundColor: '#933CAC'
																	},
																	optionContainer: { // To change css for option container 
																		maxHeight: '150px', // Açılan kutunun maksimum yüksekliği
																		overflowY: 'auto', // Kaydırma çubuğu ekler
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	}
																}}
															/>
														</div>
                            <div className="col-sm-12 mb-3">
<label className="form-control-label text-muted">
                                {_("organizer")}
                              </label>
															<Multiselect
																className="bg-white"
																options={organizers}
																displayValue="username"
																selectionLimit={1}
																avoidHighlightFirstOption
																emptyRecordMsg={_('no_organiser_search_criteria')}
																placeholder={_('please_select_organiser')}
																selectedValues={organizers.id}
																onSelect={onSelectOrganizers}
																onRemove={onRemoveOrganizers}
																style={{
																	searchBox: { // To change search box element look
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	inputField: { // To change input field position or margin
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	chips: { // To change css chips(Selected options)
																		backgroundColor: '#933CAC'
																	},
																	optionContainer: { // To change css for option container 
																		maxHeight: '150px', // Açılan kutunun maksimum yüksekliği
																		overflowY: 'auto', // Kaydırma çubuğu ekler
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	}
																}}
															/>
														</div>

                      <div className="row justify-content-center my-3 px-3">
                        <button
                          onClick={() => addActivity()}
                          className="text-decoration-none btn btn-primary cardButton col-6"
                        >
                          {_("add_activity")}
                        </button>
                      </div>

                      
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={urlModal} onHide={() => setUrlModal(false)}>
          <Modal.Body closeButton style={{ background: "#fff" }}>
            <div className="modal-body row d-flex justify-content-center">
              <span className="row d-flex text-center justify-content-center mb-3">
                {_("activity_info")}
              </span>
              <div className="d-flex justify-content-center align-items-center px-2 mb-3">
                <a
                  href={`${globals.url}a/${urlModal}`}
                  className="homeText d-flex text-decoration-none border rounded-2 p-2"
                >
                  {`${globals.url}a/${urlModal}`}
                </a>
              </div>

              <button
                onClick={() => setUrlModal(false)}
                className="text-decoration-none btn btn-primary cardButton rounded-2 col-6"
              >
                {_("close")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
      <Footer />
    </div>
  );
};

export default AddActivity;
