import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import "react-phone-number-input/style.css";
import { useState, useEffect } from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import ShowMoreText from "react-show-more-text";
import { QRCode } from "react-qrcode-logo";
import images from "../images/images";
import Modal from "react-bootstrap/Modal";
import Zoom from "react-medium-image-zoom";
import Linkify from "linkify-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { af, ar, az, bg, bs, cy, de, el, es, fi, fr, he, hi, hr, hu, it, ja, kk, ko, lb, mk, ms, nl, nn, pt, ro, ru, sq, sr, sv, tr, uk, zhTW, enUS } from 'date-fns/locale';
import { Cookies } from "react-cookie";

const ActivityDetail = () => {
    const timestamp = new Date();
    const [number, setNumber] = useState();
    const [activityName, setActivityName] = useState("");
    const [activityCategoryName, setActivityCategoryName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [endDateTime, setEndDateTime] = useState("");
    const [description, setDescription] = useState("");
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [activityId, setActivityId] = useState("");
    const [activityPhoto, setActivityPhoto] = useState("");
    const [utc, setUTC] = useState("");
    const [imgCreated, setImgCreated] = useState("");
    const [extra, setExtra] = useState("");
    const [place, setPlace] = useState("");
    const [qrURL, setQrUrl] = useState("");
    const [showQrModal, setShowQrModal] = useState(false);
    const [userType, setUserType] = useState("");
    const [userId, setUserId] = useState("");
    const [createdId, setCreatedId] = useState("");
    const [serverTime, setServerTime] = useState("");
    const [urlModal, setUrlModal] = useState(false);
    const [copy, setCopy] = useState(false);
    const [startDateDay, setStartDateDay] = useState("");
    const [endDateDay, setEndDateDay] = useState("");
    const [activityStatusModal, setActivityStatusModal] = useState(false);
    const [activityEditModal, setActivityEditModal] = useState(false);
    const [activityStatus, setActivityStatus] = useState("");
    const formData = new FormData();
    const [activityStatusStopModal, setActivityStatusStopModal] = useState(false);
    const [activityStatusCancelModal, setActivityStatusCancelModal] = useState(false);
    const [cityName, setCityName] = useState("");
    const [priceList, setPriceList] = useState([]);
    const [activityTitle, setActivityTitle]= useState("")
    const [activityPlace, setActivityPlace]= useState("")
    const [activityDescription, setActivityDescription]= useState("")
    const [activityStartTime, setActivityStartTime] = useState("")
    const [activityEndTime, setActivityEndTime] = useState("")

    const [editorModal, setEditorModal] = useState(false);
    const [activityComment, setActivityComment]= useState("")
    const [metaDescription, setMetaDescripton]= useState("")
    const [metaKeywords, setMetaKeywords] = useState([])
    const [metaTag, setMetaTag] = useState('')

    const [descriptionMeta, setDescriptionMeta] = useState("")
    const [keywordsMeta, setKeywordsMeta] = useState([])
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utcLog, setUtcLog] = useState('')
    const [responsible, setResponsible] = useState([])
    const [responsibleResult, setResponsibleResult] = useState(false)
    const [selectCategory, setSelectCategory] = useState([])
    const [selectCategoryModal, setSelectCategoryModal] = useState(false)
    const [token, setToken] = useState(false)

    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [categoriesOrder, setCategoriesOrder] = useState([]); // Default sorting: Default Position
    const [planImage, setPlanImage] = useState("")
    const [planURL, setPlanURL] = useState("");
    const cookies = new Cookies();
    const [lang] = useState(cookies.get("language"));

    const getLocale = (lang) => {
        switch (lang) {
            case "af":
                return af;
            case "ar":
                return ar;
            case "az":
                return az;
            case "bg":
                return bg;
            case "bs":
                return bs;
            case "cy":
                return cy;
            case "de":
                return de;
            case "el":
                return el;
            case "es":
                return es;
            case "fi":
                return fi;
            case "fr":
                return fr;
            case "he":
                return he;
            case "hi":
                return hi;
            case "hr":
                return hr;
            case "hu":
                return hu;
            case "it":
                return it;
            case "ja":
                return ja;
            case "kk":
                return kk;
            case "ko":
                return ko;
            case "lb":
                return lb;
            case "mk":
                return mk;
            case "ms":
                return ms;
            case "nl":
                return nl;
            case "nn":
                return nn;
            case "pt":
                return pt;
            case "ro":
                return ro;
            case "ru":
                return ru;
            case "sq":
                return sq;
            case "sr":
                return sr;
            case "sv":
                return sv;
            case "tr":
                return tr;
            case "uk":
                return uk;
            case "tw":
                return zhTW;
            default:
                return enUS; 
        }
    };

    // Sorting functions
    const sortPriceList = (priceList) => {
        let sortedList = [...priceList];
    
        // Sort by price
        if (categoriesOrder[0] === 1) {
            sortedList.sort((a, b) => Math.max(...b.prices.map(p => p.price)) - Math.max(...a.prices.map(p => p.price)));
        } else if (categoriesOrder[0] === 2) {
            sortedList.sort((a, b) => Math.max(...a.prices.map(p => p.price)) - Math.max(...b.prices.map(p => p.price)));
        } else if (categoriesOrder[0] === 3) {
            sortedList.sort((a, b) => a.name.localeCompare(b.name));
        } else if (categoriesOrder[0] === 0) {
            // Sort by id if categoriesOrder[0] === 0
            sortedList.sort((a, b) => a.id - b.id);
        }
    
        // Sort by sold-out position
        if (categoriesOrder[1] === 2) {
            sortedList = [
                ...sortedList.filter(item => item.total_tickets === item.solded_tickets),
                ...sortedList.filter(item => item.total_tickets > item.solded_tickets)
            ];
        } else if (categoriesOrder[1] === 1) {
            sortedList = [
                ...sortedList.filter(item => item.total_tickets > item.solded_tickets),
                ...sortedList.filter(item => item.total_tickets === item.solded_tickets)
            ];
        } 
    
        // Update order_number based on final sort
        sortedList.forEach((item, index) => {
            item.order_number = index + 1;
        });
    
        return sortedList;
    };
    

    const handleChangeSortOrder = (type, val) => {
     
        if(type ===1){
            setCategoriesOrder((prevOrder) => {
                const updatedOrder = [...prevOrder];
                updatedOrder[0] = val; // İlk eleman 0 yapılır
                return updatedOrder;
            });

        }else{
            setCategoriesOrder((prevOrder) => {
                const updatedOrder = [...prevOrder];
                updatedOrder[1] = val; // İlk eleman 0 yapılır
                return updatedOrder;
            });
        }

    }
    
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627,
        },
        zoom: 11,
    };

    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(()=>{
        setDevice(navigator.userAgent)
    },[])

    useEffect(()=>{
        setUtcLog(new Date().getTimezoneOffset()/-60)
    },[])

    useEffect(()=>{
        HTTP.get('https://api.ipify.org?format=json')
        .then(
            res=>{
                setIp(res.data.ip)
            }
        )
    },[])

    useEffect(() => {
        setLoading(true);
        setNumber(window.location.href.split("/")[4]);
        setQrUrl(window.location.href);
        if (number) {
            HTTP.get(`/activities/getLogout/${number}`)
                .then((res) => {
                    setLoading(false);
                    if (res.data.result) {
                        setPriceList(res.data.activity.hall.groups);
                        setPlanURL(res.data.activity.seats_plan);
                        setCategoriesOrder(JSON.parse(res.data.activity.categories_order))
                        setCityName(res.data.activity.city.name);
                        setActivityName(res.data.activity.title);
                        setStartDate(
                            moment(res.data.activity.start_time).format("DD.MM.YYYY")
                        );
                        setActivityStartTime(new Date(res.data.activity.start_time))
                        setActivityEndTime(new Date(res.data.activity.end_time))
                        
                        setStartTime(moment(res.data.activity.start_time).format("HH:mm"));
                        setEndDate(moment(res.data.activity.end_time).format("DD.MM.YYYY"));
                        setEndTime(moment(res.data.activity.end_time).format("HH:mm"));
                        setEndDateTime(res.data.activity.end_time);
                        setDescription(res.data.activity.description);
                        setComment(res.data.activity.comment);
                        setDescriptionMeta(res?.data?.activity?.meta_description)
                        if (res.data.activity.meta_keywords !== null && res.data.activity.meta_keywords !== '') {
                            setKeywordsMeta(JSON.parse(res.data.activity.meta_keywords));
                          }
                        setActivityCategoryName(res.data.activity.category.name);
                        setActivityId(res.data.activity.id);
                        setActivityPhoto(res.data.activity.photo_name);
                        setUTC(res.data.activity.utc);
                        setImgCreated(res.data.activity.created_at);
                        setExtra(res.data.activity.extra);
                        setPlanImage(res.data.activity.hall.plan_file_url)
                        setPlace(res.data.activity.place.title);
                        setActivityPlace(res.data.activity.place.id)
                        setCreatedId(res.data.activity.created_by);
                        setServerTime(res.data.time);
                        setStartDateDay(
                            moment(res.data.activity.start_time)
                                .format("dddd")
                                .toLocaleLowerCase()
                        );
                        setEndDateDay(
                            moment(res.data.activity.end_time)
                                .format("dddd")
                                .toLocaleLowerCase()
                        );
                        setActivityStatus(res.data.activity.activity_status);
                        if (res.data.activity.responsible_user_id !== null && res.data.activity.responsible_user_id !== '') {
                            setResponsible(JSON.parse(res.data.activity.responsible_user_id));
                          }
                    } else {
                        setLoading(true);
                        window.location.href = globals.url;
                    }
                })
                .catch(setLoading(true));
        }
    }, [number]);

    const formattedUtc = (hour) => {
        if (hour == 0) {
            var result = "UTC";
            return result;
        } else {
            if (hour < 0) {
                var signal = "UTC - ";
                var hour = hour * -1;
            } else {
                var signal = "UTC + ";
            }

            if (hour < 10) {
                var formatHour = "0" + parseInt(hour);
            } else {
                var formatHour = parseInt(hour);
            }

            if (hour % 1 > 0) {
                var formatMinute = (hour % 1) * 60;
            } else {
                var formatMinute = "00";
            }

            result = signal + formatHour + ":" + formatMinute;
            return result;
        }
    };

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser?.result) {
            setUserType(getuser.user.type);
            setUserId(getuser.user.id);
            setToken(getuser.token)
        }
    }, []);

    useEffect(() => {
        setResponsibleResult(responsible.includes(userId))
       
    }, [responsible, userId]);

    const linkProps = {
        onClick: (event) => {
            event.preventDefault();
            //  window.open(extra)
            setCopy(false);
            setUrlModal(true);
        },
    };

    const urlCopy = () => {
        setCopy(true);
        toast(_("copied"), {
            position: "top-right",
            autoClose: 3000,
            type: "success",
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false,
        });
        setUrlModal(false);
    };

    const changeActivityStatus = (val) => {
        formData.append("number", number);
        formData.append("activity_status", val);
        formData.append("ip_address", ip);
        formData.append("device_name", device);
        formData.append("utc", utcLog);
        formData.append("userId", userId);


        HTTP.post("/changeActivityStatus", formData)
            .then((res) => {
                if (res.data.result) {
                    if (res.data.activity.deleted_at) {
                        setActivityStatusModal(false);
                        window.location.href = globals.url;
                    } else {
                        setActivityStatusModal(false);
                        window.location.reload();
                    }
                } else {
                    setLoading(true);
                }
            })
            .catch(setLoading(true));
    };


    const updateCategories = () => {
        setLoading(true)
        formData.append("priceList", JSON.stringify(priceList));
        formData.append("categories_order", JSON.stringify(categoriesOrder));
        formData.append("activity_id", activityId);
        HTTP.post("/updateOrderNumbers", formData, {
            headers: { Authorization: `bearer ${token}` },
          })
            .then((res) => {
              
                     if (res.data.result) {
                        setEditCategoryModal(false)
                         setTimeout(() => window.location.reload(), 3000);
                       toast(_("successful"), {
                         position: "top-right",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: false,
                         pauseOnHover: false,
                         draggable: false,
                         progress: undefined,
                         theme: "light",
                         closeButton: false,
                       });
                     } else {
                       toast(res.data.message, {
                         position: "top-right",
                         autoClose: 3000,
                         hideProgressBar: false,
                         closeOnClick: false,
                         pauseOnHover: false,
                         draggable: false,
                         progress: undefined,
                         theme: "light",
                         closeButton: false,
                         type: "error",
                       });
                     }
            })
            .catch(setLoading(true));
    };


    const activityChangeStatus = (val) => {
        if (val != 2) {
            setActivityStatusStopModal(true);
        } else {
            setActivityStatusCancelModal(true);
        }
    };

    const tagManagerArgs = {
        gtmId: "GTM-PQCRDTTD",
    };

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: activityName,
            },
            event: "activity_detail_page",
        });
    }, [activityName]);
    
    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    useEffect(()=>{
        setActivityTitle(activityName)
        setActivityDescription(description)
        setActivityComment(comment)
        setMetaDescripton(descriptionMeta)
        setMetaKeywords(keywordsMeta)
    },[activityName, place, description,comment, descriptionMeta, keywordsMeta])   
    const goToReport = () => {
        window.location.href=globals.url + "a/activityreport/" + number
    }
    const saveMetaTag = () =>{
        if(metaKeywords.length < 9){
            if(metaTag != ''){
                if(metaKeywords.indexOf(metaTag)== -1){
                    setMetaKeywords(data=>[...data, (metaTag)])
                }
                setMetaTag('')
            }
        }else{
            setMetaTag('')
        }
    }
    const removeMetaTag = (data) =>{
        setMetaKeywords(metaKeywords.filter(item=>item!==data))
    }
    const tagList = () =>{
        return metaKeywords.map((data)=>{
            return(
                <div class="col d-flex justify-content-center">
                    <div key={data} class=" text-decoration-none btn btn-primary saloonButton rounded-2">{data}</div>
                    <button  onClick={() => removeMetaTag(data)} className="btn btn-secondary bi bi-x-circle-fill"/>
                </div>
            )
        })
    }
 
    const sendEditorPanel = () =>{
        formData.append("id", activityId);
        formData.append("activity_title", activityTitle);
        formData.append("description", activityDescription);
        formData.append("comment", activityComment);
        formData.append("meta_description", metaDescription);
        formData.append("meta_keywords", JSON.stringify(metaKeywords));
        formData.append("place_id", activityPlace);
        formData.append("start_time", moment(activityStartTime).format("DD.MM.YYYY HH:mm"));
        formData.append("end_time", moment(activityEndTime).format("DD.MM.YYYY HH:mm"));
        formData.append("ip_address", ip);
        formData.append("device_name", device);
        formData.append("utc", utcLog);
        formData.append("userId", userId);

        HTTP.post("/activities/update", formData)
            .then((res) => {
                
                if (res.data.result) {
                    
                        setEditorModal(false);
                        window.location.reload();
                    
                } else {
                    setLoading(true);
                }
            })
            .catch(setLoading(true));

    }

    const zoomCategory = (item) =>{
        setSelectCategory(item)
        setSelectCategoryModal(true)
    }



    return (
        <div>
            <Helmet>
                <title>Vidipass | {activityName} | {cityName}</title>
                <meta name="description" content={metaDescription?metaDescription:activityDescription}/>
                <meta name="keywords" content={metaKeywords.filter(Boolean).join(", ")}/>
                <meta name="robots" content="index, follow"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5" style={{marginTop: '50px'}}>
                        {((userType == 2 && createdId == userId) || userType == 3 || (userType == 4 && responsibleResult)) && (
                            <div className="col-12 rounded-2">
                                <div className="text-end mt-2 mb-2">
                                    {
                                        serverTime > 0 && (
                                            <>
                                            {
                                                userType == 3 &&
                                                <>
                                                    <span data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: 10 }} onClick={() => '' + gtmTag('editor_panel')} className="text-decoration-none btn btn-primary panelButton rounded-2">
                                                        {_("editor_panel")}
                                                    </span>
                                                    <ul style={{borderWidth:1, borderColor:'#933CAC'}} className="dropdown-menu px-1" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <button onClick={() => setActivityEditModal(true) + gtmTag('activity_edit')} className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} >{_("activity_edit")}</button>
                                                        </li>
                                                        <li className="mt-1">
                                                            <button className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} onClick={()=>setEditorModal(true) + gtmTag('activity_packages')}>{_("activity_packages")}</button>
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                            </>

                                        )
                                    }
                                   
                                    <span data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: 10, marginLeft:10 }} onClick={() => '' + gtmTag('organizator_panel')} className="text-decoration-none btn btn-primary panelButton rounded-2">
                                        {_("organizator_panel")}
                                    </span>
                                    <ul style={{borderWidth:1, borderColor:'#933CAC'}} className="dropdown-menu px-1" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <button className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} onClick={()=>goToReport() + gtmTag('activity_report')} >{_("activity_report")}</button>
                                        </li>
                                        {
                                            serverTime >0 &&
                                            !(userType == 4 && responsibleResult) &&
                                            <li className="mt-1">
                                                <button onClick={() => setEditCategoryModal(true) + gtmTag('category_sorting')} className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} >{_("category_sorting")}</button>
                                            </li>
                                        }
                                        {
                                            serverTime >0 &&
                                            !(userType == 4 && responsibleResult) &&
                                            <li className="mt-1">
                                                <button className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} onClick={()=>window.location.href=globals.url + "i/editInvitations/" + number}>{_("invitation_operations")}</button>
                                            </li>
                                        }
                                        {planURL ?(
                                            serverTime >0 &&
                                            !(userType == 4 && responsibleResult) &&
                                            <li className="mt-1">
                                                <button className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} onClick={()=>window.location.href=globals.url + "i/reservations/" + number}>{_("reservation_operations")}</button>
                                            </li>
                                            ):
                                            ""
                                        }
                                         {!planImage &&
                                            serverTime >0 &&
                                            !(userType == 4 && responsibleResult) &&
                                            <li className="mt-1">
                                                <button onClick={() => window.location.href=globals.url + "i/seatOperations/" + number} className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} >{_("seat_operations")}</button>
                                            </li>
                                        }
                                        
                                        {
                                            serverTime >0 &&
                                            !(userType == 4 && responsibleResult) &&
                                            <li className="mt-1">
                                                <button onClick={() => setActivityStatusModal(true) + gtmTag('activity_control')} className="dropdown-item text-decoration-none btn btn-primary panelButton rounded-2 text-center" style={{ fontSize: 12, color:'#FFFFFF' }} >{_("activity_control")}</button>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        )}
                        
                        <div className="col-12 card-back p-1 rounded-2 mb-2">
                            <div style={{backgroundColor:'#f6f6f6'}} className="col-12 justify-content-center card">
                            <div className="card-back rounded m-4 py-1 shadow">
                               <div className="card d-flex mx-1">
                               <div className="row">
                                    <div className="col-sm-12 col-md-5 justify-content-center">
                                        {activityPhoto && (
                                            <img
                                                src={
                                                    globals.imgUrl +
                                                    activityId +
                                                    "/thumb/" +
                                                    activityPhoto +
                                                    ".png" +
                                                    "?" +
                                                    new Date(imgCreated).getTime()
                                                }
                                                className="rounded-2"
                                                width='100%'
                                                height='auto'
                                                alt="activity photo"
                                            />
                                        )}
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <div className="row">
                                            <h1 className="text-center" style={{ color: "#2E81D2", fontSize:28 }}>
                                                {activityName}
                                            </h1>
                                        </div>
                                        <h2 style={{fontSize:23}} className="text-center homeText">{_("category")}</h2>
                                        <p className="text-center text-muted">
                                            {_(activityCategoryName)}
                                        </p>

                                        <div className="row text-center">
                                            <div className=" col">
                                                <h2 style={{fontSize:20}} className="homeText text-center text-truncate">
                                                    {_("start_time")}
                                                </h2>

                                                <div className="row">
                                                    <span className="text-muted">
                                                        {startDate}
                                                    </span>
                                                    <span className="text-muted">{_(startDateDay)}</span>
                                                    <span className="text-muted">
                                                        {startTime}
                                                        {/* <span style={{ fontSize: 11 }}>({formattedUtc(utc)})</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <h2 style={{fontSize:20}} className="homeText text-center text-truncate">
                                                    {_("end_time")}
                                                </h2>
                                                <div className="row">
                                                    <span className="text-muted">
                                                        {endDate}
                                                    </span>
                                                    <span className="text-muted">{_(endDateDay)}</span>
                                                    <span className="text-muted">
                                                        {endTime}{" "}
                                                        <span style={{ fontSize: 11 }}>
                                                            ({formattedUtc(utc)})
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <h2 style={{fontSize:20}} className="homeText text-center text-truncate">
                                                {_("place")}
                                            </h2>
                                            <span className="text-muted">{cityName}</span>
                                            <p className="text-muted">
                                                {place}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <h2 style={{fontSize:23}} className="text-center homeText">
                                    {_("fee")}
                                </h2>

                                <div className="card-bg rounded mx-4 p-1">
                                    <div className="row d-none d-md-flex">
                                        <div className="col-4">
                                            <span className="ms-1" style={{ color: "#FFFFFF" }}>{_("category")}</span>
                                        </div>
                                        <div className="col-4  ">
                                            <span style={{ color: "#FFFFFF" }}>{_("type")}</span>
                                        </div>
                                        <div className="col-4  text-center">
                                            <span style={{ color: "#FFFFFF" }} >{_("fee")}</span>
                                        </div>
                                    </div>

                                    {priceList.map((item) => {
                        const remainingTickets = item.total_tickets - item.solded_tickets;
                        const isPastEvent = moment().isAfter(moment(endDateTime));

                        return (
                            item.total_tickets > 0 && (
                                <div className="card p-1 my-1" key={item.id}>
                                    <div
                                        onClick={() => zoomCategory(item) + gtmTag("ticket_category_info")}
                                        className="row"
                                    >
                                        <div className="col-md-4 d-flex justify-content-center justify-content-md-start align-items-center">
                                            <>
                                            <span className="text-start multi-line-text-truncate">
                       {item.name}
                        {token && remainingTickets === 0 && !isPastEvent && (
                            <span style={{ color: "#c7c3ca" }} className="ms-1">
                                ({_("sold_out")})
                            </span>
                        )}
                        {remainingTickets > 0 && remainingTickets <= 10 && !isPastEvent && (
                            <span style={{ color: "#EC008C" }} className="ms-1 blinking-text">
                                ({_("last_ticket").replace("%s", remainingTickets)})
                            </span>
                        )}
                    </span>
                        
                        </>
                    </div>
                    <div className="d-block d-md-none" style={{ color: "#933CAC" }}>
                        <hr style={{ height: 2 }} />
                    </div>

                    <div className="col-md-8">
                        {item.prices.map((ind, i) => (
                            <div className="row" key={ind.id}>
                                <div className="col-6 d-flex justify-content-center justify-content-md-start align-items-center">
                                    <span className="text-start multi-line-text-truncate">
                                        {ind.name}
                                    </span>
                                </div>
                                <div className="col-6 d-flex justify-content-center align-items-center">
                                    <span className="text-center multi-line-text-truncate">
                                        {ind.price} TRY
                                    </span>
                                </div>
                                {i < item.prices.length - 1 && (
                                    <div style={{ color: "#000" }}>
                                        <hr style={{ height: 1 }} />
                                    </div>
                                )}
                            </div>
                        ))}

                       
                    </div>
                </div>
            </div>
        )
    );
})}
                                </div>

                                {serverTime > 0 ? (
                                    activityStatus == 0 ? (
                                        <div className="row justify-content-center px-5 mb-3 mt-3">
                                            <a
                                                onClick={() =>
                                                (window.dataLayer =
                                                    [] +
                                                    window.dataLayer.push({
                                                        event: "buy_a_ticket_button",
                                                    }))
                                                }
                                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-8 col-md-4"
                                                href={globals.url + "a/ticketing/" + number}
                                            >
                                                {_("buy_now")}
                                            </a>
                                        </div>
                                    ) : (
                                    <div className="d-flex justify-content-center mt-3 mb-3">
                                        <div style={{ backgroundColor: "#CCC" }} className="pe-none btn btn-secondary rounded-2 col-8 col-md-4">
                                            <span className="text-muted">{_("off_sold_out")}</span>
                                        </div>
                                    </div>
                                    )
                                ) : (
                                <div className="d-flex justify-content-center mt-3 mb-3">
                                    <div style={{ backgroundColor: "#CCC" }} className="pe-none btn btn-secondary rounded-2 col-8 col-md-4">
                                        <span className="text-muted">{_("activity_end")}</span>
                                    </div>
                                </div>
                                )}
                                 {
                                    description.length > 0 &&
                                    <h2 style={{fontSize:23}} className="text-center homeText">
                                        {_("about_the_activity")}
                                    </h2> 
                                    }
                                    
                                    <div  onClick={()=>gtmTag('continue_description')} className="text-start text-muted px-2">
                                        <ShowMoreText lines={3} more={_("continue")} less="">
                                            <p style={{ whiteSpace: "pre-line" }}>{description}</p>
                                        </ShowMoreText>
                                    </div>

                               </div>
                              
                                
                            </div>
                                
                               



                                <div>
                                   
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="card-back rounded mx-4 py-1 mb-4 shadow">
                                            <div className="row card d-flex mx-1">
                                                <h3 style={{fontSize:20}} className="text-center homeText">
                                                    {_("information")}
                                                </h3>
                                                <p onClick={()=>gtmTag('actividi_info_link')} className="text-center text-muted">
                                                    <Linkify
                                                        options={{
                                                            className: "homeText text-decoration-none",
                                                            attributes: linkProps,
                                                        }}
                                                    >
                                                        {_("actividi_info_1").replace("%s", extra)}
                                                    </Linkify>
                                                </p>
                                                <p className="text-center text-muted">
                                                    {_("actividi_info_2")}
                                                </p>
                                                <p className="text-center text-muted">
                                                    {_("actividi_info_3")}
                                                </p>
                                                <h3 style={{fontSize:20}} className="text-center homeText">
                                                    {_("warning")}
                                                </h3>
                                                <p className="text-center text-muted">
                                                    {_("warning_01")}
                                                </p>
                                                <p className="text-center text-muted">
                                                    {_("warning_02")}
                                                </p>
                                                <p className="text-center text-muted">
                                                    {_("warning_03")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {   comment &&
                                        <div className="col-sm-12 col-lg-12">
                                            <div className="card-back rounded m-4 py-1 shadow">
                                                <div className="row card d-flex mx-1">
                                                <h2 style={{fontSize:23}} className="text-center homeText">
                                            {_("editor_comment")}
                                            </h2>
                                            <div onClick={()=>gtmTag('continue_editor')} className="text-start text-muted px-2 mb-4">
                                                <ShowMoreText lines={3} more={_("continue")} less="">
                                                    <p style={{ whiteSpace: "pre-line" }}>{comment}</p>
                                                </ShowMoreText>
                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                   
                                </div>
                            </div>
                        </div>
                        {serverTime > 0 ? (
                                        activityStatus == 0 ? (
                                            <div className="row justify-content-center px-5 mb-3">
                                                <a
                                                    onClick={() =>
                                                    (window.dataLayer =
                                                        [] +
                                                        window.dataLayer.push({
                                                            event: "buy_ticket_button",
                                                        }))
                                                    }
                                                    className="text-decoration-none btn btn-primary cardButton rounded-2 col-8 col-md-4"
                                                    href={globals.url + "a/ticketing/" + number}
                                                >
                                                    {_("buy_ticket")}
                                                </a>
                                            </div>
                                        ) : (
                                        <div className="d-flex justify-content-center mt-3">
                                            <div
                                                style={{ backgroundColor: "#CCC" }}
                                                className="pe-none btn btn-secondary rounded-2 col-8 col-md-4"
                                            >
                                                <span className="text-muted">{_("off_sold_out")}</span>
                                            </div>
                                        </div>
                                        )
                                    ) : (
                                    <div className="d-flex justify-content-center mt-3 mb-3">
                                        <div
                                            style={{ backgroundColor: "#CCC" }}
                                            className="pe-none btn btn-secondary rounded-2 col-8 col-md-4"
                                        >
                                            <span className="text-muted">{_("activity_end")}</span>
                                        </div>
                                    </div>
                                    )}
                        <Modal show={urlModal} onHide={() => setUrlModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("online_adress")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex justify-content-center align-items-center px-2">
                                    <CopyToClipboard text={extra} onCopy={() => setCopy(true) + urlCopy()}>
                                        <div className="border rounded-2 p-2 d-flex me-2 d-flex justify-content-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="#933CAC"
                                                className="bi bi-copy"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                                />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                    <a
                                        onClick={() => window.open(extra) + setUrlModal(false)}
                                        className="homeText d-flex text-decoration-none border rounded-2 p-2"
                                    >
                                        {extra}
                                    </a>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusModal}
                            onHide={() => setActivityStatusModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_control")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card">
                                    <div className="m-2 row">
                                        {activityStatus == 0 ? (
                                            <a
                                                onClick={() => activityChangeStatus(1)}
                                                className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col me-1"
                                            >
                                                {_("stop_selling_tickets_for_event")}
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() => activityChangeStatus(0)}
                                                className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col ms-1"
                                            >
                                                {_("continue_selling_tickets_for_evet")}
                                            </a>
                                        )}

                                        <a
                                            onClick={() => activityChangeStatus(2)}
                                            className="text-decoration-none d-flex justify-content-center align-items-center btn btn-primary saloonButton rounded-2 col ms-1"
                                        >
                                            {_("cancel_activity")}
                                        </a>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusStopModal}
                            onHide={() => setActivityStatusStopModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    {activityStatus == 0 ? (
                                        <span className="homeText">
                                            {_("stop_selling_tickets_for_event")}
                                        </span>
                                    ) : (
                                        <span className="homeText">
                                            {_("continue_selling_tickets_for_evet")}
                                        </span>
                                    )}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div
                                            className="col text-end"
                                            onClick={
                                                activityStatus == 0
                                                    ? () => changeActivityStatus(1)
                                                    : () => changeActivityStatus(0)
                                            }
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div
                                            className="col text-end"
                                            onClick={() => setActivityStatusStopModal(false)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityStatusCancelModal}
                            onHide={() => setActivityStatusCancelModal(false)}
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("cancel_activity")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <h6 className="homeText">{_("sure")}</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="row">
                                        <div
                                            className="col text-end"
                                            onClick={() => changeActivityStatus(2)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-check-circle-fill"></i>
                                            </a>
                                        </div>
                                        <div
                                            className="col text-end"
                                            onClick={() => setActivityStatusCancelModal(false)}
                                        >
                                            <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                <i className="bi bi-x-circle-fill"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={activityEditModal}
                            onHide={() => setActivityEditModal(false)}
                            
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_edit")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card px-2">
                                <form onSubmit={(e) => {
                                            e.preventDefault();

                                            formData.append("id", activityId);
                                            formData.append("activity_title", activityTitle);
                                            formData.append("description", activityDescription);
                                            formData.append("comment", activityComment);
                                            formData.append("meta_description", metaDescription);
                                            formData.append("meta_keywords", JSON.stringify(metaKeywords));
                                            formData.append("place_id", activityPlace);
                                            formData.append("start_time", moment(activityStartTime).format("DD.MM.YYYY HH:mm"));
                                            formData.append("end_time", moment(activityEndTime).format("DD.MM.YYYY HH:mm"));
                                            formData.append("ip_address", ip);
                                            formData.append("device_name", device);
                                            formData.append("utc", utcLog);
                                            formData.append("userId", userId);
                                    
                                            HTTP.post("/activities/update", formData)
                                                .then((res) => {
                                                   
                                                    if (res.data.result) {
                                                        
                                                            setActivityEditModal(false);
                                                            window.location.reload();
                                                        
                                                    } else {
                                                        setLoading(true);
                                                    }
                                                })
                                                .catch(setLoading(true));
                                            
                                            
                                        }} >
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("activity_title")}</label>
                                                <input type="text" value={activityTitle} onChange={(e) => setActivityTitle(e.target.value)} className="form-control" />
                                            </div>

                                            <div className="form-group mt-1">
                                                <label className="form-control-label text-muted">{_("place")} ( id )</label>
                                                <input type="number" value={activityPlace} onChange={(e) => setActivityPlace(e.target.value)} className="form-control bg-white" />
                                            </div>

                                            <div className="row mt-2 text-center">

                                            <div className="form-group col-6">
                                                <div className="text-start">

                                                <label className="form-control-label text-muted">{_("start_time")}</label>
                                                </div>
                                                <div>
                                               
                                                <DatePicker
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={5}
                                                dateFormat="dd.MM.yyyy / HH:mm"
                                                className="form-control text-start"
                                                selected={activityStartTime} 
                                                onChange={(e) => setActivityStartTime(e)} 
                                                locale={getLocale(lang)}
                                                timeCaption={_("time")}                                                
                                                />
                                                </div>
                                            </div>
                                            <div className="form-group col-6">
                                                <div className="text-start">
                                                    <label className="form-control-label text-muted">{_("end_time")}</label>
                                                </div>
                                                <div>
                                                    <DatePicker
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    dateFormat="dd.MM.yyyy / HH:mm"
                                                    className="form-control  text-start"
                                                    selected={activityEndTime} 
                                                    onChange={(e) => setActivityEndTime(e)} 
                                                    locale={getLocale(lang)}
                                                    timeCaption={_("time")}  
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("description")}</label>
                                                <textarea rows="4" cols="50" value={activityDescription} onChange={(e) => setActivityDescription(e.target.value)}></textarea>
                                            </div>
                                            {/* <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("editor_comment")}</label>
                                                <textarea rows="4" cols="50" value={activityComment} onChange={(e) => setActivityComment(e.target.value)}></textarea>
                                            </div> */}
                                            <div className="row justify-content-center my-3 px-3">
                                                <button className="text-decoration-none btn btn-primary cardButton col-6" type="submit">{_("send")}</button>
                                            </div>
                                        </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={editorModal}
                            onHide={() => setEditorModal(false)}
                            
                        >
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("activity_packages")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-sm-12 card px-2">
                                    <div className="form-group mb-2 mt-2">
                                        <label className="form-control-label text-muted">{_("editor_comment")}</label>
                                        <textarea rows="4" cols="50" value={activityComment} onChange={(e) => setActivityComment(e.target.value.trimStart())}></textarea>
                                    </div>
                                    <div className="form-group mb-2 mt-2">
                                        <label className="form-control-label text-muted">{_("meta_description")}</label>
                                        <textarea rows="4" cols="50" value={metaDescription} onChange={(e) => setMetaDescripton(e.target.value.trimStart())}></textarea>
                                    </div>
                                    <div className="col-sm-12">
                                        <label className="form-control-label text-muted">{_("meta_keyword")}</label>
                                        <div className="input-group">
                                            <input type="text" value={metaTag.trim()} onChange={(e) =>setMetaTag(e.target.value)} className="form-control text-muted bg-white" />
                                            <button  onClick={() => saveMetaTag()} className="btn btn-secondary text-center bg-secondary">{_('add')}</button>
                                        </div>
                                        <div className="row grid gap-2 py-2 d-flex justify-content-center">
                                            {tagList()}
                                        </div>
                                    </div>
                                    <div onClick={()=>sendEditorPanel()} className="row justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton col-6">{_("send")}</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={selectCategoryModal} onHide={() => setSelectCategoryModal(false)}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                            <span className="text-center homeText">{_('category_info')}</span>
                        </Modal.Header>
                        <Modal.Body >
                        <div className="justify-content-center align-items-center mb-3"> 
                        <div className="text-center homeText">{_('category')}</div>  
                        <span className="text-center multi-line-text-truncate">
                            {selectCategory.name}
                            {token && (selectCategory.total_tickets === selectCategory.solded_tickets) && (
                                <span style={{color:'#c7c3ca'}} className="ms-1 text-muted">({_("sold_out")})</span>
                            )}
                        </span> 
                        <div style={{ color: "#000" }}>
                            <hr style={{ height: 1 }} />
                        </div>
                        <div className="text-center homeText">{_('type')}</div> 
                                {selectCategory?.prices?.map((ind)=> {
                                    return (
                                        <div className="row">
                                            <div className="col-6 d-flex justify-content-center align-items-center">
                                                <span className="text-center text-muted" >{ind.name}</span>
                                            </div>
                                            <div className="col-6 d-flex justify-content-center align-items-center">
                                                <span className="text-center text-muted" >{ind.price} TRY</span>
                                            </div>
                                        </div>
                                    );
                                })}
                           
                        </div>
                       
                        <div className="row justify-content-center px-5 mb-3">
                            <a
                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-8 col-md-4"
                                href={globals.url + "a/ticketing/" + number}
                            >
                                {_("buy_now")}
                            </a>
                        </div>
                       
                       
                        </Modal.Body>
                    </Modal>

<Modal
    show={editCategoryModal}
    onHide={() => setEditCategoryModal(false)}
    size="lg"
>
    <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 16, color: "#933CAC", fontWeight: "bold" }}>
            {_("category_sorting")}
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="">
            <div className="mb-3">
                <span className="fs-6" style={{ color: "#933CAC" }}>{_("sort_by_price")}</span>
                <div style={{ display: "flex", gap: "20px" }}>
                    <div className="text-center">
                        <input
                            type="radio"
                            id="priceDesc"
                            name="sortOrder"
                            value="priceDesc"
                            className="custom-checkbox"
                            checked={categoriesOrder[0] === 0}
                            onChange={() => handleChangeSortOrder(1,0)}
                        />
                        <label htmlFor="priceDesc" style={{ fontSize: 12 }}>
                            {_("according_to_created")}
                        </label>
                    </div>
                    <div className="text-center">
                        <input
                            type="radio"
                            id="priceDesc"
                            name="sortOrder"
                            value="priceDesc"
                            className="custom-checkbox"
                            checked={categoriesOrder[0] === 1}
                            onChange={() => handleChangeSortOrder(1,1)}
                        />
                        <label htmlFor="priceDesc" style={{ fontSize: 12 }}>
                            {_("sort_price_desc")}
                        </label>
                    </div>
                    <div className="text-center">
                        <input
                            type="radio"
                            id="priceAsc"
                            name="sortOrder"
                            value="priceAsc"
                            className="custom-checkbox"
                            checked={categoriesOrder[0] === 2}
                            onChange={() => handleChangeSortOrder(1,2)}
                        />
                        <label htmlFor="priceAsc" style={{ fontSize: 12 }}>
                            {_("sort_price_asc")}
                        </label>
                    </div>
                    <div className="text-center">
                        <input
                            type="radio"
                            id="nameAsc"
                            name="sortOrder"
                            value="nameAsc"
                            className="custom-checkbox"
                            checked={categoriesOrder[0] === 3}
                            onChange={() => handleChangeSortOrder(1,3)}
                        />
                        <label htmlFor="nameAsc" style={{ fontSize: 12 }}>
                            {_("sort_name_asc")}
                        </label>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <span className="fs-6" style={{ color: "#933CAC" }}>{_("sort_by_sold_out")}</span>
                <div style={{ display: "flex", gap: "20px" }}>
                <div className="text-center">
                        <input
                            type="radio"
                            id="soldOutBottom"
                            name="soldOutPosition"
                            value="bottom"
                            className="custom-checkbox"
                            checked={categoriesOrder[1] === 0}
                            onChange={() => handleChangeSortOrder(2,0)}
                        />
                        <label htmlFor="soldOutBottom" style={{ fontSize: 12 }}>
                            {_("fixed")}
                        </label>
                    </div>
                    
                    <div className="text-center">
                        <input
                            type="radio"
                            id="soldOutBottom"
                            name="soldOutPosition"
                            value="bottom"
                            className="custom-checkbox"
                            checked={categoriesOrder[1] === 1}
                            onChange={() => handleChangeSortOrder(2,1)}
                        />
                        <label htmlFor="soldOutBottom" style={{ fontSize: 12 }}>
                            {_("sold_out_bottom")}
                        </label>
                    </div>
                    <div className="text-center">
                        <input
                            type="radio"
                            id="soldOutTop"
                            name="soldOutPosition"
                            value="top"
                            className="custom-checkbox"
                            checked={categoriesOrder[1] === 2}
                            onChange={() => handleChangeSortOrder(2,2)}
                        />
                        <label htmlFor="soldOutTop" style={{ fontSize: 12 }}>
                            {_("sold_out_top")}
                        </label>
                    </div>
                </div>
            </div>

            {/* Kategorileri sıralama */}
            {sortPriceList(priceList)
                .map((item) => {
                    return (
                        <div key={item.id} className="card p-1 my-1">
                            <div
                               
                                className="row"
                            >
                                <div className="col-md-4 d-flex justify-content-center justify-content-md-start align-items-center">
                                <span className="text-start multi-line-text-truncate">
                                                        {item.name}
                                                        {token && (item.total_tickets === item.solded_tickets) && (
                                                            <span style={{color:'#c7c3ca'}} className="ms-1 text-muted">({_("sold_out")})</span>
                                                        )}
                                                    </span>
                                </div>
                                <div
                                    className="d-block d-md-none"
                                    style={{ color: "#933CAC" }}
                                >
                                    <hr style={{ height: 2 }} />
                                </div>

                                <div className="col-md-8">
                                    {item.prices.map((ind, i) => (
                                        <div key={ind.id} className="row">
                                            <div className="col-6 d-flex justify-content-center justify-content-md-start align-items-center">
                                                <span
                                                    className="text-start multi-line-text-truncate"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {ind.name}
                                                </span>
                                            </div>
                                            <div className="col-6 d-flex justify-content-center align-items-center">
                                                <span
                                                    className="text-center multi-line-text-truncate"
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {ind.price} TRY
                                                </span>
                                            </div>
                                            {i < item.prices.length - 1 && (
                                                <div style={{ color: "#000" }}>
                                                    <hr style={{ height: 1 }} />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="row justify-content-center px-5 mb-3">
                            <a
                                className="text-decoration-none btn btn-primary cardButton rounded-2 col-6 mt-3"
                                onClick={()=>updateCategories()}
                            >
                                {_("save")}
                            </a>
                        </div>
        </div>
    </Modal.Body>
</Modal>
                    </div>
                    <Footer />
                </>
         
        </div>
    );
};

export default ActivityDetail;