import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const Records = ({ setUser }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [username, setusername] = useState("")
    const [password, setPassword] = useState("")
    const [phone, setPhone] = useState("")
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)

    useEffect(() => {
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Records Page'
            },
            event: 'records_page'
        })
    }, [])

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("ticket_records")}</title>
                <meta name="description" content={_("meta_content_11")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
                <div className="card-body d-flex row text-center mb-3">
                    <h1 className="title fs-5 mt-1">{_('records')}</h1>
                </div>
                    <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                        <div className="card card1 col">
                            <div className="row justify-content-start">
                                <div className="col-md-8 col-10">
                                    <div className="card-body d-flex row">
                                        <h1 className="fs-6">{_('no_records')}</h1>
                                    </div>
                                    {/* <div className="card-body d-flex col">
                                        <h6 className="text-muted me-3">01 Ocak 2024</h6>
                                        <h6 className="">Giriş yapıldı.</h6>
                                    </div>
                                    <div className="card-body d-flex col">
                                        <h6 className="text-muted me-3">01 Ocak 2024</h6>
                                        <h6 className="">Giriş yapıldı.</h6>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default Records