import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import "react-phone-number-input/style.css";
import { useState, useEffect, useRef } from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Modal from 'react-bootstrap/Modal';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import ShowMoreText from "react-show-more-text";
import Linkify from 'linkify-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { QRCode } from 'react-qrcode-logo';
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import DataTable from 'react-data-table-component';
import { saveAs } from 'file-saver';

const ActivityReport = () => {
    const [loading, setLoading] = useState(false);
    const [activityName, setActivityName] = useState("");
    const [activityCategoryName, setActivityCategoryName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");
    const [description, setDescription] = useState("");
    const [number, setNumber] = useState();
    const [category, setCategory] = useState("null");
    const [seatCategory, setSeatCategory] = useState("null");
    const [activityId, setActivityId] = useState("");
    const [activityPhoto, setActivityPhoto] = useState("");
    const [token, setToken] = useState("");
    const [hall, setHall] = useState("");
    const [seat, setSeat] = useState("");
    const [seatName, setSeatName] = useState("");
    const [groupId, setGroupId] = useState("");
    const [seatId, setSeatId] = useState("");
    const [price, setPrice] = useState("");
    const [ticket, setTicket] = useState("");
    const [priceCode, setPriceCode] = useState("");
    const [utc, setUTC] = useState("")
    const [counter, setCounter] = useState("");
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [showSaloonModal, setShowSaloonModal] = useState(false);
    const [deleteCartModal, setDeleteCartModal] = useState(false);
    const [deleteTicketModal, setDeleteTicketModal] = useState(false);
    const [deleteTicketId, setDeleteTicketId] = useState(false)
    const [imgCreated, setImgCreated] = useState("")
    const [planURL, setPlanURL] = useState("")
    const [activity, setActivity] = useState("")
    const [payTicketNumber, setPayTicketNumber] = useState((activity?.hall?.groups[0]?.total_tickets) - (activity?.hall?.groups[0]?.solded_tickets))
    const [place, setPlace] = useState("")
    const [extra, setExtra] = useState("")
    const [soldTickets, setSoldTickets] = useState([])
    const [unsoldTickets, setUnsoldTickets] = useState([])
    const [soldModal, setSoldModal] = useState(false);
    const [unsoldModal, setUnsoldModal] = useState(false);
    const [urlModal, setUrlModal] = useState(false)
    const [copy, setCopy] = useState(false)
    const [startDateDay, setStartDateDay] = useState("")
    const [endDateDay, setEndDateDay] = useState("")
    const [showQrModal, setShowQrModal] = useState(false)
    const [qrURL, setQrUrl] = useState("")
    const [cityName, setCityName] = useState("")
    const [reserveModal, setReserveModal] = useState(false)
    const [invitationModal, setInvitationModal] = useState(false)
    const qrCodeRef = useRef(null); 
    const [reserveTickets, setReserveTickets] = useState([])
    const [invitationTickets, setInvitationTickets] = useState ([])

    var totalTicket = 0;
    var soldedTicket = 0;


    const handleDownload = () => {
        const canvas = qrCodeRef.current.querySelector('canvas');
        if (!canvas) return;
      
        // Burada number değişkenini kullanıyoruz
        const fileName = `qr-code-${number}.png`;  
      
        // Yeni bir canvas oluşturuyoruz
        const rotatedCanvas = document.createElement('canvas');
        const ctx = rotatedCanvas.getContext('2d');
      
        // Orijinal canvas boyutlarının çapraz uzunluğunu hesaplıyoruz
        const size = Math.sqrt(Math.pow(canvas.width, 2) + Math.pow(canvas.height, 2));
        rotatedCanvas.width = size;
        rotatedCanvas.height = size;
      
        // Çizim bağlamını (context) döndürüp, merkezi olarak yerleştiriyoruz
        ctx.translate(rotatedCanvas.width / 2, rotatedCanvas.height / 2);
        ctx.rotate(45 * Math.PI / 180); // 45 derece döndürme
      
        // Orijinal canvas'ı yeni canvas'a yerleştiriyoruz
        ctx.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);
      
        // Döndürülen canvas'ı PNG olarak indiriyoruz
        rotatedCanvas.toBlob((blob) => {
          saveAs(blob, fileName); 
        });
      };
      
    

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser.result) {
            setToken(getuser.token);
        }
        setLoading(true);
        setNumber(window.location.href.split("/")[5]);
        setQrUrl(globals.url + 'a/' + number)
        if (number) {
            HTTP.get(`/activities/get/${number}`, { headers: { Authorization: `bearer ${getuser.token}` }, })
                .then((res) => {
                    if (res.data.result) {
                        setCityName(res.data.activity.city.name)
                        setActivityName(res.data.activity.title);
                        setStartDate(moment(res.data.activity.start_time).format("DD.MM.YYYY"));
                        setStartTime(moment(res.data.activity.start_time).format("HH:mm"));
                        setEndDate(moment(res.data.activity.end_time).format("DD.MM.YYYY"));
                        setEndTime(moment(res.data.activity.end_time).format("HH:mm"));
                        setDescription(res.data.activity.description);
                        setActivityCategoryName(res.data.activity.category.name);
                        setActivityId(res.data.activity.id);
                        setActivityPhoto(res.data.activity.photo_name);
                        setPriceCode(res.data.activity.currency.tcmb_code)
                        setToken();
                        setHall(res.data.activity.hall);
                        setUTC(res.data.activity.utc);
                        setImgCreated(res.data.activity.created_at)
                        setPlanURL(res.data.activity.hall.plan_file_url)
                        setActivity(res.data.activity)
                        setPayTicketNumber((res.data.activity?.hall?.groups[0]?.total_tickets) - (res.data.activity?.hall?.groups[0]?.solded_tickets))
                        setPlace(res.data.activity.place.title)
                        setExtra(res.data.activity.extra)
                        setStartDateDay(moment(res.data.activity.start_time).format('dddd').toLocaleLowerCase())
                        setEndDateDay(moment(res.data.activity.end_time).format('dddd').toLocaleLowerCase())

                        HTTP.get(`getSeatsAll/${res.data.activity.id}`, { headers: { Authorization: `bearer ${getuser.token}` }, })
                            .then((res) => {
                                if (res.data.result) {
                                    setReserveTickets(res.data.reservedTickets)
                                    setInvitationTickets(res.data.inviteTickets)
                                    setSoldTickets(res.data.soldTickets)
                                    setUnsoldTickets(res.data.unsoldTickets)
                                    setLoading(false);
                                } else {
                                    setLoading(false);
                                }
                            })
                            .catch();
                    } else {
                        setLoading(false);
                        window.location.href = globals.url
                    }
                })
                .catch(setLoading(true));
        }
    }, [number]);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser.result) {
            setToken(getuser.token);
        }
        setLoading(true);
        HTTP.get("/getCart", { headers: { Authorization: `bearer ${getuser.token}` } })
            .then((res) => {
                if (res.data.cart) {
                    setTicket(res.data.cart.tickets)
                    setCounter(res.data.cart.cartTime)
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);

    useEffect(() => {
        ticket ? setTimeout(() => setCounter(counter - 1), 1000) : setCounter("")
    }, [counter]);

    const formattedUtc = (hour) => {
        if (hour == 0) {
            var result = 'UTC';
            return result;
        } else {
            if (hour < 0) {
                var signal = 'UTC - ';
                var hour = hour * -1;
            } else {
                var signal = 'UTC + ';
            }

            if (hour < 10) {
                var formatHour = '0' + parseInt(hour);
            } else {
                var formatHour = parseInt(hour);
            }

            if (hour % 1 > 0) {
                var formatMinute = (hour % 1 * 60);
            } else {
                var formatMinute = '00';
            }

            result = signal + formatHour + ':' + formatMinute;
            return result;
        }
    }

    const linkProps = {
        onClick: (event) => {
            event.preventDefault()
            //  window.open(extra)
            setCopy(false)
            setUrlModal(true)
        }
    };

    const urlCopy = () => {
        setCopy(true)
        toast(_('copied'), {
            position: "top-right",
            autoClose: 3000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false
        })
        setUrlModal(false)
    }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Activity Report Page'
            },
            event: 'activity_report_page'
        })
    }, [])
    const totalPrice = soldTickets.reduce((total, event) => {
        const eventTotal = event.seats.reduce((eventTotal, seat) => eventTotal + seat.price, 0);
        return total + eventTotal;
    }, 0);

    const soldColumns = [
        {
          name: `${_("order") + "-" +  _("armchair")}`,
          selector: row => row.row_label ?`${row.row_label} - ${row.name}`
          :`${row.name}`,
          width: "100px",
          wrap: true
        },
        {
          name: _("ticket_no"),
          selector: row => row.ticket_number,
          width: "170px",
          wrap: true
        },
        {
          name: _("name_on_ticket"),
          selector: row => row.name_on_ticket,
          width: "160px",
          wrap: true
        },
        {
          name: _("fee"),
          selector: row => row.price,
          width: "100px",
          wrap: true,
          cell: row => row.price>0?`${row.price} TRY`:_('invitation'),
          
        },
      ];
    
      // Flattened and filtered data for the DataTable
        const soldData = soldTickets.flatMap(res =>
            res.seats
            .filter(seat => seat.is_solded)
            .map(seat => ({
                ...seat,
                row_label: seat.row ? seat.row.row_label : '',  // Ensure row_label is available
                name: seat.name,
                ticket_number: seat.ticket_number,
                name_on_ticket: seat.name_on_ticket,
                price: seat.price,
            }))
        );

      const reserveColumns = [
        {
          name: `${_("order") + "-" + _("armchair")}`,
          selector: row => row.seat_name,  
          width: "120px",
          wrap: true
        },
        {
          name: _("phone"),
          selector: row => row.phone,  
          width: "150px",
          wrap: true
        },
        {
          name: _("e_mail"),
          selector: row => row.email,  
          width: "200px",
          wrap: true
        },
        {
        name: _("username"),
        selector: row => row.user_name,  
        width: "150px",
        wrap: true
        }
      ];
      

      const prepareReserveTickets = (tickets) => {
        return tickets.flatMap(ticket =>
          ticket.seats.map(seat => ({
            seat_name: `${seat.row?.row_label} - ${seat.name}`,  // Koltuk ismi
            phone: seat.reservation_info?.user_phone,  // Koltukla ilgili telefon bilgisi
            email: seat.reservation_info?.user_email,  // Koltukla ilgili email bilgisi
            user_name: seat.reservation_info?.user_name  // Koltukla ilgili user_name bilgisi
          }))
        );
      };
      
      
    

      const invitationColumns = [
        {
          name: `${_("order") + "-" + _("armchair")}`,
          selector: row => row.seat_name,  // Combine row_label and seat name
          width: "120px",
          wrap: true
        },
        {
          name: _("ticket_no"),
          selector: row => row.ticket_number,  // Ticket number
          width: "180px",
          wrap: true
        },
        {
          name: _("name_on_ticket"),
          selector: row => row.name_on_ticket,  // Name on ticket
          width: "180px",
          wrap: true
        },
        {
          name: _("fee"),
          selector: row => row.price,  // Price
          width: "100px",
          wrap: true,
          cell: row => row.price > 0 ? `${row.price} TRY` : _('invitation') 
        }
      ];

      const prepareInviteTickets = (tickets) => {
        return tickets.flatMap(ticket =>
          ticket.seats.map(seat => ({
            seat_name: seat.row?.row_label 
              ? `${seat.row.row_label} - ${seat.name}` 
              : seat.name,  // Combine row_label and seat name if available
            row_label: seat.row?.row_label || '',  // Row label if available, otherwise empty string
            seat_name_raw: seat.name,  // Raw seat name
            ticket_number: seat.ticket_number,  // Ticket number
            name_on_ticket: seat.name_on_ticket,  // Name on ticket
            price: seat.price,  // Price of the ticket
          }))
        );
      };
      

    

    return (
        <div>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5 px-4 mt-5">
    
                        <div className="row flex-column flex-lg-row ">
                            <div className="col-12 card-back p-1 rounded-2">
                                         <div className="card p-2">
                                           <div className="row g-1 align-items-center justify-content-center">
                                             <div className="col-4 d-flex justify-content-center align-items-center">
                                               {activityPhoto && (
                                                 <img
                                                   src={`${
                                                     globals.imgUrl
                                                   }${activityId}/thumb/${activityPhoto}.png?${new Date(
                                                     imgCreated
                                                   ).getTime()}`}
                                                   className="rounded-2 img-fluid activity-photo"
                                                   alt="activity photo"
                                                 />
                                               )}
                                             </div>
                                             <div className="col-8 d-flex flex-column align-items-center">
                                               <div className="text-center">
                                                 <h3
                                                   className="mb-1"
                                                   style={{ color: "#2E81D2", fontSize: 16 }}
                                                 >
                                                   {activityName}
                                                 </h3>
                                                 <p className="text-muted mb-1" style={{ fontSize: 12 }}>
                                                   {_(activityCategoryName)}
                                                 </p>
                                               </div>
                           
                                               <div className="d-flex justify-content-center w-100">
                                                 <div className="text-center me-3">
                                                   <h4
                                                     className="mb-0"
                                                     style={{ fontSize: 11, fontWeight: 500 }}
                                                   >
                                                     {_("start_time")}
                                                   </h4>
                                                   <span className="text-muted" style={{ fontSize: 11 }}>
                                                     {startDate} - {startTime}
                                                   </span>
                                                   <div className="text-muted" style={{ fontSize: 11 }}>
                                                     {_(startDateDay)}
                                                   </div>
                                                 </div>
                                                 <div className="text-center">
                                                   <h4
                                                     className="mb-0"
                                                     style={{ fontSize: 11, fontWeight: 500 }}
                                                   >
                                                     {_("end_time")}
                                                   </h4>
                                                   <span className="text-muted" style={{ fontSize: 11 }}>
                                                     {endDate} - {endTime}
                                                   </span>
                                                   <div className="text-muted" style={{ fontSize: 11 }}>
                                                     {_(endDateDay)}
                                                   </div>
                                                 </div>
                                               </div>
                           
                                               <div className="text-center mt-1">
                                                 <h4
                                                   className="mb-0"
                                                   style={{ fontSize: 11, fontWeight: 500 }}
                                                 >
                                                   {_("place")}
                                                 </h4>
                                                 <span className="text-muted" style={{ fontSize: 11 }}>
                                                   {cityName}, {place}
                                                 </span>
                                               </div>
                                             </div>
                                           </div>
                                           <div className="text-center mt-1">
                                             <small className="text-muted" style={{ fontSize: 10 }}>
                                               <span
                                                 className="bi bi-info-circle"
                                                 style={{ color: "#933CAC" }}
                                               />{" "}
                                               {_("activity_detail_info").replace(
                                                 "%s",
                                                 `https://vidipass.com/a/${number}`
                                               )}
                                             </small>
                                           </div>
                                      
                                    <div onClick={() => setShowQrModal(true)} className="d-flex justify-content-center mt-3">
                                        <div style={{ padding: 35 }} className="border rounded">
                                            <div style={{ transform: 'rotate(45deg)' }}>
                                                <QRCode value={qrURL}
                                                    qrStyle="dots"
                                                    eyeColor={['#D31095', '#2E81D2', '#933CAC']}
                                                    logoImage={images.vidipass_qr_icon}
                                                    quietZone={0}
                                                    size={120}
                                                    logoWidth={25}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-center text-muted">vidipass.com/a/{number}</p>
                                </div>
                            </div>                     

                            <div className="col-sm-12 card p-3 mt-2">
                                <div className="row">
                                    <h5 className="text-start homeText">{_("activity_report")}</h5>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("activity_code")}
                                    </span>
                                    <span className="text-start text-muted">{number}</span>
                                </div>
                                <div className="row mt-2">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("created_time")}</span>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{_("date")}</Th>
                                            <Th>{_("hour")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{moment(activity.created_at).format("DD.MM.YYYY")}</Td>
                                            <Td>{moment(activity.created_at).format("HH:mm")} <span style={{ fontSize: 11 }}>({formattedUtc(utc)})</span></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                                <div className="row mt-3">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("details")}</span>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{_("category")}</Th>
                                            <Th>{_("total_ticket")}</Th>
                                            <Th>{_("solded_ticket")}</Th>
                                            <Th>{_("rest_ticket")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {activity?.hall?.groups.map(val => {
                                            totalTicket += val.total_tickets;
                                            soldedTicket += val.solded_tickets
                                            return (<Tr>
                                                <Td>{val.name}</Td>
                                                <Td>{val.total_tickets}</Td>
                                                <Td>{val.solded_tickets}</Td>
                                                <Td>{val.total_tickets - val.solded_tickets}</Td>
                                            </Tr>
                                            )
                                        })
                                        }
                                    </Tbody>
                                </Table>
                                <div className="row mt-3">
                                    <span style={{ fontSize: 18 }} className="text-start homeText">{_("total_ticket")}</span>
                                </div>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{_("total_ticket")}</Th>
                                            <Th>{_("solded_ticket")}</Th>
                                            <Th>{_("reservation")}</Th>
                                            <Th>{_("invitation")}</Th>
                                            <Th>{_("total_piece")}</Th>
                                            <Th>{_("rest_ticket")}</Th>
                                            <Th>{_("total_sale")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>{totalTicket}</Td>
                                            <Td>{soldData.length}</Td>
                                            <Td>{reserveTickets.reduce((acc, group) => acc + group.seats.length, 0)}</Td>
                                            <Td>{invitationTickets.reduce((acc, group) => acc + group.seats.length, 0)}</Td>
                                            <Td>{soldedTicket}</Td>
                                            <Td>{totalTicket - soldedTicket}</Td>
                                            
                                            <Td>
                                            {totalPrice % 1 !== 0 ? `${totalPrice.toFixed(2)} TRY` : `${totalPrice} TRY`}
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>

                                <div className="col-sm-12 card p-3 mt-2">
                                <div className="text-center row g-1">
    <div className="col-6 col-md-3">
        <a onClick={() => setSoldModal(true)} className="text-decoration-none btn btn-primary saloonButton rounded-2 w-100">
            {_("sold_seats")}
        </a>
    </div>
    <div className="col-6 col-md-3">
        <a onClick={() => setUnsoldModal(true)} className="text-decoration-none btn btn-primary saloonButton rounded-2 w-100">
            {_("rest_seats")}
        </a>
    </div>
    <div className="col-6 col-md-3">
        <a onClick={() => setReserveModal(true)} className="text-decoration-none btn btn-primary saloonButton rounded-2 w-100">
            {_("reservation")}
        </a>
    </div>
    <div className="col-6 col-md-3">
        <a onClick={() => setInvitationModal(true)} className="text-decoration-none btn btn-primary saloonButton rounded-2 w-100">
            {_("invitation")}
        </a>
    </div>
</div>

</div>
                            </div>
                        </div>
                    </div>
                    {/* <Modal show={soldModal} onHide={() => setSoldModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("sold_seats")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                            {soldTickets.map(function (res) {    
                                           
    return (
//         <div className="mb-3" key={res.id}>
//         <p className="homeText">{res.name}</p>
//         <div className="d-flex justify-content-center mb-2">
//             <div className="flex-fill text-center">
//                 <p className="mb-1" style={{ fontSize: 13 }}>{_("armchair")}</p>
//             </div>
//             <div className="flex-fill text-center">
//                 <p className="mb-1" style={{ fontSize: 13 }}>{_("ticket_no")}</p>
//             </div>
//             <div className="flex-fill text-center">
//                 <p className="mb-1" style={{ fontSize: 13 }}>{_("name_on_ticket")}</p>
//             </div>
//             <div className="flex-fill text-center">
//                 <p className="mb-1" style={{ fontSize: 13 }}>{_("fee")}</p>
//             </div>
//         </div>
//         <hr />
//         {res.seats.map(function (ind) {
//             if (ind.is_solded) {
//                 return (
     
//                     <div className="row align-items-center mb-2 text-center" key={ind.ticket_number}>
//     <div className="col-3 text-start" style={{ flex: '1 1 auto' }}>
//         <span style={{ fontSize: 12 }}>{ind.name}</span>
//     </div>
//     <div className="col-3 text-start" style={{ flex: '1 1 auto'}}>
//         <span style={{ fontSize: 12 }}>{ind.ticket_number}</span>
//     </div>
//     <div className="col-3 text-start" style={{ flex: '1 1 auto' }}>
//         <span style={{ fontSize: 12 }}>{ind.name_on_ticket}</span>
//     </div>
//     <div className="col-3 text-start" style={{ flex: '1 1 auto'}}>
//         <span style={{ fontSize: 12 }}>{ind.price} TRY</span>
//     </div>
// </div>


       
//                 );
//             }
//             return null;
//         })}
//         <hr />
//     </div>

<div className="mb-3" key={res.id}>
<p className="homeText">{res.name}</p>
<div className="row mb-2">
    
    <div className="col-3 text-center">
        <p style={{ fontSize: 10}}>{_("Sıra") + "-" + _("armchair")}</p>
    </div>
    <div className="col-4 text-center">
        <p style={{ fontSize: 10}}>{_("ticket_no")}</p>
    </div>
    <div className="col-3 text-center">
        <p style={{ fontSize: 10}}>{_("Bilet Sahibi")}</p>
    </div>
    <div className="col-2 text-center">
        <p style={{ fontSize: 10}}>{_("fee")}</p>
    </div>
   
</div>
<hr />
{res.seats.map(function (ind) {
    if (ind.is_solded) {
        return (
            <div className="row mb-2" key={ind.ticket_number}>
                  
                <div className="col-3">
                    <p style={{ fontSize: 10 }}>{ind?.row_label}-{ind.name}</p>
                </div>
                <div className="col-4">
                    <p style={{ fontSize: 10 }}>{ind.ticket_number}</p>
                </div>
                <div className="col-3">
                    <p style={{ fontSize: 10 }}>{ind.name_on_ticket}</p>
                </div>
                <div className="col-2">
                    <p style={{ fontSize: 10 }}>{ind.price} TRY</p>
                </div>
                
            </div>
        );
    }
    return null;
})}
<hr />
</div>
    
    
    );
})}

                            </Modal.Body>
                        </Modal> */}

<Modal show={soldModal} onHide={() => setSoldModal(false)} centered>
      <Modal.Header closeButton style={{ background: "#fff" }}>
        <Modal.Title style={{ fontSize: 16 }}>
          <span className="homeText">{_("sold_seats")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          columns={soldColumns}
          data={soldData}
          responsive     
          pagination={false} 
          noDataComponent={<span className="text-muted">{_("no_records")}</span>}
        />
      </Modal.Body>
    </Modal>
                        
    <Modal show={unsoldModal} onHide={() => setUnsoldModal(false)}>
    <Modal.Header closeButton style={{ background: "#fff" }}>
        <Modal.Title style={{ fontSize: 16 }}>
            <span className="homeText">{_("rest_seats")}</span>
        </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {unsoldTickets.map((val) =>
            val.map((res) => (
                <div className="row" key={res.id}>
                    <p className="homeText">{res.name}</p>
                    <hr />
                    <div className="row">
                        {res.seats
                            .filter((ind) => !(ind.is_active === 0 && ind.is_solded === null))
                            .map((ind) => (
                                !ind.is_solded && (
                                    <div className="col-4 text-center mb-2" key={ind.id}>
                                        <p
                                            className="badge bg-light text-dark"
                                            style={{
                                                color: "#000",
                                                fontWeight: "normal",
                                                fontSize: 14,
                                            }}
                                        >
                                            {ind.name}
                                        </p>
                                    </div>
                                )
                            ))}
                    </div>
                </div>
            ))
        )}
    </Modal.Body>
</Modal>

<Modal show={reserveModal} onHide={() => setReserveModal(false)} centered>
      <Modal.Header closeButton style={{ background: "#fff" }}>
        <Modal.Title style={{ fontSize: 16 }}>
          <span className="homeText">{_("reservation")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          columns={reserveColumns}
          data={prepareReserveTickets(reserveTickets)}
          responsive     
          pagination={false} 
          noDataComponent={<span className="text-muted">{_("no_records")}</span>}
        />
      </Modal.Body>
    </Modal>

    <Modal show={invitationModal} onHide={() => setInvitationModal(false)} centered>
      <Modal.Header closeButton style={{ background: "#fff" }}>
        <Modal.Title style={{ fontSize: 16 }}>
          <span className="homeText">{_("invitation")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          columns={invitationColumns}
          data= {prepareInviteTickets(invitationTickets)}
          responsive     
          pagination={false} 
          noDataComponent={<span className="text-muted">{_("no_records")}</span>}
        />
      </Modal.Body>
    </Modal>


                        <Modal show={urlModal} onHide={() => setUrlModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <Modal.Title style={{ fontSize: 16 }}>
                                    <span className="homeText">{_("online_adress")}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <div className="d-flex justify-content-center align-items-center px-2">
                                    <CopyToClipboard text={extra} onCopy={() => setCopy(true) + urlCopy()}>
                                        <div className="border rounded-2 p-2 d-flex me-2 d-flex justify-content-center"  >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="#933CAC"
                                                className="bi bi-copy"
                                                viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z" />
                                            </svg>
                                        </div>
                                    </CopyToClipboard>
                                    <a onClick={() => window.open(extra) + setUrlModal(false)} className="homeText d-flex text-decoration-none border rounded-2 p-2">{extra}</a>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={showQrModal} onHide={() => setShowQrModal(false)}>
                            <Modal.Header closeButton style={{ background: "#fff" }}>
                                <span className="text-center text-muted">vidipass.com/a/{number}</span>
                            </Modal.Header>
                            
                            <Modal.Body style={{ position: 'relative' }}>
                                <button 
                                onClick={handleDownload} 
                                className="btn btn-primary bi bi-download"
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    backgroundColor: '#933CAC',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    fontSize: '15px'
                                }}
                                />

                                <div 
                                style={{ transform: 'rotate(45deg)' }} 
                                className="col-12 d-flex justify-content-center align-items-center mt-5 mb-5"
                                ref={qrCodeRef} 
                                >
                                <QRCode 
                                    value={qrURL}
                                    qrStyle="dots"
                                    eyeColor={['#D31095', '#2E81D2', '#933CAC']}
                                    logoImage={images.vidipass_qr_icon}
                                    quietZone={0}
                                    size={200}
                                    logoWidth={40}
                                />
                                </div>
                            </Modal.Body>
                        </Modal>

                    <Footer />
                </>
        </div>
    );
};

export default ActivityReport;