import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import "react-phone-number-input/style.css";
import { useState, useEffect, useRef} from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import "react-medium-image-zoom/dist/styles.css";
import Modal from "react-bootstrap/Modal";
import * as Popover from "@radix-ui/react-popover";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { af, ar, az, bg, bs, cy, de, el, es, fi, fr, he, hi, hr, hu, it, ja, kk, ko, lb, mk, ms, nl, nn, pt, ro, ru, sq, sr, sv, tr, uk, zhTW, enUS } from 'date-fns/locale';
import { Cookies } from "react-cookie";

const Reservations = () => {
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState();
  const [category, setCategory] = useState("null");
  const [seatCategory, setSeatCategory] = useState("null");
  const [hall, setHall] = useState("");
  const [seat, setSeat] = useState("");
  const [priceCode, setPriceCode] = useState("");
  const HTTP = axios.create({ baseURL: globals.apiUrl });
  const [planURL, setPlanURL] = useState("");
  const divRef = useRef(null);
  const [term, setTerm] = useState(false);
  const [cancelInsuranceModal, setCancelInsuranceModal] = useState(false);
  const [serverTime, setServerTime] = useState("");
  const [activityStatus, setActivityStatus] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [seatChart, setSeatChart] = useState([]);
  const [groupCenters, setGroupCenters] = useState({}); // Grupların merkezlerini saklar
  const [prices, setPrices] = useState([]);
  const closeRef = useRef(null);
  const [reserveTime, setReserveTime] = useState("")
  const cookies = new Cookies();
  const [lang] = useState(cookies.get("language"));
  
  const [popoverOpened, setPopoverOpened] = useState(false);
  const popoverContentRef = useRef(null);
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [userType, setUserType] = useState("");
  const [discountRate, setDiscountRate] = useState(0);
  const [startTime, setStartTime] = useState("");
  useEffect(()=>{
    setUserType(JSON.parse(localStorage.getItem("auth")).user.type)
  },[])

  const getLocale = (lang) => {
    switch (lang) {
        case "af":
            return af;
        case "ar":
            return ar;
        case "az":
            return az;
        case "bg":
            return bg;
        case "bs":
            return bs;
        case "cy":
            return cy;
        case "de":
            return de;
        case "el":
            return el;
        case "es":
            return es;
        case "fi":
            return fi;
        case "fr":
            return fr;
        case "he":
            return he;
        case "hi":
            return hi;
        case "hr":
            return hr;
        case "hu":
            return hu;
        case "it":
            return it;
        case "ja":
            return ja;
        case "kk":
            return kk;
        case "ko":
            return ko;
        case "lb":
            return lb;
        case "mk":
            return mk;
        case "ms":
            return ms;
        case "nl":
            return nl;
        case "nn":
            return nn;
        case "pt":
            return pt;
        case "ro":
            return ro;
        case "ru":
            return ru;
        case "sq":
            return sq;
        case "sr":
            return sr;
        case "sv":
            return sv;
        case "tr":
            return tr;
        case "uk":
            return uk;
        case "tw":
            return zhTW;
        default:
            return enUS; 
    }
};

useEffect(() => {
  if (popoverOpened && popoverContentRef.current) {
    const popoverContent = popoverContentRef.current;

    // Önce en alta kaydır
    popoverContent.scrollTop = popoverContent.scrollHeight;

    // Animasyonlu şekilde yukarıya kaydır
    setTimeout(() => {
      if ('scrollBehavior' in document.documentElement.style) {
        popoverContent.scrollTo({
          top: 0,
          behavior: 'smooth' // Mobil ve destekleyen tarayıcılar için pürüzsüz kaydırma
        });
      } else {
        // Tarayıcı desteklemiyorsa fallback
        popoverContent.scrollTop = 0;
      }
    }, 100); // Gecikmeyi daha kısa tutabilirsiniz (mobilde hızlı yanıt için)
  }
}, [popoverOpened,popoverContentRef.current]);

  useEffect(() => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    setNumber(window.location.href.split("/")[5]);
    if (number) {
      HTTP.get(`/activities/get/${number}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setPriceCode(res.data.activity.currency.tcmb_code);
            setStartTime(res.data.activity.start_time);
            setHall(res.data.activity.hall);
            setSeatChart(res.data.activity.hall.row);
            setPlanURL(res.data.activity.seats_plan);
            setServerTime(res.data.time);
            setActivityStatus(res.data.activity.activity_status);
            setLoading(false);
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          } else {
            window.location.href = globals.url;
          }
        })
        .catch(setLoading(true));
    }
  }, [number]);

  const getGroupsByStatus = () => {
    const groups = {};

    seatChart.forEach((row, rowIndex) => {
      row.seat.forEach((seat, colIndex) => {
        const groupName = seat.group?.name || seat.status; // Eğer grubu varsa, grup ismi; yoksa status kullanacağız

        // Eğer grup daha önce tanımlanmadıysa, oluştur
        if (!groups[groupName]) {
          groups[groupName] = [];
        }

        // Grup içindeki koltukları ekle
        groups[groupName].push({ rowIndex, colIndex });
      });
    });

    return groups;
  };


  const calculateGroupCenter = (group) => {
    if (!group || group.length === 0) return { center: null, positionType: 4 };

    const rows = group.map((seat) => seat.rowIndex);
    const cols = group.map((seat) => seat.colIndex);

    const minRow = Math.min(...rows);
    const maxRow = Math.max(...rows);
    const minCol = Math.min(...cols);
    const maxCol = Math.max(...cols);

    const centerRow = (minRow + maxRow) / 2;
    const centerCol = (minCol + maxCol) / 2;

    const closestSeat = group.reduce((closest, seat) => {
      const distance = Math.sqrt(
        Math.pow(seat.rowIndex - centerRow, 2) +
          Math.pow(seat.colIndex - centerCol, 2)
      );
      return closest === null || distance < closest.distance
        ? { seat, distance }
        : closest;
    }, null);

    const positionType =
      Number.isInteger(centerRow) && Number.isInteger(centerCol)
        ? 1
        : Number.isInteger(centerRow)
        ? 2
        : Number.isInteger(centerCol)
        ? 3
        : 4;

    return { center: closestSeat?.seat || null, positionType };
  };

  const calculateAllGroupCenters = () => {
    const groups = getGroupsByStatus(); // Tüm grupları al
    const newGroupCenters = {};

    Object.keys(groups).forEach((groupName) => {
      newGroupCenters[groupName] = calculateGroupCenter(groups[groupName]); // Her grup için merkezi hesapla
    });

    setGroupCenters(newGroupCenters); // Merkezi gruplarla birlikte güncelle
  };

  useEffect(() => {
    calculateAllGroupCenters();
  }, [seatChart]);

  const getSeat = (categoryId) => {
    
    const myArray = categoryId.split(",");
    const mySeat = myArray[0];
    const myGroup = myArray[1];
    const myPrice = myArray[2];
    const myStatus = myArray[3];
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setSeatCategory(categoryId);
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0") {
      HTTP.get(`/activities/getUnsoldSeats/${myGroup}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setSeat(res.data.seats);
            setLoading(false);      
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  const saveSeat = (seat, related)=>{
    if (prices?.length === 1){
      const item = prices[0];
      const value = `${item.id},${item.group_id},${item.price},${item.status},${seat},${related && related}`;
      setSeatCategory(value);
    }
    
  }
  
  const getCart = () => {
    const myArray = seatCategory.split(",");
    const myPriceId = myArray[0];
    const myCategoryId = myArray[1];
    const myPrice = myArray[2];
    const myStatus = myArray[3];
    const mySeat = myArray[4];
    const myRelated = myArray[5];
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (seatCategory !== null) {
      HTTP.post(
        "/reservation",
        {
          seat_id: myRelated === "null" ? [mySeat] : [mySeat, myRelated],
          user_id: selectedUser.id ? selectedUser.id : null,
          discount_rate: discountRate,
          reserved_time: moment(reserveTime).format("DD.MM.YYYY HH:mm"),
        },
        { headers: { Authorization: `bearer ${getuser.token}` } }
      )
        .then((res) => {
          if (res.data.result) {
            // setCategory("null")
            setSeatCategory("null");
            getSeatChart(category);
            setLoading(false);
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  const cancelReserve = (seat, related) => {
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if (seat !== null) {
      HTTP.post(
        "/reservationCancel",
        {
          seat_id: seat === "null" ? [seat] : [seat, related],
        },
        { headers: { Authorization: `bearer ${getuser.token}` } }
      )
        .then((res) => {
          if (res.data.result) {
            // setCategory("null")
            setSeatCategory("null");
            getSeatChart(category);
            setLoading(false);
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    }
  };

  useEffect(
    () => {
      setTimeout(() => {
        if (!planURL){
          if (hall?.groups?.length < 2) {
            setCategory(0);
          }
          if (hall?.groups[0]?.prices?.length == 1) {
            const id = hall?.groups[0]?.prices[0]?.id;
            const group_id = hall?.groups[0]?.prices[0]?.group_id;
            const price = hall?.groups[0]?.prices[0]?.price;
            const status = hall?.groups[0]?.prices[0]?.status;
            getSeat(id + "," + group_id + "," + price + "," + status);
          }
        }
      }, 200);
    },
    [hall],
    divRef.current
  );

  const getSeatChart = (categoryId, status) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    setLoading(true);
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: divRef.current.offsetTop,
      });
    }, 200);

    if (categoryId !== "0" && status !== "standing") {
      HTTP.get(`/activities/getUnsoldSeatsChart/${categoryId}`, {
        headers: { Authorization: `bearer ${getuser.token}` },
      })
        .then((res) => {
          if (res.data.result) {
            setCategory(categoryId);
            setSeat(res.data.seats);
            setLoading(false);
            
            setTimeout(() => {
              window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop,
              });
            }, 200);
          }
        })
        .catch(setLoading(true));
    } else {
      setCategory(categoryId);
    }
  };

  useEffect(() => {
    if (!category || !hall?.groups) return;

    let filteredPrices = [];

    // hall.groups bir nesne mi dizi mi kontrol et
    if (Array.isArray(hall.groups)) {
      // Dizi ise find kullan
      const group = hall.groups.find((group) => group.id === category);
      filteredPrices = group?.prices || [];
    } else {
      // Nesne ise doğrudan erişim kullan
      filteredPrices = hall?.groups?.[category]?.prices || [];
    }

    setPrices(filteredPrices);
  }, [category, hall]);

  useEffect(() => {
    if (query.length >= 3) {
      fetchUsers(query);
    } else {
      setUsers([]);
      setShowDropdown(false);
    }
  }, [query]);

  const fetchUsers = async (searchTerm) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    if(getuser.user.type===2){
      try {
        const response = await HTTP.get(
          `/searchUserByOrganizator?query=${searchTerm}`,
          {
            headers: { Authorization: `bearer ${getuser.token}` },
          }
        );
        setUsers(response.data);
        setShowDropdown(response.data.length > 0);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
        setShowDropdown(false);
      }

    }else{
      try {
        const response = await HTTP.get(
          `/searchUser?query=${searchTerm}`,
          {
            headers: { Authorization: `bearer ${getuser.token}` },
          }
        );
        setUsers(response.data);
        setShowDropdown(response.data.length > 0);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
        setShowDropdown(false);
      }
    }
    
  };

  useEffect(()=>{
    if(query.length<1){
      setSelectedUser("")
    }
  },[query])


  const handleSelectUser = (user) => {
    setSelectedUser(user); // Seçilen kullanıcıyı dışarıya aktarma
    setQuery(user.username); // Inputu seçilen username ile güncelle
    setShowDropdown(false);
  };
  return (
    <div>
      {loading ? <Loader />
      :
      <>
        <Navbar />
        <div className="container mb-5 px-4 mt-5">
        <h3 className="homeText text-center">{_("reservation_operations")}</h3>
          
          <div
            style={{ marginBottom: isExpanded ? "360px" : "120px" }}
            className="row flex-column flex-lg-row"
          >
             <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    <div className="row">
                    <div className="col">
                    <label className="text-muted">{_("select_user")}</label>
                  <div className="search-container" style={{ position: "relative", width: "300px" }}>
      <input
        type="text"
        placeholder={userType === 2 ? _("username") : _("username") + ", " + _("phone") + ", " + _("e_mail")}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className="form-control"
       
      />
      {showDropdown && (
        <div
          className="dropdown"
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginTop: "5px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            zIndex: 10,
          }}
        >
          {users.map((user) => (
            <div
              key={user.id}
              onClick={() => handleSelectUser(user)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
              }}
            >
              {user.username && <div><strong className="homeText">{user.username}</strong></div>}
              {user.phone && <div>{user.phone}</div>}
              {user.email && <div>{user.email}</div>}
            </div>
          ))}
        </div>
      )}
    </div>
   

                    </div>
                   

                    </div>
                    
   
                  </div>
            </div>
            {serverTime > 0 &&
              activityStatus == 0 &&
              (planURL && (
                <div className="col-12 card-back p-1 rounded-2 mt-2">
                  <div className="card p-2">
                    {seatChart.length > 0 &&
                      (seat.length > 0 ? (
                        
                          <>
                           <span
                              onClick={() => window.location.reload()}
                              style={{ color: "#933CAC", cursor: "pointer" }}
                              className="d-flex justify-content-start align-items-center"
                            >
                              <span className="bi bi-arrow-left-square-fill fs-2" />
                             
                            </span>
                            <div className="d-flex flex-row gap-2 align-items-start my-1 flex-wrap">
                              {/* İlk Satır: Boş Küçük Modern Çerçeve */}
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box normal"></div>
                                <span className="modern-text">
                                  {_("standart_seat")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box disable"></div>
                                <span className="modern-text">
                                  {_("disable_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box rounded-left"></div>
                                <div className="modern-box rounded-right"></div>
                                <span className="modern-text">
                                  {_("double_seat")}
                                </span>
                              </div>

                              {/* İkinci Satır: Mor Küçük Modern Çerçeve */}
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box selectable"></div>
                                <span className="modern-text">
                                  {_("selectable_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box close"></div>
                                <span className="modern-text">
                                  {_("closed_seat")}
                                </span>
                              </div>

                             
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box sold"></div>
                                <span className="modern-text">
                                  {_("full_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box myBasket"></div>
                                <span className="modern-text">
                                  {_("my_basket_seat")}
                                </span>
                              </div>

                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box otherBasket"></div>
                                <span className="modern-text">
                                  {_("other_basket_seat")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-1">
                                <div className="modern-box reserved"></div>
                                <span className="modern-text">
                                  {_("reserved")}
                                </span>
                              </div>

                              {/* Üçüncü Satır: Yuvarlatılmış Çerçeveler */}
                            </div>
                            <div
                              id="seat-map"
                              className="seat-map border border-secondary-subtle p-2 rounded-2"
                            >
                              {seat.map((row, rowIndex) => (
                                <div key={rowIndex} className="seat-row">
                                  {row.row_label ? (
                                    <div className="seat-row-label">
                                      <span
                                        style={{
                                          border: "3px solid #a5a7a9",
                                          boxShadow:
                                            "0 0 10px 0 rgba(100,100,100,1)",
                                        }}
                                        className="seat-label rounded"
                                      >
                                        {row.row_label}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="seat-row-label" />
                                  )}

                                  {row?.seat.map((seat, colIndex) => {
                                    const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                    const isEmpty = seat.status === "empty";
                                    const isClose = seat.is_active === 0;
                                    const isBasket = seat.basket === "yourBasket"
                                    const isReserved = seat.is_reserve === 1

                                    const isClickable =
                                      seat.is_solded !== 1 && seat.basket === null
                                     

                                    const isSeat = seat.status === "seat";
                                    const isStanding =
                                      seat.status === "standing";
                                    return (
                                      <div
                                        style={{
                                          backgroundColor:
                                            isStage && seat.color,
                                        }}
                                      >
                                        <Popover.Root key={colIndex} onOpenChange={setPopoverOpened}>
                                          <Popover.Trigger asChild>
                                            <div
                                              className={`${isClickable ? "seat":"inactiveSeat"} ${
                                                isStage ? "stage-seat" : ""
                                              } ${
                                                seat.position_type === "left" &&
                                                "leftLoveSeat"
                                              } 
                                            ${
                                              seat.position_type === "right" &&
                                              "rightLoveSeat"
                                            } 
                                            ${
                                              seat.type === "disable" &&
                                              "disableSeat"
                                            }
                                            ${
                                              seat.type === "normal" &&
                                              "normalSeat"
                                            }
                                            ${
                                              isStanding &&
                                              isClose &&
                                              "closeSeat"
                                            }
                                            ${
                                              isStanding &&
                                              !isClose &&
                                              "normalSeat"
                                            }
                                            ${
                                              seat.is_solded === 0 &&
                                              "selectableSeatBack"
                                            }
                                            ${
                                              seat.is_solded === 1 &&
                                              "soldSeatBack"
                                            }

                                             ${
                                              (seat.is_active === 0 && isSeat) &&
                                              "closeSeatBack"
                                            }
                                            
                                            ${
                                              seat.basket === "yourBasket" &&
                                              "myBasketSeatBack"
                                            }
                                            ${
                                              seat.basket === "anotherBasket" &&
                                              "otherBasketSeatBack"
                                            }

                                            ${
                                              isReserved &&
                                              "reserveSeatBack"
                                            }
                                           
                                            `}
                                              style={{
                                                color: "#FFFFFF",
                                                letterSpacing: "1px",
                                                boxShadow: !isEmpty &&
                                                  "0 0 10px 0 rgba(100,100,100,1)",
                          
                                              }}
                                              onClick={() =>
                                                (isClickable && !isBasket)&&
                                                setIsExpanded(false) +
                                                setSeatCategory("null")+
                                                setTerm(false)+
                                                saveSeat(seat.id,seat.related_id && seat.related_id)
                                              } // Dummy onClick, asıl işlevi Popover kullanır
                                            >
                                              {seat.name && (
                                                <span
                                                  className={`seat-label d-flex justify-content-center`}
                                                >
                                                  {seat.name}
                                                </span>
                                              )}
                                            </div>
                                          </Popover.Trigger>

                                          {isClickable && (
                                            <Popover.Portal>
                                              <Popover.Content
                                                className="PopoverContent2 border border-primary-subtle"
                                                sideOffset={-20}
                                                style={{
                                                  position: "relative", // Popover.Content'in konumunu relative yapıyoruz ki close butonunu yerleştirebilelim
                                                }}
                                              >
                                                {
                                                  <div ref={popoverContentRef} className="scrollable-container mt-3">
                                                    <div
                                                    className="mx-1"
                                                   
                                                  >
                                                    
                                                     <span
                                                        className={`d-flex align-items-center justify-content-start homeText gap-1`}
                                                      >
                                                        <span className={seat.type==="disable"?"fas fa-wheelchair":seat.type==="love"?"fas fa-couch":"fas fa-chair"}/>
                                                        <span>{seat.name}</span>
                                                    </span>
                                                    {
                                                      isReserved ? 
                                                      <>
                                                      <span className="homeText text-muted mt-1">{_("this_seat_reserved")}</span>
                                                      <div className="row justify-content-center my-3 mx-1">
                                                                  <a
                                                                    onClick={()=>
                                                                      cancelReserve(seat.id,seat.related_id && seat.related_id)
                                                                    }
                                                                    className="text-decoration-none btn btn-primary cardButton rounded-2 col-9"
                                                                  >
                                                                    {_(
                                                                      "cancel"
                                                                    )}
                                                                  </a>
                                                                </div>
                                                                </>
                                                   :
                                                    <>
                                                    
                                                    <div className="card p-1"> 
                                                    <span className="homeText">
                                                      {_("ticket_type")}
                                                    </span>
                                                    <div className="col-sm-12 mb-3">
                                                      {seat?.group.prices?.map(
                                                        (item, i) => {
                                                          const value = `${
                                                            item.id
                                                          },${item.group_id},${
                                                            item.price
                                                          },${item.status},${
                                                            seat.id
                                                          },${
                                                            seat.related_id &&
                                                            seat.related_id
                                                          }`;
                                                          return (
                                                            <div
                                                              key={i}
                                                              className="form-check"
                                                            >
                                                              <input
                                                                type="radio"
                                                                className="priceInput form-check-input custom-radio"
                                                                id={`price-${i}`}
                                                                name="seatCategory"
                                                                value={value}
                                                                onChange={(e) =>
                                                                  setSeatCategory(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                }
                                                                checked={
                                                                  seatCategory===
                                                                  value
                                                                }
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor={`price-${i}`}
                                                              >
                                                                {item.name} -{" "}
                                                                {item.price}{" "}
                                                                {priceCode}
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>

                                                    </div>
                                                    

                                                    {seat &&
                                                      seatCategory !== "null" &&
                                                      category !== "null" && (
                                                        <>
                                                        <div className="card p-1 mt-1">
                                                          <span className="homeText">
                                                            {" "}
                                                            {_(
                                                              "last_reserve_time"
                                                            )}
                                                          </span>
                                                        
                                                          <div className="col-sm-12">
                                                        
                                               
                                                <div>
                                               
                                                <DatePicker
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={5}
                                                dateFormat="dd.MM.yyyy / HH:mm"
                                                className="form-control text-start"
                                                selected={reserveTime} 
                                                onChange={(e) => setReserveTime(e)}
                                                minDate={new Date()} // Minimum tarih olarak bugünü belirle
                                                maxDate={new Date(startTime)} // Maksimum tarih olarak 24.02.2025'i belirle
                                                locale={getLocale(lang)}
                                                timeCaption={_("time")}
                                                />
                                                </div>
                                            
                                                          </div>
                                                          </div>

                                                          {/* <div className="card p-1 mt-1">
                                                          <span className="homeText">
                                                            {" "}
                                                            {_(
                                                              "discount_rate"
                                                            )}
                                                          </span>
                                                        
                                                          <div className="form-group mt-1">
                                                <input type="number" value={discountRate} onChange={(e) => setDiscountRate(e.target.value)} className="form-control bg-white" />
                                            </div>
                                                          </div> */}

                                                         

                                                        
                                                          <div className="row justify-content-center my-3 mx-1">
                                                                  <a
                                                                    onClick={
                                                                      getCart
                                                                    }
                                                                    className="text-decoration-none btn btn-primary cardButton rounded-2 col-9"
                                                                  >
                                                                    {_(
                                                                      "save"
                                                                    )}
                                                                  </a>
                                                                </div>
                                                         
                                                        </>
                                                      )}
                                                      </>
                                                       }
                                                  </div>
                                                 
                                                  </div>
                                                }

                                                <Popover.Close
                                                  ref={closeRef}
                                                  className="bi bi-x-circle-fill PopoverClose"
                                                />
                                                <Popover.Arrow className="PopoverArrow" />
                                              </Popover.Content>
                                            </Popover.Portal>
                                          )}
                                        </Popover.Root>
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                            </div>

                           
                          </>
                        
                      ) : (
                        <>
                        <span className="d-flex justify-content-center homeText my-2">
                          
                          {_("select_category")}
                        </span>
                        <div id="seat-map" className="seat-map-ticket">
                          {seatChart.map((row, rowIndex) => (
                            <div key={rowIndex} className="seat-row">
                              {row.seat.map((seat, colIndex) => {
                                const isStage = seat.status === "stage"; // Koltuğun sahne olup olmadığını kontrol et
                                const isSeat = seat.status === "seat";
                                const isStanding = seat.status === "standing";
                                const isClickable =
                                  seat.status === "seat" ||
                                  seat.status === "standing";

                                // Center stage hesaplama
                                const groupCenter =
                                  groupCenters[seat.group?.name || seat.status];
                                const isGroupCenter =
                                  groupCenter &&
                                  groupCenter.center &&
                                  groupCenter.center.rowIndex === rowIndex &&
                                  groupCenter.center.colIndex === colIndex;
                                const positionType =
                                  groupCenter?.positionType || 4;
                                return (
                                  <div style={{ backgroundColor: seat.color }}>
                                    {isClickable ? (
                                      <div
                                        className={`chartSeat`}
                                        style={{
                                          backgroundColor: seat.color,
                                          border: "none",
                                          cursor: "default",
                                        }}
                                        onClick={() =>{
                                          isSeat && getSeatChart(
                                            seat.group_id,
                                            seat.status
                                          )
                                        }
                                          
                                        }
                                      >
                                        {isGroupCenter &&
                                          (isSeat || isStanding) && (
                                            <div
                                              className="rounded"
                                              style={{
                                                position: "absolute",
                                                top:
                                                  positionType === 1
                                                    ? "50%"
                                                    : positionType === 2
                                                    ? "50%"
                                                    : positionType === 3
                                                    ? "100%"
                                                    : "100%",
                                                left:
                                                  positionType === 1
                                                    ? "50%"
                                                    : positionType === 2
                                                    ? "100%"
                                                    : positionType === 3
                                                    ? "50%"
                                                    : "100%",
                                                transform:
                                                  "translate(-50%, -50%)",
                                                zIndex: 3,

                                                color: "#FFFFFF",
                                              }}
                                            >
                                              <span style={{ fontSize: 12 }}>
                                                {seat.group?.name}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      <div
                                        className={`chartSeat`}
                                        style={{
                                          cursor: "default",
                                          backgroundColor: seat.color,
                                        }}
                                      >
                                        {isStage && isGroupCenter && (
                                          <div
                                            className="rounded"
                                            style={{
                                              position: "absolute",
                                              top:
                                                positionType === 1
                                                  ? "50%"
                                                  : positionType === 2
                                                  ? "50%"
                                                  : positionType === 3
                                                  ? "100%"
                                                  : "100%",
                                              left:
                                                positionType === 1
                                                  ? "50%"
                                                  : positionType === 2
                                                  ? "100%"
                                                  : positionType === 3
                                                  ? "50%"
                                                  : "100%",
                                              transform:
                                                "translate(-50%, -50%)",
                                              zIndex: 3,

                                              color: "#FFFFFF",
                                            }}
                                          >
                                            <span style={{ fontSize: 12 }}>
                                              {_("stage")}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </div>
                        </>
                      ))}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <Footer />
      </>
      }
    </div>
  );
};

export default Reservations;
