import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from '../config/languages/i18n';
import { useDispatch } from "react-redux";
import { ProfileEditAction, ProfileEditEmailAction, ProfileEditPhoneAction } from "../redux/actions/auth";
import { Cookies } from "react-cookie";
import PhoneInput from 'react-phone-number-input';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from 'react-gtm-module'
import axios from "axios";
import globals from "../config/globals/globals";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const ProfileEdit = () => {
    const dispatch = useDispatch();
    const [user, setUser] = useState([]);
    const [oldusername, setoldusername] = useState("");
    const [newusername, setnewusername] = useState("");
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country_code, setCountry] = useState("TR");
    const [gender, setGender] = useState(0);
    const [updateEmailModal, setUpdateEmailModal] = useState(false)
    const [updatePhoneModal, setUpdatePhoneModal] = useState(false)
    const [birthDate, setBirthDate] = useState("")
    const [activatedMail, setActivatedMail] = useState('')
    const [extraMail, setExtraMail] = useState('')
    const [activatedModal, setActivatedModal] = useState(false)
    const [codeStart, setCodeStart] = useState("");
    const [codeEnd, setCodeEnd] = useState("");
    const [loading, setLoading] = useState(false);
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const formData = new FormData();
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(()=>{
        setDevice(navigator.userAgent)
    },[])

    useEffect(()=>{
        setUtc(new Date().getTimezoneOffset()/-60)
    },[])

    useEffect(()=>{
        HTTP.get('https://api.ipify.org?format=json')
        .then(
            res=>{
                setIp(res.data.ip)
            }
        )
    },[])

    useEffect(() => {
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem('auth'));
        if (getuser) {
            setUser(getuser.user);
            setoldusername(getuser.user.username);
            setnewusername(getuser.user.username);
            setEmail(getuser.user.email)
            setCountry(getuser.user.country_code);
            setPhone(getuser.user.phone);
            setGender(getuser.user.gender);
            setBirthDate(new Date(getuser.user.birth_date))
            setActivatedMail(getuser.user.email_extra)
        } else {
            window.location = '/i/start'
        }
    }, []);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const replaceUserNameCharacter = function (text) {
        if (text.length <= 3) {
            return text.replace(/[^-a-zA-Z\s+]+/ig, '').replace(/\s+/gi, "");
        } else {
            if (text.substring(0, 1) >= 0) {
                setnewusername('');
                return;
            }
            if (text.substring(1, 2) >= 0) {
                setnewusername('');
                return;
            }
            if (text.substring(2, 3) >= 0) {
                setnewusername('');
                return;
            }
            if ((text.substring(0, 8)).toLocaleLowerCase() == "actividi") {
                setnewusername('');
                return;
            }
            if ((text.substring(0, 8)).toLocaleLowerCase() == "vidipass") {
                setnewusername('');
                return;
            }
            return text.replace(/[^-a-zA-Z0-9\s+]+/ig, '').replace(/\s+/gi, "");
        }
    }

    const updateEmail = function (email) {
        setUpdateEmailModal(false)
        dispatch(ProfileEditEmailAction({ email: email }))
    }

    const updatePhone = function (phone, country_code) {
        setUpdatePhoneModal(false)
        dispatch(ProfileEditPhoneAction({ phone: phone, country_code: country_code }))
    }

    function deltaDate(input, days, months, years) {
        return new Date(
            input.getFullYear() + years,
            input.getMonth() + months,
            Math.min(
                input.getDate() + days,
                new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
            )
        );
    }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Profile Edit Page'
            },
            event: 'profile_edit_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    const handleCodeStart = (evt) => {
        if (evt.length < 3) {
            return setCodeStart(evt)
        }
    };

    const handleCodeEnd = (evt) => {
        if (evt.length < 3 && codeStart.length == 2) {
            return setCodeEnd(evt)
        }
    };

    const emailActivated = () => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true)
        formData.append("email_extra", extraMail);
        formData.append("type", 1);
        formData.append("lang", 'tr');
        formData.append("ip_address", ip);
        formData.append("device_name", device);
        formData.append("utc", utc);
        HTTP.post(`/ticketMail`, (formData), { headers: { Authorization: `bearer ${getuser.token}` } })
            .then((res) => {
                if (res.data.result) {
                    setActivatedModal(true)
                    setLoading(false)
                    toast(res.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "success"
                    })
                } else {
                    setLoading(false)
                    toast(res.data.errors.email_extra[0], {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                }
            })
            .catch(setLoading(true));
    }

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("edit_profile")}</title>
                <meta name="description" content={_("meta_content_10")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
            </Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
            <div className="card-body d-flex row text-center mb-3">
                            <h1 className="title fs-5 mt-1">{_('edit_profile')}</h1>
                        </div>
                <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                    <div className="card card1 col">
                        <div className="row justify-content-center my-auto">
                            <div className="col-md-8 col-10">
                                <div className="row justify-content-center mb-3">
                                    <img style={{width:150}} src={images.username_icon} alt="profile edit" />
                                    <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("username")}</h1>
                                </div>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    setoldusername("");
                                    setnewusername("");
                                    (newusername.length >= 3 && !extraMail) &&
                                        //dispatch(ProfileEditAction({ username: newusername, gender: gender, birthDate: birthDate }))
                                        dispatch(ProfileEditAction({ username: newusername, ip_address: ip, device_name: device, utc: utc }))
                                }}>

                                    <div className="form-group">
                                        <label className="form-control-label text-muted">{_("username")}</label>
                                        <input required type="text" value={replaceUserNameCharacter(newusername)} onChange={(e) => setnewusername(e.target.value)} placeholder={_("username")} className="form-control" />
                                    </div>
                                    {/* <div className="row d-flex">
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("gender")}</label>
                                                <select
                                                    type="text"
                                                    className={"form-control bg-white headerInput"}
                                                    style={{ float: "left" }}
                                                    onChange={(e) => setGender(e.target.value)}
                                                    selected={gender}
                                                    value={gender}>
                                                    <option value={0}>{_('select')}</option>
                                                    <option value={1}>{_('man')}</option>
                                                    <option value={2}>{_('woman')}</option>
                                                    <option value={3}>{_('not_define_gender')}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col d-flex justify-content-end">
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("birth_date")}</label>
                                                <div>
                                                    <DatePicker
                                                        placeholderText={_("birth_date")}
                                                        className="form-control  text-start me-5"
                                                        yearDropdownItemNumber={100}
                                                        maxDate={deltaDate(new Date(), 0, 0, -18)}
                                                        showYearDropdown
                                                        scrollableYearDropdown
                                                        selected={birthDate}
                                                        onChange={(e) => setBirthDate(e)}
                                                        dateFormat="dd.MM.yyyy"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {email &&
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("e_mail")}</label>
                                            {/* <div className="d-flex"> */}
                                            <input disabled required type="text" value={email} placeholder={_("username")} className="form-control bg-white me-2" />
                                            {/* <div className="rounded d-flex justify-content-center align-items-center" style={{ backgroundColor: '#933CAC', color: '#FFFFFF', width: 40, }}>
                                                <span style={{ fontSize: 19, color: '#FFFFFF' }} className="bi bi-pencil-square navLink wrapper" onClick={(e) => setUpdateEmailModal(true)}></span>
                                            </div> */}
                                            {/* </div> */}
                                        </div>
                                    }
                                    {phone &&
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("phone")}</label>
                                            {/* <div className="d-flex"> */}
                                            <input disabled required type="text" value={phone} placeholder={_("phone")} className="form-control bg-white me-2" />
                                            {/* <div className="rounded d-flex justify-content-center align-items-center" style={{ backgroundColor: '#933CAC', color: '#FFFFFF', width: 40, }}>
                                                <span style={{ fontSize: 19, color: '#FFFFFF' }} className="bi bi-pencil-square navLink wrapper" onClick={(e) => setUpdatePhoneModal(true)}></span>
                                            </div> */}
                                            {/* </div> */}
                                        </div>
                                    }

                                    <div className="mt-3">
                                        <label className="form-control-label text-muted">{_("e_mail")}</label>
                                        <div className="col-sm-12">
                                            <div className="input-group">
                                                {
                                                    activatedMail ?
                                                        <input disabled={true} required type="text" value={activatedMail} placeholder={_("e_mail")} className="form-control text-muted bg-white" />
                                                        :
                                                        <>
                                                            <input type="text" value={extraMail} onChange={(e) => setExtraMail(e.target.value)} placeholder={_("e_mail")} className="form-control text-muted bg-white" />
                                                            <button disabled={!extraMail} onClick={() => emailActivated()} className="btn btn-secondary text-center bg-secondary">{_('verify')}</button>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div onClick={()=>gtmTag('ok_button_profile_edit')} className="row justify-content-center my-3 px-3">
                                        <button disabled={!newusername || extraMail} type="submit" className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >
                                            {_("ok")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={updateEmailModal} onHide={() => setUpdateEmailModal(false)}>
                    <Modal.Header closeButton style={{ background: "#fff" }}> </Modal.Header>
                    <Modal.Body >
                        <div className="form-group">
                            <label className="form-control-label text-muted">{_("e_mail")}</label>
                            <input required type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={_("e_mail")} className="form-control" />
                        </div>
                        <div className="row justify-content-center my-3 px-3">
                            <button disabled={!newusername} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" onClick={(e) => updateEmail(email)}>{_("ok")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={updatePhoneModal} onHide={() => setUpdatePhoneModal(false)}>
                    <Modal.Header closeButton style={{ background: "#fff" }}> </Modal.Header>
                    <Modal.Body >
                        <div className="form-group">
                            <label className="form-control-label text-muted">{_("phone")}</label>
                            <PhoneInput
                                placeholder={_("phone")}
                                value={phone}
                                onChange={setPhone}
                                className="headerInput"
                                defaultCountry="TR"
                                onCountryChange={setCountry}
                            />
                        </div>
                        <div className="row justify-content-center my-3 px-3">
                            <button disabled={!newusername} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" onClick={(e) => updatePhone(phone, country_code)}>{_("ok")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={activatedModal} onHide={() => setActivatedModal(false)}>
                    <Modal.Header closeButton style={{ background: "#fff" }}>
                        <Modal.Title
                            style={{ fontSize: 16 }}
                        ><span className="homeText">{_("verify_code")}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (codeStart.length == 2 && codeEnd.length == 2) {
                                    const getuser = JSON.parse(localStorage.getItem("auth"));
                                    setLoading(true)
                                    setCodeStart('')
                                    setCodeEnd('')
                                    formData.append("code", codeStart + codeEnd);
                                    formData.append("lang", 'tr');
                                    formData.append("ip_address", ip);
                                    formData.append("device_name", device);
                                    formData.append("utc", utc);
                                    HTTP.post(`/emailActivation`, (formData), { headers: { Authorization: `bearer ${getuser.token}` } })
                                        .then((res) => {
                                            setLoading(false)
                                            if (res.data.result) {
                                                setActivatedModal(false)
                                                localStorage.setItem('auth', JSON.stringify(res.data))
                                                toast(res.data.message, {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "success"
                                                })
                                                setTimeout(() => {
                                                    window.location.reload()
                                                }, 3000);
                                            } else {
                                                toast(res.data.message, {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "error"
                                                })
                                            }
                                        })
                                        .catch(setLoading(true));
                                } else {
                                    toast(_('password_must_be_four_characters'), {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                        closeButton: false,
                                        type: "error"
                                    })
                                }
                            }}
                        >
                            <div className="row justify-content-center mb-3 mt-3">
                                <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("enter_verify_code_for_email")}</p>
                                <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("control_spam_box")}</p>
                                <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >({extraMail})</p>
                            </div>
                            <div className="form-group">
                                <label className="form-control-label text-muted">{_("verify_code")}</label>
                                <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white">
                                    <span type="text" className="me-1" autofocus="">v</span>
                                    <span type="text" className="me-1" autofocus="">-</span>
                                    <input value={codeStart} onChange={(e) => handleCodeStart(e.target.value)} style={{ width: 50 }} type="text" className="form-control me-1" />
                                    <span type="text" className="me-1" autofocus="">-</span>
                                    <input value={codeEnd} onChange={(e) => handleCodeEnd(e.target.value)} style={{ width: 50 }} type="text" className="form-control" />
                                </div>
                            </div>

                            <div className="row justify-content-center my-3 px-3">
                                <button disabled={!(codeStart.length == 2 && codeEnd.length == 2)} type="submit" className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >
                                    {_("send")}
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default ProfileEdit