import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import globals from "../config/globals/globals";
import ".././index.css";
import images from "../images/images";
import Loader from "../components/Loader";
import { _ } from "../config/languages/i18n";
import React from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import moment from "moment";
import { getMonth } from "../../src/config/month/month"
import { getYear } from "../../src/config/year/year"
import constant from '../config/constant';
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const Payment = () => {
    var totalPrice = 0;
    var salePrice = 0;
    var totalSalePrice = 0;
    var insurancePrice = 0;
    var ticketPrice = 0;

    const [loading, setLoading] = useState(false);
    const [ticket, setTicket] = useState("");
    const [cartTime, setCartTime] = useState("");
    const [counter, setCounter] = useState("");
    const [fullscreen, setFullscreen] = useState(true);
    const [deleteCartModal, setDeleteCartModal] = useState(false);
    const [deleteTicketModal, setDeleteTicketModal] = useState(false);
    const [deleteTicketId, setDeleteTicketId] = useState(false)
    const [orderId, setOrderId] = useState("")
    const month = getMonth();
    const year = getYear();
    const [invoiceType, setInvoiceType] = useState(1)
    const [TCNO, setTCNO] = useState("")
    const [buyButton, setBuyButton] = useState(false)
    const [saleStatus, setSaleStatus] = useState(false)
    const [saleRatio, setSaleRatio] = useState(0)
    const [balance, setBalance] = useState("")
    const [totalAmount, setTotalAmount] = useState("")
    const [paymentStatus, setPaymentStatus] = useState(0)
    const [sendPaymentStatus, setSendPaymentStatus] = useState(0)
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utcLog, setUtcLog] = useState('')
    const [showQrModal, setShowQrModal] = useState(false)

    const [state, setState] = useState({
        number: "",
        month: "",
        year: "",
        cvc: "",
        name: "",
        focus: "",
        month: "",

        nameSurname: "",
        tckn: "",
        individualEmail: "",

        officialTitleCompany: "",
        taxAdministration: "",
        taxNumber: "",
        billingAddress: "",
        institutionalEmail: ""
    });
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [iFrameSrc, setIFrameSrc] = useState("");
    const formData = new FormData();
    const HTTP = axios.create({
        baseURL: globals.apiUrl,
    });

    const [ticketId, setTicketId] = useState("")
    const [cardId, setCardId] = useState("")

    const [term, setTerm] = useState(true)
    const onChangeTerm = (value) => {
        setTerm(!term);
    }

    const [tcknTerm, setTcknTerm] = useState(false)
    const onChangeTcknTerm = (value) => {
        setTcknTerm(!tcknTerm);
    }

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtcLog(new Date().getTimezoneOffset()/-60)
	},[])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true);
        HTTP.get("/getCart", {
            headers: { Authorization: `bearer ${getuser.token}` },
        })
            .then((res) => {
                if (res.data.cart) {
                    setTicket(res.data.cart.tickets);
                    setCartTime(res.data.cart.cartTime);
                    setCounter(res.data.cart.cartTime);
                    localStorage.setItem('basketsNumber', JSON.stringify(res.data.cart.tickets.length))
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true);
        HTTP.get("/getUserWallet", { headers: { Authorization: `bearer ${getuser.token}` } })
            .then((res) => {
                if (res.data.result) {
                    setBalance(res.data.balance)
                    setLoading(false)
                }
            }).catch(setLoading(true))
    }, [])

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setLoading(true);
        HTTP.get("users/getUserLastInvoice", {
            headers: { Authorization: `bearer ${getuser.token}` },
        })
            .then((res) => {
                if (res.data.result) {
                    if (res.data.invoice.type == 2) {
                        state.officialTitleCompany = res.data.invoice.firmname
                        state.taxAdministration = res.data.invoice.tax_office
                        state.taxNumber = res.data.invoice.tax_no
                        state.billingAddress = res.data.invoice.address
                        state.institutionalEmail = res.data.invoice.email
                    } else {
                        state.nameSurname = res.data.invoice.fullname
                        state.tckn = res.data.invoice.tckn
                        state.individualEmail = res.data.invoice.email
                    }
                } else {
                    setLoading(false);
                }
            })
            .catch(setLoading(true));
    }, []);

    useEffect(() => {
        if (paymentStatus == 2 && showPaymentModal == false) {
            window.dataLayer.push({
                ticket: {
                    ticket: ticket,
                    ticket_number: ticket.length
                },
                payment: {
                    total_amount: totalAmount,
                    payment_type: "card",
                    payment_status: "fail"
                },
                event: 'payment_details'
            })
        }
        if (paymentStatus == 1 && showPaymentModal == true) {
            window.dataLayer.push({
                ticket: {
                    ticket: ticket,
                    ticket_number: ticket.length
                },
                payment: {
                    total_amount: totalAmount,
                    payment_type: "card",
                    payment_status: "success"
                },
                event: 'payment_details'
            })
        }
    }, [paymentStatus, showPaymentModal])

    if (orderId) {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        setTimeout(() => HTTP.get(`getProcessStatus?process_id=${orderId}`,
            {
                headers: { Authorization: `bearer ${getuser.token}` },
            }
        )
            .then((res) => {
                if (res.data.process.status == 2) {
                    setPaymentStatus(2)
                    setTimeout(() => window.location.href = globals.url + "i/payment", 2000);
                    setTimeout(() => setShowPaymentModal(false), 2000)
                }
                if (res.data.process.status == 1) {
                    setPaymentStatus(1)
                    localStorage.setItem("feeRef", JSON.stringify(0))
                    setTimeout(() => window.location.href = globals.url + "i/tickets", 2000);
                    setTimeout(() => setShowPaymentModal(false), 2000)
                    localStorage.setItem('basketsNumber', JSON.stringify(0))
                }
            })
            .catch(), 1000);
    }

    const removeTicket = (e) => {
        setLoading(true);
        const ticket_id = e;
        const getuser = JSON.parse(localStorage.getItem("auth"));
        HTTP.post(
            "/deleteTicket",
            { ticket_id, device, utcLog, ip },
            {
                headers: { Authorization: `bearer ${getuser.token}` },
            }
        )
            .then((res) => {
                if (res.data.result) {
                    getTicket()
                    // setTicket(res.data.cart?.tickets);
                    setLoading(false);
                } else {
                    setLoading(true);
                }
            })
            .catch(setLoading(true));
    };

    const getTicket = () => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        HTTP.get("/getCart", { headers: { Authorization: `bearer ${getuser.token}` } })
            .then((res) => {
                if (res.data.cart) {
                    setTicket(res.data.cart.tickets)
                    localStorage.setItem('basketsNumber', JSON.stringify(res.data.cart.tickets.length))
                    setCounter(res.data.cart.cartTime)
                    setLoading(false);
                } else {
                    setTicket("")
                    localStorage.setItem('basketsNumber', JSON.stringify(0))
                    setCounter("")
                    setLoading(false)
                }
            })
            .catch(setLoading(true));
    }

    const frontRemoveTicket = (e) => {
        setDeleteTicketId(e)
        setDeleteTicketModal(true)
    }

    const removeCart = () => {
        setLoading(true);
        const getuser = JSON.parse(localStorage.getItem("auth"));
        HTTP.post(
            "/deleteCart",
            {ip, device, utcLog},
            {
                headers: { Authorization: `bearer ${getuser.token}` },
            }
        )
            .then((res) => {
                if (res.data.result) {
                    getTicket()
                    // setTicket(res.data.cart?.tickets);
                    localStorage.setItem('basketsNumber', JSON.stringify(0));
                    setLoading(false);
                } else {
                    setLoading(true);
                }
            })
            .catch(setLoading(true));
    };

    const handleInputChangeName = (evt) => {
        const { name, value } = evt.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeNumber = (evt) => {
        const { name, value } = evt.target;
        if (value.length >= 6) {
            if (constant.denizbankBin.find(arr => arr == value.slice(0, 6))) {
                setSaleStatus(true);
                setSaleRatio(constant.denizbankBin[0]);
            } else {
                setSaleStatus(false);
                setSaleRatio(0);
            }
        } else {
            setSaleStatus(false);
            setSaleRatio(0);
        }
        if ((value.replace(/\s/g, '')).length < 17 && (/^[0-9]*[]?[0-9]*$/).test(value.replace(/\s/g, ''))) {
            return setState((prev) => ({ ...prev, [name]: value.replace(/\s/g, '').trim() }));
        }
    };

    const handleInputChangeMonth = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 3) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeYear = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 3) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeCVV = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 4) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeNameSurname = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeTckn = (evt) => {
        const { name, value } = evt.target;
        if ((value.length < 12)) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeIndividualEmail = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value.trim()}));
    };

    const handleInputChangeOfficialTitleCompany = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeTaxAdministration = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeTaxNumber = (evt) => {
        const { name, value } = evt.target;
        if (value.length < 11) {
            return setState((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleInputChangeBillingAddress = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value }));
    };

    const handleInputChangeInstitutionalEmail = (evt) => {
        const { name, value } = evt.target;
        return setState((prev) => ({ ...prev, [name]: value.trim()}));
    };

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));

    };

    function TCNOKontrol(TCNO) {
        var tek = 0,
            cift = 0,
            sonuc = 0,
            TCToplam = 0,
            i = 0;

        if (TCNO.length != 11) return false;
        if (isNaN(TCNO)) return false;
        if (TCNO[0] == 0) return false;

        tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
        cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);

        tek = tek * 7;
        sonuc = Math.abs(tek - cift);
        if (sonuc % 10 != TCNO[9]) return false;

        for (var i = 0; i < 10; i++) {
            TCToplam += parseInt(TCNO[i]);
        }

        if (TCToplam % 10 != TCNO[10]) return false;

        return true;
    }
    function emailControl(data) {
        var regex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/;
        if (regex.test(data) == true) {
            return true;
        } else {
            return false;
        }
    }

    var myArray = ""
    var cartId = ""
    const pay = () => {
        setLoading(true)
        ticket.map((item, i) => {
            if (ticket.length - 1 == i) {
                myArray += item.id;
            } else {
                myArray += item.id + '|';
            }
            cartId = item.cart_id;
        })

        const getuser = JSON.parse(localStorage.getItem("auth"));
        setBuyButton(true)
        if(totalAmount > balance){
            if (invoiceType == 1) {
                if (TCNOKontrol(state.tckn) == false && !tcknTerm) {
                    toast(_('invalid_tckn'), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                    setBuyButton(false)
                    return;
                }
    
                if (emailControl(state.individualEmail) == false) {
                    toast(_('invalid_email'), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                    setBuyButton(false)
                    return;
                }
            } else {
                if (emailControl(state.institutionalEmail) == false) { //Kurumsal
                    toast(_('invalid_email'), {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                    setBuyButton(false)
                    return;
                }
            }
        }
       

        formData.append("ticket_ids", myArray);
        formData.append("cart_id", cartId);
        formData.append("ccNumber", state.number);
        formData.append("ccMonth", state.month);
        formData.append("ccYear", state.year);
        formData.append("ccCvv", state.cvc);

        formData.append("invoice_type", invoiceType)
        formData.append("invoice_fullname", state.nameSurname)
        formData.append("invoice_tr_status", tcknTerm ? 0 : 1)
        formData.append("invoice_tckn", state.tckn)
        formData.append("invoice_email", state.individualEmail ? state.individualEmail : state.institutionalEmail)
        formData.append("invoice_firm", state.officialTitleCompany)
        formData.append("invoice_tax_office", state.taxAdministration)
        formData.append("invoice_tax_no", state.taxNumber)
        formData.append("invoice_address", state.billingAddress)
        formData.append("invoice_e_invoice", term ? 1 : 0)
        formData.append("use_wallet", balance > 0 ? 1 : 0)
        formData.append("is_wallet_enough", balance >= totalAmount ? 1 : 0)
        formData.append("ip_address", ip)
        formData.append("device_name", device)
        formData.append("utc", utcLog)
        HTTP.post(
            "/payment",
            (formData),
            {
                headers: { Authorization: `bearer ${getuser.token}` },
                'Content-Type': 'application/json'
            }
        ).then(response => {
            const data = response.data
            if (data.result == true) {
                setLoading(false)
                toast(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false
                })
                if (totalAmount > balance) {
                    setTimeout(() => setShowPaymentModal(true), 3000);
                }
                if (balance >= totalAmount) {
                    localStorage.setItem("feeRef", JSON.stringify(0))
                    setTimeout(() => window.location.href = globals.url + "i/tickets", 2000);
                    localStorage.setItem('basketsNumber', JSON.stringify(0))
                    window.dataLayer.push({
                        ticket: {
                            ticket: ticket,
                            ticket_number: ticket.length
                        },
                        payment: {
                            total_amount: totalAmount,
                            payment_type: "wallet",
                            payment_status: "success"
                        },
                        event: 'payment_details'
                    })
                }
                setIFrameSrc(data.url)
                setOrderId(data.orderUuid)
            }
            if (data.result == false) {
                setBuyButton(false)
                if (data.errors.ccNumber) {
                    toast(data.errors.ccNumber[0], {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                }
                if (data.errors.ccMonth) {
                    toast(data.errors.ccMonth[0], {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                }
                if (data.errors.ccYear) {
                    toast(data.errors.ccYear[0], {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                }
                if (data.errors.ccCvv) {
                    toast(data.errors.ccCvv[0], {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton: false,
                        type: "error"
                    })
                }
            }
        }).catch(() => {
            setBuyButton(false)
            toast(_('connection_error'), {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton: false,
                type: "error"
            })
        })
    }

    function NumToTime(num) {
        var hours = Math.floor(num / 60);
        var minutes = num % 60;
        if (minutes + ''.length < 2) {
            minutes = '0' + minutes;
        }
        return hours + ":" + minutes;
    }

    useEffect(() => {
        sendGTM()
    }, [ticket, totalAmount, balance])

    const sendGTM = async () => {
        await ticket
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            ticket: {
                ticket: ticket,
                ticket_number: ticket.length
            },
            payment: {
                total_amount: totalAmount,
                payment_type: (totalAmount > balance) ? "card" : "wallet",
            },
            event: 'card_details'
        })
    }

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        if (ticket.length > 0 && counter <= 0) {
            removeCart();
            localStorage.setItem('basketsNumber', JSON.stringify(0)); // Sepet sayısını sıfırla
        }
    }, [ticket, counter]);
    
    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("cart")}</title>
                <meta name="description" content={_("meta_content_08")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container py-5 mt-5">
                        <div className="row flex-column flex-lg-row ">
                            {ticket ?
                                <>
                                    <div className="col-sm-12 col-lg-6 card p-5">
                                        {/* <div onClick={()=>setDeleteCartModal(true)} className="row mb-3">
                                            <span className="homeText d-flex justify-content-end align-items-center col">
                                                {" "}
                                                {_('empty_cart')}
                                            </span>
                                        </div> */}
                                        <div className="row">
                                            <span className="homeText col d-flex justify-content-start">
                                                {_('number_of_tickets')}: {ticket.length}
                                            </span>
                                            {counter > 0 && 
                                                <span className="homeText col d-flex justify-content-end">
                                                    {_('basket_duration')}: {NumToTime(counter)}
                                                    {/* {_('basket_duration')}: {(Math.floor(counter / 60)) + ":" + (counter % 60)} */}
                                                </span>
                                            }
                                        </div>

                                        {ticket.map((val, i) => {
                                            if (val.is_insurance) {
                                                if (saleStatus && val.activity.is_online) { // isonline koy bak
                                                    // totalPrice = totalPrice + ((val.current_price.price + val.insurance_fee) * ((100 - saleRatio) / 100))
                                                    totalPrice = totalPrice + ((val.current_price.price) * ((100 - saleRatio) / 100)+val.insurance_fee)
                                                    salePrice = val.current_price.price * ((100 - saleRatio) / 100)
                                                    totalSalePrice =totalSalePrice + (val.current_price.price - (val.current_price.price * ((100 - saleRatio) / 100)))
                                                
                                                    insurancePrice = insurancePrice + val.insurance_fee
                                                    ticketPrice = ticketPrice + val.current_price.price
                                                    setTimeout(() => {
                                                        setTotalAmount(Math.round((totalPrice + Number.EPSILON) * 100) / 100)

                                                    });
                                                } else {
                                                    totalPrice = totalPrice + (val.current_price.price + val.insurance_fee)
                                                    salePrice = 0
                                                    insurancePrice = insurancePrice + val.insurance_fee
                                                    ticketPrice = ticketPrice + val.current_price.price
                                                    setTimeout(() => {
                                                        setTotalAmount(Math.round((totalPrice + Number.EPSILON) * 100) / 100)

                                                    });
                                                }
                                            } else {
                                                if (saleStatus && val.activity.is_online) { // isonline koy bak
                                                    totalPrice = totalPrice + (val.current_price.price * ((100 - saleRatio) / 100))
                                                    salePrice = val.current_price.price * ((100 - saleRatio) / 100)
                                                    totalSalePrice =totalSalePrice + (val.current_price.price - (val.current_price.price * ((100 - saleRatio) / 100)))
                                                    ticketPrice = ticketPrice + val.current_price.price
                                                    setTimeout(() => {
                                                        setTotalAmount(Math.round((totalPrice + Number.EPSILON) * 100) / 100)
                                                    });
                                                } else {
                                                    totalPrice = totalPrice + val.current_price.price
                                                    ticketPrice = ticketPrice + val.current_price.price
                                                    salePrice = 0
                                                    setTimeout(() => {
                                                        setTotalAmount(Math.round((totalPrice + Number.EPSILON) * 100) / 100)
                                                    });
                                                }
                                            }

                                            return (
                                                <div className="row border rounded-2 py-2 mb-1">
                                                    <div className="col-3 d-flex justify-content-start align-items-center">
                                                        <img
                                                            src={globals.imgUrl + val.activity.id + "/thumb/" + val.activity.photo_name + ".png" + '?' + new Date(val.activity.created_at).getTime()}
                                                            className="figure-img img-fluid rounded-2"
                                                            width='100%'
                                                            height='auto'
                                                            alt="activity photo"
                                                        />
                                                    </div>

                                                    <div className="col-4 d-flex justify-content-center align-items-center">
                                                        <div className="row text-truncate">
                                                            <div className="col">
                                                                <h1 style={{fontSize:16}} className="my-0 text-muted text-truncate">{val.activity.title}</h1>
                                                            </div>
                                                            <div className="row text-truncate">
                                                                <small className="text-muted text-truncate">{moment(val.activity.start_time).format("DD.MM.YYYY")}</small>
                                                                <small className="text-muted text-truncate">{_(moment(val.activity.start_time).format("dddd").toLocaleLowerCase())}</small>
                                                                <small className="text-muted text-truncate">{moment(val.activity.start_time).format("HH:mm")}</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-4 d-flex justify-content-start align-items-start">
                                                        <div className="row text-truncate">
                                                            <small className="text-muted text-truncate">
                                                                {_('category')}: Genel
                                                            </small>
                                                            <small className="text-muted text-truncate">
                                                                {_('type')}: {val.current_price.name}
                                                            </small>
                                                            { val.current_price.status == 1 ?
                                                                <small className="text-muted text-truncate">{_('armchair')}: {_('numberless')}</small>
                                                                :
                                                                <small className="text-muted text-truncate">{_('armchair')}: {val.seat.name}</small>
                                                            }

                                                            { val.is_insurance ?
                                                                <small className="text-muted text-truncate">
                                                                    {_('fee')}: {(Math.round(((val.current_price.price + val.insurance_fee) + Number.EPSILON) * 100) / 100).toFixed(2)} TRY
                                                                </small>
                                                                :
                                                                <small className="text-muted text-truncate">
                                                                    {_('fee')}: {(Math.round(((val.current_price.price) + Number.EPSILON) * 100) / 100).toFixed(2)} TRY
                                                                </small>
                                                            }

                                                            {(saleStatus && val.activity.is_online) ?
                                                                val.is_insurance ?
                                                                    <small className="text-muted text-truncate">
                                                                        {_('sale_price')}: {(Math.round(((salePrice + val.insurance_fee) + Number.EPSILON) * 100) / 100).toFixed(2)} TRY
                                                                    </small>
                                                                    :
                                                                    <small className="text-muted text-truncate">
                                                                        {_('sale_price')}: {(Math.round((salePrice + Number.EPSILON) * 100) / 100).toFixed(2)} TRY
                                                                    </small>
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                    </div>

                                                    <span
                                                        onClick={(e => frontRemoveTicket(val.id))}
                                                        className="bi bi-x-circle-fill homeText d-flex align-items-center justify-content-center col-1"
                                                    ></span>
                                                    { val.is_insurance ?
                                                        <div className="d-flex text-success" style={{ fontSize: 10 }}>
                                                            <div className="border p-1 border-success rounded-1 ">
                                                                {_("insured")} (10 TRY)
                                                            </div>

                                                        </div>
                                                        :
                                                        <div className="d-flex" style={{ color: '#DD1D27', fontSize: 10 }}>
                                                            <div className="border p-1 border-danger rounded-1 ">
                                                                {_("non_refundable")}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })}

                                        <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end mt-2">{_('total_of_ticket')}: {Math.round((ticketPrice + Number.EPSILON) * 100) / 100} TRY</div>
                                        {
                                            insurancePrice > 0 &&
                                            <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end mt-2">{_('total_of_insurance')}: {Math.round((insurancePrice + Number.EPSILON) * 100) / 100} TRY</div>
                                        }
                                         {
                                            totalSalePrice > 0 &&
                                            <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end mt-2">{_('total_of_sale_price')}: {-(Math.round((totalSalePrice + Number.EPSILON) * 100) / 100)} TRY</div>
                                        }

                                        <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end mt-2">{_('total_price')}: {Math.round((totalPrice + Number.EPSILON) * 100) / 100} TRY</div>
                                        {
                                            balance > 0 &&
                                            <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end mt-2">{_('wallet')}: {balance} TRY</div>
                                        }
                                        <hr />
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span onClick={()=>setShowQrModal(true)}>
                                                    <img src={images.etbis} alt="vidipass logo" width="50" height="auto" />
                                                </span>
                                                <img src={images.secured}  alt="vidipass logo" width="50" height="auto" />
                                            </div>
                                            <div>
                                        
                                                <div className="homeText d-flex justify-content-end">{_('payment')}</div>
                                                {
                                                    totalPrice > balance &&
                                                    <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end">{_('card')}: {-(Math.round(((totalPrice - balance) + Number.EPSILON) * 100) / 100).toFixed(2)} TRY</div>
                                                }
                                                {
                                                    balance > 0 &&
                                                    <div style={{ fontSize: 13 }} className="text-muted d-flex justify-content-end">{_('wallet')}: {balance >= totalPrice ? -totalPrice : -balance} TRY</div>
                                                }
                                                
                                            </div>
                                            
                                         </div>
                                         {
                                                    balance >= totalAmount &&
                                                    <div className="row justify-content-center mt-5 my-3 px-3">
                                                        <button
                                                            disabled={buyButton}
                                                            onClick={pay} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >{_('pay')}</button>
                                                        {/* <button disabled={!(state.name && state.number && state.month && state.year && state.cvc)}  onClick={pay} className="text-decoration-none btn btn-primary cardButton rounded-2" >{_('pay')}</button> */}
                                                    </div>
                                                }
                                    </div>
                                    {
                                        totalAmount > balance &&
                                        <div className="col-sm-12 col-lg-6 card p-5">
                                            <span className="homeText text-center mb-3">{_("invoice_info")}</span>
                                            <div>
                                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button onClick={e => setInvoiceType(1)} className="nav-link active" id="faq_tab_1-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_1" type="button" role="tab" aria-controls="faq_tab_1" aria-selected="true" >
                                                            <div className="d-flex flex-column lh-lg">
                                                                <i className="bx bxs-plane-alt"></i>
                                                                <span className="homeText">{_("individual")}</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button onClick={e => setInvoiceType(2)} className="nav-link" id="faq_tab_2-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_2" type="button" role="tab" aria-controls="faq_tab_2" aria-selected="false">
                                                            <div className="d-flex flex-column lh-lg">
                                                                <i className="bx bxs-shopping-bag"></i>
                                                                <span className="homeText">{_("corporate")}</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </ul>

                                                <div className="tab-content my-3" id="myTabContent">
                                                    <div className="tab-pane fade active show" id="faq_tab_1" role="tabpanel" aria-labelledby="faq_tab_1-tab">
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="text"
                                                                name="nameSurname"
                                                                className="form-control"
                                                                placeholder={_('name_surname')}
                                                                value={state.nameSurname}
                                                                required
                                                                onChange={handleInputChangeNameSurname}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.nameSurname == "" && <div className="text-danger small mt-2 mx-1">{_('required_name_surname')}</div>}
                                                        </span>
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="number"
                                                                name="tckn"
                                                                className="form-control"
                                                                placeholder={_('tckn')}
                                                                value={state.tckn}
                                                                required
                                                                onChange={handleInputChangeTckn}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {(state.tckn == "" && tcknTerm == 0) && <div className="text-danger small mt-2 mx-1">{_('required_tckn')}</div>} 
                                                        </span>
                                                        <div className="form-check mt-2">
                                                            <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={tcknTerm} onChange={onChangeTcknTerm} />
                                                                {_("not_citizen_of_republic_of_turkey")}
                                                            </label>
                                                        </div>
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="text"
                                                                name="individualEmail"
                                                                className="form-control"
                                                                placeholder={_('email_address_to_send_invoice')}
                                                                value={state.individualEmail}
                                                                required
                                                                onChange={handleInputChangeIndividualEmail}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.individualEmail == "" && <div className="text-danger small mt-2 mx-1">{_('required_email')}</div>}
                                                        </span>
                                                    </div>

                                                    <div  className="tab-pane fade" id="faq_tab_2" role="tabpanel" aria-labelledby="faq_tab_2-tab">
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="text"
                                                                name="officialTitleCompany"
                                                                className="form-control"
                                                                placeholder={_('official_title_of_company')}
                                                                value={state.officialTitleCompany}
                                                                required
                                                                onChange={handleInputChangeOfficialTitleCompany}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.officialTitleCompany == "" && <div className="text-danger small mt-2 mx-1">{_('required_official_title')}</div>}
                                                        </span>
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="text"
                                                                name="taxAdministration"
                                                                className="form-control"
                                                                placeholder={_('tax_administration')}
                                                                value={state.taxAdministration}
                                                                required
                                                                onChange={handleInputChangeTaxAdministration}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.taxAdministration == "" && <div className="text-danger small mt-2 mx-1">{_('required_tax_administration')}</div>}
                                                        </span>
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="number"
                                                                name="taxNumber"
                                                                className="form-control"
                                                                placeholder={_('tax_number')}
                                                                value={state.taxNumber}
                                                                required
                                                                onChange={handleInputChangeTaxNumber}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.taxNumber == "" && <div className="text-danger small mt-2 mx-1">{_('required_tax_number')}</div>}
                                                        </span>
                                                        <div className="form-group mt-2">
                                                            <textarea
                                                                type="text"
                                                                name="billingAddress"
                                                                className="form-control"
                                                                placeholder={_('billing_address')}
                                                                value={state.billingAddress}
                                                                required
                                                                onChange={handleInputChangeBillingAddress}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.billingAddress == "" && <div className="text-danger small mt-2 mx-1">{_('required_billing_address')}</div>}
                                                        </span>
                                                        <div className="form-group mt-2">
                                                            <input
                                                                type="text"
                                                                name="institutionalEmail"
                                                                className="form-control"
                                                                placeholder={_('email_address_to_send_invoice')}
                                                                value={state.institutionalEmail}
                                                                required
                                                                onChange={handleInputChangeInstitutionalEmail}
                                                                onFocus={handleInputFocus}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                            {state.institutionalEmail == "" && <div className="text-danger small mt-2 mx-1">{_('required_email')}</div>}
                                                        </span>
                                                        <div className="form-check mt-2">
                                                            <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} />
                                                                {_("e_invoice_payer")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {totalAmount > balance &&
                                                    <>
                                                        <div style={{ color: "#000" }}>
                                                            <hr style={{ height: 1 }} />
                                                        </div>
                                                        <Cards
                                                            number={state.number}
                                                            expiry={state.month + state.year}
                                                            cvc={state.cvc}
                                                            name={state.name}
                                                            focused={state.focus}
                                                            placeholders={{ name: _('name_surname') }}
                                                        />
                                                        <form className="mt-2">
                                                            <div className="form-group mt-2">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control"
                                                                    placeholder={_('name_on_the_card')}
                                                                    value={state.name}
                                                                    required
                                                                    onChange={handleInputChangeName}
                                                                    onFocus={handleInputFocus}
                                                                />
                                                            </div>
                                                            <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                                {state.name == "" && <div className="text-danger small mt-2 mx-1">{_('required_card_name')}</div>}
                                                            </span>
                                                            <div className="form-group mt-2">
                                                                <input
                                                                    name="number"
                                                                    placeholder={_('card_number')}
                                                                    value={state.number.replace(/\s/g, '')}
                                                                    className="form-control"
                                                                    required
                                                                    onChange={handleInputChangeNumber}
                                                                    onFocus={handleInputFocus}
                                                                />
                                                            </div>
                                                            <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                                {state.number == "" && <div className="text-danger small mt-2 mx-1">{_('required_card_number')}</div>}
                                                            </span>
                                                            <div className="row mt-2">
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <select
                                                                            type="tel"
                                                                            name="month"
                                                                            className="form-control bg-white headerInput"
                                                                            placeholder={_('month')}
                                                                            value={state.month}
                                                                            pattern="\d\d/\d\d"
                                                                            required
                                                                            onChange={handleInputChangeMonth}
                                                                            onFocus={handleInputFocus}>
                                                                            <option value="" key={""}>{_('month')}</option>
                                                                            {
                                                                                month.map((result) => (<option title={result.key}>{result.value}</option>))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <select
                                                                            type="tel"
                                                                            name="year"
                                                                            className="form-control bg-white headerInput"
                                                                            placeholder={_('year')}
                                                                            value={state.year}
                                                                            pattern="\d\d/\d\d"
                                                                            required
                                                                            onChange={handleInputChangeYear}
                                                                            onFocus={handleInputFocus}>
                                                                            <option value="" key={""}>{_('year')}</option>
                                                                            {
                                                                                year.map((result) => (<option title={result.key}>{result.value}</option>))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <input
                                                                            type="tel"
                                                                            name="cvc"
                                                                            className="form-control"
                                                                            placeholder="CVC"
                                                                            value={state.cvc}
                                                                            pattern="\d{3,4}"
                                                                            required
                                                                            onChange={handleInputChangeCVV}
                                                                            onFocus={handleInputFocus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">
                                                                {state.month == "" && <div className="text-danger small mt-2 mx-1">{_('required_month')}</div>}
                                                                {state.year == "" && <div className="text-danger small mt-2 mx-1">{_('required_year')}</div>}
                                                                {state.cvc == "" && <div className="text-danger small mt-2 mx-1">{_('required_cvc')}</div>}
                                                            </span>
                                                        </form>
                                                    </>
                                                }
                                            </div>
                                            <span style={{ fontSize: 14 }} className="text-muted mb-1 mt-3">{_("pay_secure_info_1")}</span>
                                            <span style={{ fontSize: 14 }} className="text-muted mt-1">{_("pay_secure_info_2")}</span>
                                            <div className="row justify-content-center mt-3 my-3 px-3">
                                                <button
                                                    disabled={totalAmount > balance && (
                                                        !(state.name && state.number && state.month && state.year && state.cvc && (
                                                            tcknTerm ?
                                                                (state.nameSurname && state.individualEmail)
                                                                :
                                                                (state.nameSurname && state.tckn && state.individualEmail)
                                                                ||
                                                                (state.officialTitleCompany && state.taxAdministration && state.taxNumber && state.billingAddress && state.institutionalEmail))
                                                        ) || buyButton)

                                                        // (!(tcknTerm ? (state.nameSurname && state.individualEmail):(state.nameSurname && state.tckn && state.individualEmail)||(state.officialTitleCompany && state.taxAdministration && state.taxNumber && state.billingAddress && state.institutionalEmail))||buyButton )
                                                    }
                                                    onClick={pay} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >{_('pay')}</button>
                                                {/* <button disabled={!(state.name && state.number && state.month && state.year && state.cvc)}  onClick={pay} className="text-decoration-none btn btn-primary cardButton rounded-2" >{_('pay')}</button> */}
                                            </div>
                                        </div>
                                    }
                                </>
                                :
                                <div className="row d-flex justify-content-center px-5">
                                    <h1 style={{fontSize:20}} className="homeText text-center">{_("add_to_activity")}</h1>
                                        <a className="text-decoration-none btn btn-primary cardButton rounded-2 col-md-6" href={globals.url}>{_("add_to_activity")}</a>
                                </div>
                            }
                        </div>
                    </div>

                    <Modal show={showPaymentModal}>
                        <Modal.Header closeButton closeVariant="white" style={{ color: "pink", background: "#f8f8f8" }}>
                            <Modal.Title>
                                <div className="row">
                                    <div className="col col-4 d-flex justify-content-start align-items-center text-align-center">
                                        <div className="d-flex justify-content-start align-items-center text-align-center ">
                                            <img src={images.vidipass_logo} className="w-75" alt="vidipass logo" />
                                        </div>
                                    </div>
                                    <div className="col col-8">
                                        <p style={{ fontSize: 10, color: "black" }}> {_("payment_warning_1")} </p>
                                        <p style={{ fontSize: 10, color: "black" }}> {_("payment_warning_2")} </p>
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body >
                            {
                                paymentStatus != 0 ?
                                    <>
                                        <div style={{ marginBottom: -100 }} className="row" >
                                            <iframe src={iFrameSrc}></iframe>
                                        </div>
                                        {
                                            paymentStatus == 1 ?
                                                <div className="d-flex justify-content-center">
                                                    <a className="border p-2 rounded border-primary" aria-current="page" href={globals.url + "i/tickets"}><img width={75} height={'auto'} src={images.vidipassbank_colourful_navbar} alt="vidipass bank"/></a>
                                                </div>
                                                :
                                                <div className="d-flex justify-content-center">
                                                    <a className="text-decoration-none btn btn-primary cardButton rounded-2" href={globals.url + "i/payment/"}>{_("go_to_cart")}</a>
                                                </div>
                                        }
                                    </>
                                    :
                                    <div className="containerIframe">
                                        <iframe className="responsive-iframe" src={iFrameSrc}></iframe>
                                    </div>
                            }
                        </Modal.Body>
                    </Modal>

                    <Modal show={deleteCartModal} onHide={() => setDeleteCartModal(false)}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                            <Modal.Title
                                style={{ fontSize: 16 }}
                            ><span className="homeText">{_("empty_cart")}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <h6 className="homeText">{_("sure")}</h6>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <div className="row">
                                    <div className="col text-end" onClick={() => removeCart() + setDeleteCartModal(false)}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-check-circle-fill"></i>
                                        </a>
                                    </div>
                                    <div className="col text-end" onClick={() => setDeleteCartModal(false)}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-x-circle-fill"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={deleteTicketModal} onHide={() => setDeleteTicketModal(false)}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                            <Modal.Title
                                style={{ fontSize: 16 }}
                            ><span className="homeText">{_("delete_ticket")}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <h6 className="homeText">{_("sure")}</h6>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <div className="row">
                                    <div className="col text-end" onClick={(e) => removeTicket(deleteTicketId) + setDeleteTicketModal(false)}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-check-circle-fill"></i>
                                        </a>
                                    </div>
                                    <div className="col text-end" onClick={() => setDeleteTicketModal(false)}>
                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                            <i className="bi bi-x-circle-fill"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showQrModal} onHide={() => setShowQrModal(false)}>
                        <Modal.Header closeButton style={{ background: "#fff" }}>
                        
                            <span className="text-center homeText">ETBIS</span>
                        </Modal.Header>
                        <Modal.Body >
                        <a href={"https://www.eticaret.gov.tr/siteprofil/1286801282236550/wwwvidipasscom"} target="blank" className="col-12 d-flex justify-content-center align-items-center mb-3">
                            <img src={images.etbis} alt="vidipass logo" height="auto" />
                        </a>
                        <div className="text-center text-muted">{_('etbis_info')}</div>
                       
                       
                        </Modal.Body>
                    </Modal>
                </>
            <Footer />
        </div>
    );
};

export default Payment;