const year = [
    {
        "key": "1",
        "value": "25"
    },
    {
        "key": "2",
        "value": "26"
    },
    {
        "key": "3",
        "value": "27"
    },
    {
        "key": "4",
        "value": "28"
    },
    {
        "key": "5",
        "value": "29"
    },
    {
        "key": "6",
        "value": "30"
    },
    {
        "key": "7",
        "value": "31"
    },
    {
        "key": "8",
        "value": "32"
    },
    {
        "key": "9",
        "value": "33"
    },
    {
        "key": "10",
        "value": "34"
    },
    {
        "key": "11",
        "value": "35"
    },
    {
        "key": "12",
        "value": "36"
    },
    {
        "key": "13",
        "value": "37"
    },
    {
        "key": "14",
        "value": "38"
    },
    {
        "key": "15",
        "value": "39"
    },
    {
        "key": "16",
        "value": "40"
    },
    {
        "key": "17",
        "value": "41"
    },
    {
        "key": "18",
        "value": "42"
    },
    {
        "key": "19",
        "value": "43"
    },
    {
        "key": "20",
        "value": "44"
    },
    {
        "key": "21",
        "value": "45"
    },
];

export function getYear() {
    return year;
};